<template>
	<div>
		<v-menu :top="footer"
		        :left="!footer"
		        :offset-y="footer"
		        :offset-x="!footer"
		        :max-height="menuHeight"
		        :transition="footer ? 'slide-y-reverse-transition' : 'slide-x-reverse-transition'"
		        max-width="260"
		        eager
		>
			<template v-slot:activator="{ on }">
				<v-btn color="primary" v-on="on" :block="!footer" elevation="4">
					<v-icon left>{{mdiPlus}}</v-icon>
					Gebäude
				</v-btn>
			</template>
			<v-list>
				<template v-if="$store.getters.withExtension('glaubeUndBildung')">
					<v-list-item :disabled="!facultiesAllowed"
					             to="/choose-faculties"
					>
						<v-list-item-avatar tile>
							<img src="/img/faculties/all.png">
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>
								Fakultäten hinzufügen
							</v-list-item-title>
							<v-list-item-subtitle v-if="!facultiesAllowed">
								(Schule fehlt)
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider style="border-width: medium"></v-divider>
				</template>
				<template v-for="(building, index) in avBuildings">
					<v-list-item
							@click="addBuilding(building)"
							:disabled="building.disabled && building.name !== 'Schloss'"
							:class="[(building.name === 'Schloss' && building.disabled) ? 'pseudo-disabled' : '']"
							:ripple="building.name === 'Schloss' && !building.disabled"
					>
						<v-list-item-avatar tile>
							<img :src="getSrc(building.name)" alt="Building Icon">
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{ building.name }}</v-list-item-title>
							<v-list-item-subtitle v-if="building.cantBuildReason">
								({{ building.cantBuildReason }})
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action v-if="building.name === 'Schloss' && missingBuildings.length > 0">
							<v-btn icon @click="dialog = true">
								<v-icon class="primary--text">{{mdiInformation}}</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					<v-divider v-if="index < avBuildings.length-1"></v-divider>
				</template>
			</v-list>
		</v-menu>
		<SmallDialog :dialog.sync="dialog">
			<template v-slot:header>
				Fehlende Gebäude
			</template>
			<template v-slot:text>
				Folgende Gebäude fehlen noch, um ein Schloss bauen zu können:
				<ul>
					<li v-for="(obj, index) of missingBuildings">
						{{obj.name}}
					</li>
				</ul>
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import {mdiPlus, mdiInformation} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import {helper} from '@/_helpers';
	import {faculty, building} from "@/_model";
	import {mapGetters, mapState} from 'vuex';

	export default {
		name: "AddBuildingButton",
		props: ['footer'],
		components: {SmallDialog},
		data: () => ({
			mdiPlus, mdiInformation,
			newBuilding: null,
			menuHeight: '80vh',
			dialog: false,
		}),
		computed: {
			...mapState(["faculties", "newBuiltBuilding"]),
			...mapGetters(["facultiesAllowed", "puffer", "builtBuildingNames", "availableBuildings"]),
			avBuildings() {
				let avBuildings = helper.clone(this.availableBuildings);
				let withGlaubeUndBildung = this.$store.getters.withExtension("glaubeUndBildung");
				let withFacultyArchitecture = withGlaubeUndBildung ? this.$store.getters.chosenFaculties.some(f => f.id === "architektur") : true;
				for (let buildingObj of avBuildings) {
					buildingObj.disabled = true;
					buildingObj.cantBuildReason = null;
					// Wohnhäuser kann man immer bauen
					if (buildingObj.name === "Wohnhaus") {
						buildingObj.disabled = false;
					} else {
						if (buildingObj.name === "Schloss") {
							// Schloss-Regeln:
							if (this.builtBuildingNames.includes("Schloss")) {
								// wenn es schon ein Schloss gibt, darf ein weiteres Schloss gebaut werden
								this.checkHousesRule(buildingObj);
							} else {
								// überprüfen, ob Gebäude fehlen
								if (this.missingBuildings.length <= 0) {
									this.checkHousesRule(buildingObj);
								} else {
									buildingObj.cantBuildReason = this.missingBuildings.map(b => b.name).join(", ") + " fehlt";
								}
							}
						} else {
							// überprüfen, ob es ein Upgrade ist
							if (buildingObj.predecessor) {
								// Überprüfen, ob mit Erweiterung Glaube&Bildung und Fakultät Architektur vorhanden
								if (withGlaubeUndBildung && withFacultyArchitecture || !withGlaubeUndBildung) {
									if (this.builtBuildingNames.includes(buildingObj.predecessor)) {
										// Vorgänger vorhanden: Upgrade kann gebaut werden
										buildingObj.disabled = false;
									} else {
										buildingObj.cantBuildReason = buildingObj.predecessor + " fehlt";
									}
								} else {
									buildingObj.cantBuildReason = "Fakultät Architektur fehlt";
								}
							} else {
								// alle anderen können nur gebaut werden, wenn genügend Puffer (Wohnhäuser) vorhanden
								this.checkHousesRule(buildingObj);
							}
						}
					}
				}
				this.$store.commit("currentAvailableBuildings", avBuildings);
				return avBuildings;
			},
			missingBuildings() {
				if (this.builtBuildingNames.includes("Schloss")) {
					return [];
				} else {
					return building.getMissingBuildings();
				}
			}
		},
		methods: {
			addBuilding(newBuilding) {
				// go to /buildings if adding a building from somewhere else
				if (this.$route.path !== "/buildings") {
					this.$router.push("/buildings");
				}
				if (!newBuilding.disabled) {
					this.$store.commit("setGlobalLoading", true);
					// remove nodes in newBuilding that shouldn't be in the db
					delete newBuilding.disabled;
					delete newBuilding.cantBuildReason;
					let promise;
					if (newBuilding.predecessor) {
						promise = building.upgrade(newBuilding);
					} else {
						promise = building.add(newBuilding);
					}
					promise.then(buildingObj => {
						this.$store.commit("setNewBuiltBuilding", buildingObj);
						building.checkCastleDialog();
					}).catch(error => {
						this.$store.commit("globalError", "Bauen nicht möglich: " + error.message);
						this.$store.commit("setGlobalLoading", false);
					});
				}
			},
			checkHousesRule(buildingObj) {
				if (this.puffer > 0) {
					buildingObj.disabled = false;
				} else {
					buildingObj.cantBuildReason = "Wohnhaus fehlt";
				}
			},
			getSrc(name) {
				return building.getPicture(name);
			},
		}
	}
</script>

<style scoped>
	.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
		background-color: white;
		border-radius: 4px;
	}

	.v-list-item--disabled img {
		filter: grayscale(100%);
	}

	.castleInfoBtn {
		position: sticky;
		bottom: 3rem;
		left: 100%;
	}

	.theme--light.v-list-item.pseudo-disabled:not(.v-list-item--active):not(.v-list-item--disabled) {
		color: rgba(0, 0, 0, 0.38) !important;
		cursor: unset;
	}
</style>