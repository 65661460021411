<template>
	<div>
		<div>
			<span v-if="games.length <= 0" class="grey--text">
				{{message}}
			</span>
			<span v-else>Hier kannst du ein neues <strong>Spiel erstellen</strong>, Einstellungen zu deinen vorhandenen Spielen vornehmen, den <strong>Live-Spielstand</strong> abfragen, oder als <strong>Bürgermeister</strong> ein Spiel spielen.</span>
		</div>
		<template v-if="games.length > 0">
			<p></p>
			<v-btn class="primary" link to="/preparation" v-if="hasAccess('preparation')">
				<v-icon left>
					{{mdiPlay}}
				</v-icon>
				Spiel beitreten
			</v-btn>
			<p></p>

			<v-list>
				<v-subheader>Spiele:</v-subheader>
				<v-list-item v-for="(game, index) in games" :key="game.id">
					<v-list-item-content>
						<v-list-item-title :class="[game.played ? 'grey--text' : '']">{{game.name}}</v-list-item-title>
						<v-list-item-subtitle v-if="game.customerName">Kunde: {{game.customerName}}</v-list-item-subtitle>
						<v-list-item-subtitle v-if="game.played">bereits gespielt</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<div>
							<v-btn icon :to="{path: `/edit-game/${game.id}`}" v-if="hasAccess('edit-game')">
								<v-icon>{{mdiPencil}}</v-icon>
							</v-btn>
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on">
										<v-icon>{{mdiDotsVertical}}</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item @click="showDeleteConfirmation(game)" v-if="hasAccess('add-game')">
										<v-list-item-icon>
											<v-icon class="error--text">{{mdiDelete}}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title class="error--text">Spiel löschen</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item :to="{path: `/live-rank-game/${game.id}`}">
										<v-list-item-icon>
											<v-icon>{{mdiPoll}}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Live-Spielstand ansehen</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item @click="game.played ? markAsNotPlayed(game) : markAsPlayed(game)" v-if="hasRole('Admin')">
										<v-list-item-icon>
											<v-icon>{{game.played ? mdiMinusBox : mdiCheckboxMarked}}</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>als {{game.played ? "ungespielt" : "gespielt"}} markieren</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<br>
		</template>

		<v-btn class="primary" link to="/add-game" v-if="hasAccess('add-game')">
			<v-icon left>
				{{mdiPlus}}
			</v-icon>
			Spiel hinzufügen
		</v-btn>

		<ConfirmationDialog :dialog.sync="deleteConfirmation" :confirmed="deleteGame">
			<template v-slot:text>
				Willst du das Spiel wirklich löschen?
			</template>
		</ConfirmationDialog>
	</div>
</template>

<script>
	import {
		mdiPlay, mdiPencil, mdiDotsVertical, mdiDelete, mdiPoll, mdiPlus, mdiCheckboxMarked, mdiMinusBox
	} from '@mdi/js';
	import ConfirmationDialog from "@/components/ConfirmationDialog";
	import EditGame from "@/components/EditGame";
	import {game, auth} from "@/_helpers";
	import {games, extension, customer} from "@/_model";
	import config from "@/config";
	import {authorize} from "@/mixins";

	export default {
		name: "Games",
		mixins: [authorize],
		components: {EditGame, ConfirmationDialog},
		data: () => ({
			mdiPlay, mdiPencil, mdiDotsVertical, mdiDelete, mdiPoll, mdiPlus, mdiCheckboxMarked, mdiMinusBox,
			toggleExpansionPanel: true,
			games: [],
			loading: false,
			message: "Noch keine Spiele erstellt...",
			deleteConfirmation: false,
			tempGame: {},
		}),
		created() {
			this.writeGames();
		},
		methods: {
			writeGames() {
				this.$store.commit("setGlobalLoading", true);
				games.get().then(games => {
					this.games = games;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.message = error.message;
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			showDeleteConfirmation(game) {
				this.deleteConfirmation = true;
				this.tempGame = game;
			},
			deleteGame() {
				this.$store.commit("setGlobalLoading", true);
				games.delete(this.tempGame).then(response => {
					this.$store.commit("globalSuccess", response);
					this.writeGames();
					this.toggleExpansionPanel = !this.toggleExpansionPanel;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			play() {
				this.$router.push("/preparation");
			},
			markAsPlayed(gameObj) {
				this.$store.commit("setGlobalLoading", true);
				game.markAsPlayed(gameObj).then(response => {
					this.$store.commit("globalSuccess", response);
					gameObj.played = true;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			markAsNotPlayed(gameObj) {
				this.$store.commit("setGlobalLoading", true);
				game.markAsNotPlayed(gameObj).then(response => {
					this.$store.commit("globalSuccess", response);
					gameObj.played = false;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},

			call(function_name, game) {
				this[function_name](game)
			},
		}
	}
</script>

<style scoped>

</style>