import { render, staticRenderFns } from "./ChooseFaculties.vue?vue&type=template&id=123b69ca&scoped=true"
import script from "./ChooseFaculties.vue?vue&type=script&lang=js"
export * from "./ChooseFaculties.vue?vue&type=script&lang=js"
import style0 from "./ChooseFaculties.vue?vue&type=style&index=0&id=123b69ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123b69ca",
  null
  
)

export default component.exports