import {backend, helper} from "@/_helpers";
import moment from "moment";

export const customer = {
	async insert(customerObj) {
		return backend.post("insertCustomer", {customerObj})
	},
	async getAll() {
		return await backend.post("getCustomers");
	},
	async getById(id) {
		return await backend.post("getCustomer", {id});
	},
	async delete(customerObj) {
		return await backend.post("deleteCustomer", {customerObj});
	}
};

// function toCouchFormat(customerObj) {
// 	let customer = helper.clone(customerObj);
// 	customer.exp = moment(customer.exp).add(1, "days").toISOString().substr(0, 10);
// 	for (let accessObj of customer.access) {
// 		accessObj.exp = customer.exp;
// 	}
// 	return customer;
// }
// function toVueFormat(customerObj) {
// 	customerObj.exp = customerObj.access[0].exp;
// 	// customerObj.exp = moment(customerObj.exp).subtract(1, "days").toISOString().substr(0, 10);
// 	return customerObj;
// }