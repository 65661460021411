<template>
	<div>
		<p v-if="customers.length <= 0" class="grey--text">
			{{ message }}
		</p>
		<div v-else>
			<v-list three-line>
				<v-list-item v-for="(customerObj, index) in customers" :key="customerObj.name">
					<v-list-item-content>
						<v-list-item-title>{{customerObj.name}}</v-list-item-title>
						<v-list-item-subtitle>gültig bis {{expireDate(customerObj)}} ({{getDate(customerObj)}})
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<div>
							<v-btn icon :to="{path: `/edit-customer/${customerObj._id}`}">
								<v-icon>{{mdiPencil}}</v-icon>
							</v-btn>
							<v-btn icon @click="showDeleteConfirmation(customerObj)">
								<v-icon>{{mdiDelete}}</v-icon>
							</v-btn>
						</div>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</div>
		<v-btn class="primary" to="/add-customer">
			<v-icon left>{{mdiPlus}}</v-icon>
			neuer Kunde
		</v-btn>

		<ConfirmationDialog :dialog.sync="deleteConfirmation" :confirmed="deleteCustomer">
			<template v-slot:text>
				Willst du den Kunden wirklich löschen?
			</template>
		</ConfirmationDialog>
	</div>
</template>

<script>
	import {mdiPencil, mdiDelete, mdiPlus} from "@mdi/js";
	import {customer} from "@/_model";
	import moment from "moment";
	import ConfirmationDialog from "@/components/ConfirmationDialog";

	export default {
		name: "Customers",
		components: {ConfirmationDialog},
		data: () => ({
			mdiPencil, mdiDelete, mdiPlus,
			customers: [],
			message: "Noch keine Kunden erstellt...",
			deleteConfirmation: false,
			tempCustomer: {}
		}),
		created() {
			this.writeCustomers();
		},
		methods: {
			writeCustomers() {
				this.$store.commit("setGlobalLoading", true);
				customer.getAll().then(customers => {
					this.customers = customers;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.message = error.message;
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			expireDate(customer) {
				return moment(customer.exp).fromNow();
			},
			getDate(customer) {
				return moment(customer.exp).calendar();
			},
			deleteCustomer() {
				this.$store.commit("setGlobalLoading", true);
				customer.delete(this.tempCustomer).then(response => {
					this.$store.commit("globalSuccess", response);
					this.writeCustomers();
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.message = error.message;
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			showDeleteConfirmation(customerObj) {
				this.deleteConfirmation = true;
				this.tempCustomer = customerObj;
			},
		}
	}
</script>

<style scoped>

</style>