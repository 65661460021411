import {gameDb} from "@/_controller";
import {building} from "@/_model";
import config from "@/config";
import store from "@/store";

export const evaluation = {
	async getValue(type) {
		return gameDb.instance.getValue(config.evalPrefix + type)
	},
	async updateValue(type, value) {
		return gameDb.instance.updateValue(config.evalPrefix + type, value);
	},
	async getBuiltBuildings(evalBuiltHousesCount = null, evalBuiltCastlesCount = null, builtBuildings = null) {
		if (!builtBuildings) {
			builtBuildings = store.state.builtBuildings;
		}
		let arr = [
			{
				name: "Wohnhaus",
				built: builtBuildings.filter(b => b.name === "Wohnhaus").length,
				eval: evalBuiltHousesCount !== null ?
						evalBuiltHousesCount : await evaluation.getValue("builtHousesCount")
			},
			{
				name: "Schloss",
				built: builtBuildings.filter(b => b.name === "Schloss").length,
				eval: evalBuiltCastlesCount !== null ?
						evalBuiltCastlesCount : await evaluation.getValue("builtCastlesCount")
			}
		];
		for (let item of arr) {
			if (item.eval !== item.built) {
				if (item.eval >= item.built) {
					let difference = item.eval - item.built;
					// add the difference between them to the builtBuildings object
					for (let i = 0; i < difference; i++) {
						let houseObj = store.getters.availableBuildings.find(b => b.name === item.name);
						builtBuildings.push(houseObj);
					}
				} else {
					// remove the difference between them from the builtBuildings object
					let difference = item.built - item.eval;
					for (let i = 0; i < difference; i++) {
						let index = builtBuildings.findIndex(b => b.name === item.name);
						builtBuildings.splice(index, 1);
					}
				}
			}
		}
		return builtBuildings;
	},
  getProductionBuildings(builtBuildings) {
    return builtBuildings.filter((buildingObj) => {
      return building.isProductionBuilding(buildingObj);
    });
  },
};
