<template>
	<v-row>
		<template v-for="(ext, index) in extensionsObj" v-if="ext.enabled">
			<v-col cols="12" md="6">
				<v-card>
					<v-card-title>{{ ext.name }}:</v-card-title>
					<div class="images" v-viewer>
						<img :src="getChecklistPicture(ext)">
					</div>
				</v-card>
			</v-col>
		</template>
	</v-row>
</template>

<script>
	import {extension} from "@/_model";

	export default {
		name: "ChecklistPictures",
		props: ["extensions"],
		computed: {
			extensionsObj() {
				let grundspiel = extension.create("grundspiel", "Grundspiel", true);
				let extensions = [
					grundspiel,
					...this.extensions
				];
				return extensions;
			}
		},
		methods: {
			getChecklistPicture(ext) {
				return extension.getPicture(ext);
			}
		}
	}
</script>

<style scoped>
	img {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
</style>