<template>
	<div>
		<v-alert type="error" :icon="mdiAlertCircle">
			Error 404: Seite nicht gefunden!
		</v-alert>
		<v-btn class="primary" link to="/" block>
      <v-icon left>
        {{mdiChevronLeft}}
      </v-icon>
      Zurück zur Startseite
    </v-btn>
	</div>
</template>

<script>
	import {mdiAlertCircle, mdiChevronLeft} from "@mdi/js";

	export default {
		name: "NotFound",
		data: () => ({
			mdiAlertCircle, mdiChevronLeft
		})
	}
</script>

<style scoped>

</style>