import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from "vuetify/lib/util/colors";
// import Touch from 'vuetify/es5/directives/touch'

Vue.use(Vuetify,
		// {directives: {Touch}}
);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#195aa9",
				secondary: colors.blue.lighten2,
				accent: colors.blue.lighten2,
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				grundspiel: '#CBCFD4',
				naturkatastrophen: '#FF2828',
				räuberUndGendarm: '#42C47D',
				glaubeUndBildung: '#548DD4',
				zufriedenheit: '#FFC000',
			}
		}
	},
	breakpoint: {
		thresholds: {
			sm: 1000
		}
	}
});
