<template>
	<v-dialog :value="dialogVal" max-width="300" scrollable @click:outside="dialogVal = false">
		<v-card>
			<v-card-title class="headline">
				<slot name="header"></slot>
			</v-card-title>

			<v-card-text>
				<slot name="text"></slot>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<slot name="actions">
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialogVal = false">
						Schließen
            <v-icon right>
              {{mdiClose}}
            </v-icon>
					</v-btn>
				</slot>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
  import {mdiClose} from "@mdi/js";
	export default {
		name: "SmallDialog",
		props: ["dialog"],
    data: () => ({
      mdiClose
    }),
		computed: {
			dialogVal: {
				get() {
					return this.dialog;
				},
				set(value) {
					this.$emit('update:dialog', value)
				}
			}
		},
	}
</script>

<style scoped>

</style>