<template>
	<div>
		<p>Zufriedenheit der Siedler ablesen:</p>

		<div class="text-center">
			<v-chip class="ma-2" color="green" text-color="white">
				<v-avatar left class="green darken-4"></v-avatar>
				Anzahl Gold
			</v-chip>
			<v-chip class="ma-2" color="red" text-color="white">
				<v-avatar left class="red darken-4"></v-avatar>
				Anzahl aktuelle Streiks
			</v-chip>
		</div>
		<br>
		<div class="d-flex flex-column align-center">
			<v-img src="/img/zufriedenheitswaage.jpg" width="230"></v-img>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HintZufriedenheit",
	}
</script>

<style scoped>

</style>