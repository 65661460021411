import {ls, backend, auth} from "@/_helpers";
import store from "@/store";
import config from "@/config";

export const game = {
	exists() {
		return !!game.getCurrent();
	},
	setIsStarted(value) {
		ls.setItem(config.gamePrefix + "isStarted", value);
		store.commit("setGameIsStarted", value);
	},
	isStarted() {
		return ls.getBool(config.gamePrefix + "isStarted") || false;
	},
	setIsEnded(value) {
		ls.setItem(config.gamePrefix + "isEnded", value);
		store.commit("setGameIsEnded", value);
	},
	isEnded() {
		return ls.getBool(config.gamePrefix + "isEnded") || false;
	},
	setIsPaused(value) {
		ls.setItem(config.gamePrefix + "isPaused", value);
		store.commit("setGameIsPaused", value);
	},
	isPaused() {
		return ls.getBool(config.gamePrefix + "isPaused") || false;
	},
	setPlayingGame(value) {
		ls.setItem(config.gamePrefix + "playing", value);
		store.commit("setPlayingGame", value);
	},
	playingGame() {
		return ls.getBool(config.gamePrefix + "playing") || false;
	},

	getCurrent() {
		return ls.getObject(config.gamePrefix + "current");
	},
	setCurrent(value) {
		store.commit("setCurrentGame", value);
		ls.setItem(config.gamePrefix + "current", JSON.stringify(value));
	},
	removeCurrent() {
		store.commit("setCurrentGame", null);
		ls.removeItem(config.gamePrefix + "current");
	},

	backToGame() {
		game.setIsEnded(false);
		game.setIsStarted(true);
	},
	removeFromLocalStorage() {
		ls.removeAll(config.gamePrefix);
	},

	getShowHouses() {
		return ls.getBool(config.gamePrefix + "showHouses") || true;
	},
	setShowHouses(value) {
		store.commit("showHouses", value);
		ls.setItem(config.gamePrefix + "showHouses", value);
	},
	initShowHouses() {
		let value = this.getShowHouses() || true;
		store.commit("showHouses", value);
		return value;
	},

	async markAsPlayed(gameObj = null) {
		let data = {
			gameId: gameObj ? gameObj.id : game.getCurrent().id,
			customerId: gameObj ? gameObj.customerId : store.state.currentUser.id
		};
		return backend.post("markGameAsPlayed", data);
	},
	async markAsNotPlayed(gameObj = null) {
		let data = {
			gameId: gameObj ? gameObj.id : game.getCurrent().id,
			customerId: gameObj ? gameObj.customerId : store.state.currentUser.id
		};
		return backend.post("markGameAsNotPlayed", data);
	},

	async deleteDb(dbName) {
		return backend.post("deleteDb", {dbName});
	}
};