import config from "@/config";

export const ls = {
	getBool(itemName) {
		return ls.getObject(itemName);
	},
	getInt(itemName) {
		return parseInt(ls.getItem(itemName));
	},
	getObject(itemName) {
		return JSON.parse(ls.getItem(itemName));
	},
	setObject(itemName, value) {
		ls.setItem(itemName, JSON.stringify(value));
	},
	setItem(itemName, value) {
		localStorage.setItem(config.lsPrefix + itemName, value);
	},
	getItem(itemName) {
		return localStorage.getItem(config.lsPrefix + itemName);
	},
	removeItem(itemName) {
		localStorage.removeItem(config.lsPrefix + itemName);
	},
	removeAll(secondPrefix = "") {
		for (let itemName in localStorage) {
			if (localStorage.hasOwnProperty(itemName)) {
				if (itemName.startsWith(config.lsPrefix + secondPrefix)) {
					localStorage.removeItem(itemName);
				}
			}
		}
	}
};
