<template>
	<div>
		<p>Durch Produktionsgebäude erzeugte Rohstoffe, die ausgegeben werden müssen:</p>
		<div class="d-flex flex-column align-center">
			<template v-for="(item, index) in productionBuildings" v-if="item.count > 0">
				<div class="pa-2">
					<div class="d-flex align-center">
						<v-img max-width="70" :src="item.buildingSrc" class="ma-2"></v-img>
						<v-icon>{{mdiArrowRight}}</v-icon>
						<v-badge overlap right bordered :color="item.color" :content="'+'+item.count" class="ma-2">
							<v-img width="60" :src="item.resourceSrc">
							</v-img>
							<div class="text-center resource-caption">
								{{item.resource}}
							</div>
						</v-badge>
					</div>
					<div class="text-center" v-if="item.faculty">(inkl. Fakultät {{item.faculty.name}})</div>
				</div>
			</template>
		</div>
<!--   TODO: wenn mit Zufriedenheit: Hinweis, dass bestreikte Gebäude nicht zählen und selbst beachtet werden müssen-->
	</div>
</template>

<script>
	import {mdiArrowRight} from "@mdi/js";
	import {building, faculty} from "@/_model";
	import {helper} from "@/_helpers";

	export default {
		name: "HintProduction",
		data: () => ({
			mdiArrowRight,
			productionBuildings: [
				{
					name: 'Sägewerk',
					count: 0,
					resource: 'Holz',
					buildingSrc: '',
					resourceSrc: '',
					color: 'brown',
					faculty: false
				},
				{
					name: 'Bergwerk',
					count: 0,
					resource: 'Stein',
					buildingSrc: '',
					resourceSrc: '',
					color: 'grey',
					faculty: false
				},
				{
					name: 'Schmiede',
					count: 0,
					resource: 'Eisen',
					buildingSrc: '',
					resourceSrc: '',
					color: 'blue-grey',
					faculty: false
				},
				{
					name: 'Dreschmaschine',
					count: 0,
					resource: 'Stroh',
					buildingSrc: '',
					resourceSrc: '',
					color: 'orange',
					faculty: false
				},
			],
		}),
		created() {
			// get buildingSrc, resourceSrc and count
			let builtBuildings = this.$store.state.builtBuildings;
			for (let builtBuilding of builtBuildings) {
				if (building.isProductionBuilding(builtBuilding)) {
					let dataItem = this.productionBuildings.find(b => b.name === builtBuilding.name);
					if (dataItem) {
						dataItem.buildingSrc = building.getPicture(builtBuilding.name);
						dataItem.resourceSrc = helper.getResourcePicture(dataItem.resource);
						dataItem.count++;
					}
				}
			}
			for (let buildingObj of this.productionBuildings) {
				let facultyInstance = faculty.getByResource(buildingObj.resource);
				if (facultyInstance) {
					buildingObj.count *= 2;
					buildingObj.faculty = facultyInstance;
				}
			}
		},
	}
</script>

<style scoped>
	.resource-caption {
		margin-top: 3px;
	}
</style>