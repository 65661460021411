<template>
	<v-bottom-sheet v-model="sheet" inset :overlay-opacity="0.95">
		<v-card height="450px" class="d-flex flex-column align-center">
			<CardTitleClose title="Cantopia-App installieren" :close="() => this.sheet = false"></CardTitleClose>
			<PseudoApps></PseudoApps>
			<div class="pa-4 grey--text">
				<div v-if="mobileBrowser === 'Safari'">
					<ol>
						<li>
							Tippe auf
							<img src="/img/addToHomeScreen/apple-share-icon-30x38.png" width="20" class="inline-icon">
						</li>
						<li>
							Wähle <span class="font-italic">"Zum Home-Bildschirm"</span>
						</li>
						<li>
							Tippe auf <span class="font-italic">"Hinzufügen"</span>
						</li>
					</ol>
				</div>
				<div v-if="mobileBrowser === 'Chrome'">
					<ol>
						<li>
							Tippe auf
							<v-icon>{{mdiDotsVertical}}</v-icon>
							oben rechts im Browser
						</li>
						<li>
							Wähle <span class="font-italic">"Zum Startbildschirm zufügen"</span>
						</li>
						<li>
							Tippe auf <span class="font-italic">"Hinzufügen"</span>
						</li>
					</ol>
				</div>
				<div v-if="mobileBrowser === 'Samsung'">
					<ol>
						<li>
							Tippe auf
							<v-icon style="transform: scaleY(1.5)">{{mdiMenu}}</v-icon>
							im Browser
						</li>
						<li>
							Wähle <span class="font-italic">"Seite hinzufügen zu"</span> -
							<span class="font-italic">"Startbildschirm"</span>
						</li>
						<li>
							Tippe auf <span class="font-italic">"Hinzufügen"</span>
						</li>
					</ol>
				</div>
				<br>
				<div class="subtitle-2 grey--text text-center">
					<p>Schon installiert? Öffne sie vom Home-Bildschirm</p>
					<p>Oder: <a @click="sheet = false">einfach hier benutzen</a></p>
				</div>
			</div>
		</v-card>
	</v-bottom-sheet>
</template>

<script>
	import PseudoApps from "@/components/PseudoApps";
	import CardTitleClose from "@/components/CardTitleClose";
	import {mdiDotsVertical, mdiMenu} from "@mdi/js";
	import {mapFields} from 'vuex-map-fields';
	import {ls} from "@/_helpers";
	import config from "@/config";

	export default {
		name: "AddToHomeScreen",
		components: {CardTitleClose, PseudoApps},
		data: () => ({
			mdiDotsVertical, mdiMenu,
			sheet: false,
		}),
		computed: {
			mobileBrowser() {
				// return "Samsung";
				let userAgent = navigator.userAgent || navigator.vendor || window.opera;
				if (/SamsungBrowser/i.test(userAgent)) return "Samsung";
				if (/Android/i.test(userAgent)) return "Chrome";
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "Safari";
				return null;
			},
			hostname() {
				return location.hostname;
			},
		},
		created() {
			let isPWA = window.matchMedia('(display-mode: standalone)').matches;
			if (isPWA) {
				ls.setItem("pwaHost", location.hostname);
			} else {
				let addToHomeScreenShown = ls.getInt("addToHomeScreenShown") || 0;
				// show sheet if: not yet showed 2 times && on mobile OS && not mid-game
				if (addToHomeScreenShown < config.addToHomeScreenMax && this.mobileBrowser &&
						!this.$route.path.startsWith("/buildings")
				) {
					ls.setItem("addToHomeScreenShown", addToHomeScreenShown + 1);
					this.sheet = true;
				}
			}
		},
	}
</script>

<style scoped>
	ol {
		list-style: none;
		counter-reset: li
	}

	li::before {
		content: counter(li) ".";
		color: #195aa9;
		display: inline-block;
		width: 1em;
		margin-left: -1em
	}

	li {
		counter-increment: li
	}

	.inline-icon {
		display: inline;
		margin-left: 4px;
		margin-bottom: -4px;
	}
</style>