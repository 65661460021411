<template>
	<div class="max-width">
		<div class="pa-4">
			<div class="caption grey--text">Grundspiel:</div>
			<div class="d-flex align-center">
				<div class="d-flex flex-column" style="border-right: 1px solid lightgray;">
					<v-chip>
						<v-avatar left color="grey" class="white--text darken-1">{{housesCount}}</v-avatar>
						Wohnhäuser
					</v-chip>
          <v-chip>
            <v-avatar left color="grey" class="white--text darken-1">{{publicBuildingsCount}}</v-avatar>
            öffentl. Gebäude
          </v-chip>
					<v-chip>
						<v-avatar left color="grey" class="white--text darken-1">{{otherBuildingsCount}}</v-avatar>
						andere Gebäude
					</v-chip>
				</div>
				<div>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-chip v-on="on" :class="{'error': !buildingRuleOk}" class="elevation-4">
								Puffer
								<v-avatar right color="grey" class="white--text darken-1">{{puffer}}</v-avatar>
							</v-chip>
						</template>
						<span v-if="buildingRuleOk">
							So viele andere Gebäude können noch gebaut werden, bis wieder ein Wohnhaus gebaut werden muss
						</span>
						<span v-else>Bauregel verletzt: Zu wenig Wohnhäuser</span>
					</v-tooltip>
				</div>
			</div>
		</div>
		<template v-if="$store.getters.withExtension('räuberUndGendarm') && roundsToNextAttackChip">
			<v-divider></v-divider>
			<div class="pa-4">
				<div class="caption grey--text">Räuber und Gendarm:</div>
				<div class="d-flex align-center">
					<div class="d-flex flex-column">
						<v-chip color="räuberUndGendarm" class="white--text ma-1">
							nächster Überfall-Chip in
							<v-avatar right color="räuberUndGendarm" class="darken-3">{{roundsToNextAttackChip}}</v-avatar>
						</v-chip>
					</div>
				</div>
			</div>
		</template>
		<template v-if="$store.getters.withExtension('glaubeUndBildung') && chosenFaculties.length > 0">
			<v-divider></v-divider>
			<div class="pa-4">
				<div class="caption grey--text">Glaube und Bildung: (gegründete Fakultäten)</div>
				<div class="d-flex align-start pt-1 row justify-start ma-0">
					<v-tooltip top v-for="faculty in chosenFaculties">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="faculty pa-1 d-flex align-start flex-column col-4 col-md">
								<v-avatar class="elevation-2" size="56">
									<v-img :src="'img/faculties/'+faculty.id+'.png'" max-width="60"></v-img>
								</v-avatar>
								<span class="text-caption text-center faculty-text pt-1">
									{{faculty.name}}
									<template v-if="faculty.resource">
										<br>
										({{faculty.resource}} x2)
									</template>
								</span>
							</div>
						</template>
						<span v-if="faculty.id === 'architektur'">
							Du kannst jetzt Gebäude ausbauen
						</span>
						<span v-else>
							Doppelte {{faculty.resource}}-Produktion pro Runde
						</span>
					</v-tooltip>
					<div class="pa-1 col-4 col-md d-flex align-start flex-column"
						 v-if="chosenFaculties.length !== faculties.length"
					>
						<v-btn fab color="primary" elevation="2" @click="$router.push({'name': 'choose-faculties'})">
							<v-icon>{{mdiPlus}}</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {mdiPlus} from "@mdi/js";
	import {attackChip} from "@/_controller";
	import {mapState, mapGetters} from "vuex";

	export default {
		name: "BuildingChips",
		data: () => ({
			mdiPlus
		}),
		computed: {
			...mapState(["round", "builtBuildings", "faculties"]),
			...mapGetters(["puffer", "otherBuildingsCount", "housesCount", "chosenFaculties", "publicBuildingsCount"]),
			buildingRuleOk() {
				return this.puffer >= 0;
			},
			roundOfFirstWache() {
				return attackChip.getRoundOfFirstWache(this.builtBuildings);
			},
			roundsToNextAttackChip() {
				return attackChip.roundsToNextAttackChip(this.builtBuildings, this.round);
			}
		}
	}
</script>

<style scoped>
	.v-chip {
		margin: .5em;
	}
	.caption {
		/*position: absolute;*/
		/*padding-left: 2em;*/
	}
	.faculty .faculty-text {
		line-height: 1.4;
	}
</style>
