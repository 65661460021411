<template>
	<div>
		<p>Bilder mit Materialien für das Spiel aufgeteilt auf die gewählten Erweiterungen:</p>
		<ChecklistPictures :extensions="currentGame.settings.extensions"></ChecklistPictures>
    <v-btn to="/buildings" text color="primary">
      <v-icon left>
        {{mdiChevronLeft}}
      </v-icon>
      Zurück
    </v-btn>
	</div>
</template>

<script>
	import ChecklistPictures from "@/components/ChecklistPictures";
	import {mapState} from 'vuex';
  import {mdiChevronLeft} from "@mdi/js";

	export default {
		name: "Checklist",
		components: {ChecklistPictures},
    data: () => ({
      mdiChevronLeft
    }),
		computed: {
			...mapState(["currentGame"]),
		}
	}
</script>

<style scoped>

</style>