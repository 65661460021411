import PouchDB from 'pouchdb-browser';
import config from "@/config";
import {backend} from "@/_helpers";
import localConfig from "../../localConfig";

PouchDB.plugin(require('pouchdb-find'));
PouchDB.plugin(require('pouchdb-live-find'));

export let Pouch = function (name) {
	let localDb;
	let dbName = name;

	if (!dbName.startsWith(config.dbPrefix)) {
		dbName = config.dbPrefix + dbName;
	}
	localDb = new PouchDB(dbName, {revs_limit: 1, auto_compaction: true});
	return Object.assign(this, {
		localDb, dbName,
		replication: null,
		changes: []
	});
};
Pouch.prototype.replicateFrom = function () {
	this.replication = this.localDb.replicate.from(config.couchUrl + this.dbName, {
		auth: {
			username: localConfig.couch.username,
			password: localConfig.couch.password,
		},
		live: true,
		retry: true
	})
};
// doc functions
Pouch.prototype.getValue = async function (name) {
	return await this.localDb.get(name).then(doc => {
		return Promise.resolve(doc.value);
	});
};
Pouch.prototype.getValues = async function (type) {
	return await this.getDocs(type).then(res => {
		return Promise.resolve(res.map(d => d[type]));
	}).catch(error => {
		return Promise.reject([]);
	});
};
Pouch.prototype.getDocs = async function (type) {
	return await this.localDb.allDocs({
		include_docs: true,
		startkey: type,
		endkey: type + '\ufff0',
	}).then(result => {
		return Promise.resolve(result.rows.map(r => r.doc));
	});
};
Pouch.prototype.updateValue = async function (name, value) {
	return await this.localDb.get(name).then(doc => {
		// update value
		doc.value = value;
		return this.localDb.put(doc);
	}).catch(error => {
		// initially store value
		let doc = {_id: name, type: name, value,};
		return this.localDb.put(doc);
	});
};
Pouch.prototype.putDoc = async function (type, object) {
	if (!object.timeAdded) {
		object.timeAdded = new Date().toISOString();
	}
	let doc = {
		_id: type + "_" + object.timeAdded,
		type: type,
	};
	doc[type] = object;
	return this.localDb.put(doc);
};
Pouch.prototype.overwriteDoc = async function (obj, doc, type, excludedProps = ['timeAdded']) {
	// replace every prop except timeAdded
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			if (!excludedProps.includes(prop)) {
				doc[type][prop] = obj[prop];
			}
		}
	}
	// write overwritten doc props to db
	return await this.localDb.put(doc).then(res => {
		return Promise.resolve(doc);
	});
};
Pouch.prototype.deleteDoc = async function (type, object) {
	let docId = type + '_' + object.timeAdded;
	return await this.localDb.get(docId).then(doc => {
		doc._deleted = true;
		return this.localDb.put(doc);
	});
};

Pouch.prototype.registerChange = function (docType, callback = null) {
	let change = this.localDb.changes({
		since: 'now',
		live: true,
		include_docs: true,
		filter: doc => {
			return doc.type === docType;
		}
	}).on('change', change => {
		if (callback) callback(change);
	});
	this.changes.push(change);
	return change;
};
Pouch.prototype.registerDestroyed = function (callback = null) {
	this.localDb.on("destroyed", destroyedDbName => {
		if (callback) callback(destroyedDbName);
	});
};
Pouch.prototype.replicateTo = async function () {
	return await this.createRemoteDb().then(response => {
		let remoteDb = new PouchDB(config.couchUrl + this.dbName, {
			revs_limit: 1, auto_compaction: true,
			auth: {
				username: localConfig.couch.username,
				password: localConfig.couch.password
			}
		});
		this.replication = this.localDb.replicate.to(remoteDb, {
			live: true,
			retry: true
		});
		return Promise.resolve();
	});
};
Pouch.prototype.createRemoteDb = async function () {
	let data = {dbName: this.dbName};
	return backend.post("createDb", data);
};
Pouch.prototype.deleteRemoteDb = async function () {
	let data = {dbName: this.dbName};
	return await backend.post("deleteDb", data);
};
Pouch.prototype.cancelEvents = function () {
	for (let change of this.changes) {
		change.cancel();
	}
	if (this.replication) {
		this.replication.cancel();
	}
};
Pouch.prototype.destroy = function () {
	this.cancelEvents();
	return this.localDb.destroy();
};