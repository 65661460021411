import {backend, ls} from "@/_helpers";
import store from "@/store";
import config from "@/config";
import {router} from "@/router";
export const auth = {
	async login(access_code) {
		return await backend.post("authenticate", {access_code}).then(response => {
			auth.removeCurrentUser();
			auth.setCurrentUser(response);
			return Promise.resolve(response);
		})
	},
	logout() {
		auth.removeCurrentUser();
		auth.removeFromLocalStorage();
	},
	getCurrentUser() {
		return ls.getObject(config.authPrefix + "currentUser");
	},
	setCurrentUser(value) {
		store.commit("setCurrentUser", value);
		ls.setItem(config.authPrefix + "currentUser", JSON.stringify(value));
	},
	removeCurrentUser() {
		store.commit("setCurrentUser", null);
		ls.removeItem(config.authPrefix + "currentUser");
	},
	removeFromLocalStorage() {
		ls.removeAll(config.authPrefix);
	},
	isAuthenticated() {
		let currentUser = auth.getCurrentUser();
		return currentUser && currentUser._id.startsWith("customer_") && !auth.expired();
	},
	expired() {
		let currentUser = auth.getCurrentUser();
		return currentUser && currentUser.exp < new Date().getTime();
	},
	hasAccess(routeName, roles = null) {
		let route = router.options.routes.find(r => r.name === routeName);
		if (route) {
			if (route.meta && route.meta.authorize) {
				roles = roles || auth.getCurrentUser().roles;
				return route.meta.authorize.some(r => roles.includes(r));
			}
			return true;
		}
		return false;
	},
	hasRole(role) {
		return auth.getCurrentUser().roles.map(r => r.toLowerCase()).includes(role.toLowerCase());
	}
};