<template>
	<SmallDialog :dialog.sync="showMissedRoundsDialog">
		<template v-slot:header>
			Klingeln verpasst!
		</template>
		<template v-slot:text>
			<v-alert type="warning" :icon="mdiAlertCircle">
				Du hast <strong>{{missedRounds}}x klingeln</strong> verpasst!
			</v-alert>
			<p>Gegebenenfalls Würfeln nachholen!</p>
		</template>
	</SmallDialog>
</template>

<script>
	import {mdiDiceMultiple, mdiAlertCircle} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import {mapState} from 'vuex';
	import {mapFields} from 'vuex-map-fields';

	export default {
		name: "MissedRoundDialog",
		components: {SmallDialog},
		data: () => ({
			mdiDiceMultiple, mdiAlertCircle,
		}),
		computed: {
			...mapState(["missedRounds"]),
			...mapFields(["showMissedRoundsDialog"]),
		}
	}
</script>

<style scoped>

</style>