<template>
	<SmallDialog :dialog.sync="showBuildCastleDialog">
		<template v-slot:header>
			Schloss
		</template>
		<template v-slot:text>
			<v-alert type="info" :icon="mdiCastle" prominent>
				<span v-if="puffer > 0">Du kannst jetzt ein Schloss bauen!</span>
				<span v-else>Dir fehlt nur noch <strong>1 Wohnhaus</strong> für ein Schloss!</span>
			</v-alert>
		</template>
	</SmallDialog>
</template>

<script>
	import {mdiCastle} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import {mapGetters} from 'vuex';
	import {mapFields} from 'vuex-map-fields';

	export default {
		name: "BuildCastleDialog",
		components: {SmallDialog},
		data: () => ({
			mdiCastle,
		}),
		computed: {
			...mapFields(["showBuildCastleDialog"]),
			...mapGetters(["puffer"]),
		}
	}
</script>

<style scoped>

</style>