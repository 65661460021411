<template>
	<v-list-item>
		<v-list-item-avatar size="62" tile>
			<img :src="getSrc" alt="Building Icon">
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title>{{buildingObj.name}}</v-list-item-title>
		</v-list-item-content>
		{{buildingObj.wp}}
		<v-list-item-action>
			<v-btn icon>
				<v-icon color="grey lighten-1">{{mdiDotsVertical}}</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
	import {mdiDotsVertical} from "@mdi/js";
	import {helper} from '@/_helpers';
	import {building} from '@/_model';

	export default {
		name: "Building",
		props: ["buildingObj", "index"],
		data: () => ({
			mdiDotsVertical
		}),
		methods: {
			getSrc() {
				return building.getPicture(this.buildingObj.name);
			},
		}
	}
</script>

<style scoped>
</style>