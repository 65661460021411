<template>
	<div class="d-flex justify-center ma-8">
		<div class="pseudo-app lighten-3 grey"></div>
		<v-img class="pseudo-app" src="/img/addToHomeScreen/app-icon-round-120x120.png" width="80"></v-img>
		<!--                <div class="d-flex flex-column align-center pseudo-app">-->
		<!--                    <v-img src="/img/addToHomeScreen/app-icon-round-150x150.png" width="80"></v-img>-->
		<!--                    <span id="app-caption">Cantopia-App</span>-->
		<!--                </div>-->
		<div class="pseudo-app lighten-3 grey"></div>
		<div class="pseudo-app lighten-4 grey"></div>
	</div>
</template>

<script>
	export default {
		name: "PseudoApps"
	}
</script>

<style scoped>
	.pseudo-app {
		max-width: 80px;
		min-width: 80px;
		height: 80px;
		border-radius: 1em;
		margin: 0 0.7em;
	}
</style>