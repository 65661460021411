<template>
	<div>
		<!--		toolbar-->
		<v-app-bar clipped-left clipped-right app class="primary" id="app-bar">
			<v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer">
				<v-icon>{{mdiMenu}}</v-icon>
			</v-app-bar-nav-icon>
			<v-toolbar-title :class="{'hidden-xs-only': gameIsStarted}" class="white--text">
				{{ this.$route.meta.title }}
			</v-toolbar-title>
			<template v-if="gameIsStarted && this.$vuetify.breakpoint.xsOnly">
				<v-spacer></v-spacer>
				<template v-for="(item, index) in toolbarInfos">
					<v-btn class="header-item" color="primary" elevation="0" fab depressed tile
					       @click="call(item.onClick)"
					>
						{{ item.short }}: {{ getState(item.id) }}
					</v-btn>
				</template>
			</template>
		</v-app-bar>

		<!--		left drawer-->
		<v-navigation-drawer app clipped v-model="drawer" id="drawer" ref="leftDrawer" :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="title">
						<div class="d-flex align-center">
							<img src="/img/splash/android-512x512.png" width="40" id="drawer-app-icon">
							<span>Cantopia-App</span>
						</div>
					</v-list-item-title>
					<v-list-item-subtitle>
						made by <span class="body-1">FluhrerGames</span>
					</v-list-item-subtitle>
					<v-list-item-subtitle>
						Version: {{version}}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list dense nav>
				<template v-if="gameIsStarted">
          <div class="sub-menu">
            <v-subheader>Komponenten</v-subheader>
            <template v-for="(item, index) in sites">
              <v-list-item link v-if="show(item)" :to="item.link">
                <v-list-item-icon>
                  <v-icon v-bind:class="[item.classes]">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-bind:class="[item.classes]">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
          <div class="sub-menu">
            <v-subheader>Spiel-Steuerung</v-subheader>
            <template v-for="(item, index) in controlElements">
              <v-list-item link :disabled="show(item)" @click="closeDrawerOnMobile(); call(item.onClick)"
              >
                <v-list-item-icon>
                  <v-icon :disabled="show(item)">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item link @click="closeDrawerOnMobile(); endConfirmation = true">
              <v-list-item-icon>
                <v-icon class="primary--text">{{ mdiExitRun }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text">Spiel beenden</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
					<template>
            <div class="sub-menu">
              <v-subheader>Sonstiges</v-subheader>
              <v-list-item
                  link
                  :to="{ name: 'scan-qr' }"
                  v-if="$store.getters.withAllExtensions(['glaubeUndBildung', 'naturkatastrophen'])"
              >
                <v-list-item-icon>
                  <v-icon>{{ mdiQrcodeScan }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>QR-Code scannen</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="isBuildingsView">
                <v-list-item-action class="adjust-action-margin">
                  <v-switch v-model="showHouses" color="primary"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Wohnhäuser anzeigen</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
					</template>
				</template>
				<template v-else-if="currentUser && !currentGame">
          <div class="sub-menu">
            <v-subheader>Seiten</v-subheader>
            <template v-for="(item, index) in sitesBeforeGame">
              <v-list-item link v-if="hasAccess(item.id)" :key="item.id" :to="item.link">
                <v-list-item-icon>
                  <v-icon v-bind:class="[item.classes]">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-bind:class="[item.classes]">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
				</template>
				<template v-if="(isAuthenticated && !gameIsStarted && !currentGame) || gameIsEnded || currentGame">
          <div class="sub-menu">
            <v-subheader>Aktionen</v-subheader>
            <v-list-item link @click="logout" v-if="isAuthenticated && !gameIsStarted && !currentGame">
              <v-list-item-icon>
                <v-icon>{{mdiLogoutVariant}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ausloggen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="gameIsEnded">
              <v-list-item link @click="backToGame">
                <v-list-item-icon>
                  <v-icon>{{mdiUndo}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Zurück zum Spiel</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link v-if="currentGame.settings.liveRank" to="/live-rank-end">
                <v-list-item-icon>
                  <v-icon>{{mdiPoll}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Live-Spielstand</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item link @click="leaveConfirmation = true" v-if="currentGame">
              <v-list-item-icon>
                <v-icon class="error--text">{{mdiLogoutVariant}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="error--text">
                <v-list-item-title>Spiel verlassen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
				</template>
			</v-list>
			<template v-if="currentUser || currentGame">
				<v-divider></v-divider>
				<v-list>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Weitere Infos:</v-list-item-title>
              <v-list-item-subtitle v-if="currentUser.id">
                Kunden-ID: <span class="black--text">{{currentUser.id}}</span>
              </v-list-item-subtitle>
							<v-list-item-subtitle v-if="currentUser">
								Benutzerrolle: <span class="black--text">{{currentUser.roles[0]}}</span>
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="currentGame">
								aktuelles Spiel: <span class="black--text">{{currentGame.name}}</span>
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="groupName">
								Nr./Name Gruppe: <span class="black--text">{{groupName}}</span>
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="mayorName">
								Name Bürgermeister: <span class="black--text">{{mayorName}}</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
			<br>
			<br>
			<br>
		</v-navigation-drawer>

		<template v-if="gameIsStarted">
			<!--		right drawer-->
			<v-navigation-drawer v-if="this.$vuetify.breakpoint.smAndUp" app right permanent clipped id="drawer-right">
				<v-list>
					<v-subheader>Infos</v-subheader>
					<template v-for="(item, index) in [...footerInfos, ...toolbarInfos].reverse()">
						<v-tooltip left>
							<template v-slot:activator="{ on }">
								<v-list-item v-on="on" @click="call(item.onClick)">
									<v-list-item-icon>
										<v-icon>{{ item.icon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ item.title }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-icon>
										<strong>{{ getState(item.id) }}</strong>
									</v-list-item-icon>
								</v-list-item>
							</template>
							<span>{{ item.description }}</span>
						</v-tooltip>
					</template>
					<v-divider></v-divider>
					<div class="pa-2">
						<AddBuildingButton :footer="false"></AddBuildingButton>
					</div>
				</v-list>
			</v-navigation-drawer>

			<!--		footer-->
			<v-footer v-if="this.$vuetify.breakpoint.xsOnly" app inset class="d-flex justify-space-between"
			          elevation="6"
			>
				<template v-for="(item, index) in footerInfos">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-chip color="primary" v-on="on" label outlined default class="elevation-4">
								<v-icon v-if="$vuetify.breakpoint.xsOnly" left>{{item.icon}}</v-icon>
								<strong :id=item.id>{{getState(item.id)}}</strong>
							</v-chip>
						</template>
						<span>{{item.description}}</span>
					</v-tooltip>
				</template>
				<AddBuildingButton :footer="true"></AddBuildingButton>
			</v-footer>
		</template>

		<!--		confirmation dialogs-->
		<ConfirmationDialog :dialog.sync="leaveConfirmation">
			<template v-slot:text>
				Willst du wirklich das Spiel verlassen?
				Dadurch wird der Fortschritt vom aktuellen Spiel komplett zurückgesetzt.
			</template>
			<template v-slot:actions>
				<v-btn color="primary" @click="leaveConfirmation = false">Abbrechen</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="error" text @click="leaveConfirmation = false; leaveGame();">Ja</v-btn>
			</template>
		</ConfirmationDialog>
		<ConfirmationDialog :dialog.sync="endConfirmation" :confirmed="endGame">
			<template v-slot:text>
				Willst du wirklich das Spiel beenden? Du wirst danach auf eine Auswertungsseite geleitet.
			</template>
		</ConfirmationDialog>
	</div>
</template>

<script>
	import {
		mdiLogoutVariant, mdiUndo, mdiDomain, mdiCheckboxMultipleMarked, mdiPuzzle, mdiSchool, mdiPoll, mdiPlayCircle,
		mdiPauseCircle, mdiExitRun, mdiAutorenew, mdiTimerSand, mdiFlagCheckered, mdiDiceMultiple, mdiMenu, mdiAccountGroup,
		mdiGamepad, mdiHome, mdiQrcodeScan
	} from '@mdi/js';
	import AddBuildingButton from "@/components/AddBuildingButton";
	import ConfirmationDialog from "@/components/ConfirmationDialog";
	import {gameDb} from "@/_controller";
	import {countdown, game, auth} from "@/_helpers";
	import {building, faculty} from "@/_model";
	import config from "@/config";
	import {authorize} from "@/mixins";
	import {mapState, mapGetters} from 'vuex';
	import {mapFields} from 'vuex-map-fields';
  import {removeFireProtectionFromLocalStorage} from "../_helpers/qrcode-extra-fire-protection";
  import {removeWinningPointChipsFromLocalStorage} from "@/_helpers/qrcode-winning-point-chip";

	let changes = [];
	export default {
		name: "Navbar",
		components: {ConfirmationDialog, AddBuildingButton},
		mixins: [authorize],
		props: ["leftDrawer"],
		data: () => ({
			mdiLogoutVariant, mdiUndo, mdiMenu, mdiExitRun, mdiHome, mdiQrcodeScan, mdiPoll,
			sites: [
				{title: 'Gebäudeliste', icon: mdiDomain, link: "/buildings"},
				{title: 'Checkliste', icon: mdiCheckboxMultipleMarked, link: "/checklist"},
				{title: 'Erweiterungen auswählen', show: "extensions", icon: mdiPuzzle, link: "/choose-extensions"},
				{title: 'Fakultäten auswählen', show: "faculties", icon: mdiSchool, link: "/choose-faculties"},
				{title: 'Live-Spielstand', show: "liveRank", icon: mdiPoll, link: "/live-rank"},
			],
			controlElements: [
				{show: 'gameIsNotPaused', title: 'Spiel fortsetzen', icon: mdiPlayCircle, onClick: "continueCountdown"},
				{show: 'gameIsPaused', title: 'Spiel pausieren', icon: mdiPauseCircle, onClick: "pauseCountdown"},
			],
			toolbarInfos: [
				{
					id: "round", short: "Runde", title: "Aktuelle Runde", icon: mdiAutorenew,
					description: "Zeigt die aktuelle Runde an.", onClick: null
				},
				{
					id: "humanReadableCountdownTime", short: "Zeit", title: "Countdown", icon: mdiTimerSand,
					description: "Zeit bis zum nächsten Würfeln", onClick: "showCountdownTimeDialog"
				},
			],
			footerInfos: [
				{
					id: "winningPoints", title: "Siegerpunkte", icon: mdiFlagCheckered,
					description: "Eure aktuellen Siegerpunkte (inkl. SP-Chips)"
				},
				{
					id: "countingDice", title: "Zählende Würfel", icon: mdiDiceMultiple,
					description: "Aktuell zählende Würfel"
				},
			],
			sitesBeforeGame: [
				{id: 'home', title: 'Home', icon: mdiHome, link: '/home'},
				{id: 'customers', title: 'Kunden', icon: mdiAccountGroup, link: '/customers'},
				{id: 'games', title: 'Spiele', icon: mdiGamepad, link: '/games'},
			],
			leaveConfirmation: false,
			endConfirmation: false,
			version: config.version,
		}),
		methods: {
			pauseCountdown() {
				countdown.pause();
			},
			continueCountdown() {
				countdown.continue();
			},
			endGame() {
				this.endConfirmation = false;
				game.setIsEnded(true);
				game.setIsStarted(false);
				this.pauseCountdown();
				this.$router.push('/evaluation');
			},
			logout() {
				auth.logout();
				this.$store.commit("setIsAuthenticated", false);
				this.$router.push(config.loginPath);
			},
			leaveGame() {
				game.removeFromLocalStorage();
				building.removeFromLocalStorage();
				faculty.removeFromLocalStorage();
				countdown.removeFromLocalStorage();
				countdown.destroy();
        removeFireProtectionFromLocalStorage();
        removeWinningPointChipsFromLocalStorage();

				// remove all pouch listeners + delete database
				gameDb.instance.deleteRemoteDb().catch(error => {
					this.$store.commit("globalError",
							"Datenbank auf dem Server konnte nicht gelöscht werden: " + error.message);
				}).then(async () => {
					game.removeCurrent();
					await gameDb.instance.destroy();
					await this.$router.push({ path: "/home", query: { forceReload: "true" } });
				});
			},
			backToGame() {
				game.backToGame();
				this.$router.push('/buildings');
			},

			closeDrawerOnMobile() {
				if (this.$refs.leftDrawer.isMobile) this.drawer = false;
			},
			showCountdownTimeDialog() {
				this.$store.commit("showCountdownTimeDialog", true);
			},

			call(function_name) {
				if (function_name) {
					this[function_name]();
				}
			},
			getState(val) {
				return this[val];
			},
			show(item) {
				return item.show ? this.getState(item.show) : true;
			}
		},
		computed: {
			...mapState([
				"countingDice", "isBuildingsView", "gameIsStarted", "gameIsEnded", "gameIsPaused",
				"isAuthenticated", "currentGame", "currentUser", "newBuiltBuilding", "round", "groupName",
				"mayorName",
			]),
			...mapGetters(["humanReadableCountdownTime", "extensions", "facultiesAllowed", "winningPoints"]),
			...mapFields(["showHouses"]),
			liveRank() {
				return this.currentGame ? this.currentGame.settings.liveRank : false;
			},
			faculties() {
				return this.$store.getters.withExtension("glaubeUndBildung") && this.facultiesAllowed;
			},
			gameIsNotPaused() {
				return !this.gameIsPaused;
			},
			drawer: {
				get() {
					return this.leftDrawer;
				},
				set(value) {
					this.$emit("update:leftDrawer", value);
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	#drawer {
		width: 270px;
	}

	#drawer-app-icon {
		margin-right: .5em;
	}

	.v-list-item {
		cursor: pointer;
	}

	.v-list-bottom {
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: white;
	}

	.toolbar-info {
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}

	.v-btn {
		padding: 0 1rem;
	}

	.v-footer .v-chip {
		height: 36px;
	}

	.header-item {
		/*margin: 0 1rem;*/
		width: 100px;
	}

	.adjust-action-margin {
		margin-right: 18px !important;
	}
  .sub-menu {
    .v-subheader:only-child {
      display: none;
    }
  }
</style>
