import {ls} from "./localStorage"

const validFireProtectionIds = [
  "45e3480c-e966-4003-b522-89ebe47c36bf",
  "968da497-28ec-431e-9cd2-a13f27d1cc19",
  "62e8c2a1-415e-4554-9b45-ba89d4bcb0e2"
]

const prefix = "fireProtection_";

export const isValidQrForFireProtection = (value) => {
  return validFireProtectionIds.includes(value);
};

export const getExtraFireProtection = () => {
  return ls.getInt(prefix + "points") || 0;
};

export const saveExtraFireProtection = (number) => {
  ls.setItem(prefix + "points", number);
};

const getUsedFireProtectionIds = () => {
  return ls.getObject(prefix + "usedIds") || [];
};

export const addUsedFireProtectionId = (value) => {
  const usedFireProtectionIds = getUsedFireProtectionIds();
  usedFireProtectionIds.push(value);
  ls.setObject(prefix + "usedIds", usedFireProtectionIds);
};

export const fireProtectionIdAlreadyUsed = (id) => {
  const usedFireProtectionIds = getUsedFireProtectionIds();
  return usedFireProtectionIds.includes(id);
};

export const removeFireProtectionFromLocalStorage = () => {
  ls.removeAll(prefix);
}
