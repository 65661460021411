<template>
  <div>
    <p>Liste aller gespielten Spiele mit Anzahl der Spielenden:</p>

    <v-simple-table fixed-header height="70vh" v-if="gameObjects">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Datum</th>
          <th class="text-left">Kunde</th>
          <th class="text-left">Spiel</th>
          <th class="text-left">Bürgermeister</th>
          <th class="text-left">Gruppe</th>
          <th class="text-left"># Mitspieler</th>
          <th class="text-left">DB Name</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, index) in gameObjects" :key="obj.dbName">
            <td>{{ obj.timestamp ? new Date(obj.timestamp).toLocaleDateString() : "" }}</td>
            <td>{{ obj.customerName }}</td>
            <td>{{ obj.gameName }}</td>
            <td>{{ obj.mayorName }}</td>
            <td>{{ obj.groupName }}</td>
            <td><strong>{{ obj.playerCount }}</strong></td>
            <td>{{ obj.dbName }}</td>
            <td>
              <v-btn icon color="error" @click="dbToDelete = obj.dbName; deleteConfirmation = true">
                <v-icon>{{mdiDelete}}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><strong>Gesamt:</strong></td>
            <td><strong>{{ playerCountsTotal }}</strong></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <SmallDialog :dialog.sync="deleteConfirmation">
      <template v-slot:header>
        Löschen?
      </template>
      <template v-slot:text>
        <p>Willst du wirklich die Datenbank '<em>{{dbToDelete}}</em>' löschen?</p>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" @click="deleteConfirmation = false">
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="deleteConfirmation = false; deleteGame();">
          Ja
        </v-btn>
      </template>
    </SmallDialog>
  </div>
</template>

<script>
import {mdiDelete} from '@mdi/js';
import {backend, game} from "@/_helpers";
import SmallDialog from "../components/SmallDialog";

export default {
  name: "AdminInfos",
  components: {SmallDialog},
  data: () => ({
    mdiDelete,
    gameObjects: null,
    deleteConfirmation: false,
    dbToDelete: ""
  }),
  created() {
    this.$store.commit('setGlobalLoading', true);
    backend.post('getPlayerCounts').then((playerCountObjs) => {
      this.gameObjects = playerCountObjs;
    }).catch((error) => {
      this.$store.commit('globalError', error.message);
    }).finally(() => {
      this.$store.commit('setGlobalLoading', false);
    });
  },
  computed: {
    playerCountsTotal() {
      return this.gameObjects.reduce((sum, obj) => {
        if (obj.playerCount) {
          return sum + parseInt(obj.playerCount);
        }
        return sum;
      }, 0);
    }
  },
  methods: {
    deleteGame() {
      this.$store.commit("setGlobalLoading", true);
      game.deleteDb(this.dbToDelete).then(res => {
        this.gameObjects.splice(this.gameObjects.findIndex(g => g.dbName === this.dbToDelete), 1);
        this.$store.commit("globalSuccess", "Spiel '" + this.dbToDelete + "' vom Server gelöscht!")
      }).catch(err => {
        this.$store.commit("globalError", err);
      }).finally(() => {
        this.$store.commit("setGlobalLoading", false);
        this.dbToDelete = "";
      });
    }
  }
}
</script>

<style scoped>

</style>