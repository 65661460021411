<template>
	<div>
		<p>Wähle zuerst die Erweiterungen, mit denen ihr spielt:</p>
		<v-form v-if="extensions">
			<ExtensionChooser :extensions.sync="extensions"></ExtensionChooser>
			<br>
      <div class="d-flex justify-end">
        <v-btn @click="nextPage" class="primary">
          Weiter
          <v-icon right>
            {{mdiChevronRight}}
          </v-icon>
        </v-btn>
      </div>
		</v-form>
	</div>
</template>

<script>
	import ExtensionChooser from "@/components/ExtensionChooser";
	import {building, extension} from "@/_model";
  import {mdiChevronRight} from "@mdi/js";

	export default {
		name: "Extensions",
		components: {ExtensionChooser},
    data: () => ({
      mdiChevronRight
    }),
		computed: {
			extensions: {
				get() {
					return this.$store.getters.extensions;
				},
				set(value) {
					extension.setExtensions(value);
				}
			}
		},
		methods: {
			nextPage() {
				building.initBuildings();
				this.$router.push("/checklist-before-game");
			}
		}
	}
</script>

<style scoped>

</style>