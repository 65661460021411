import {ls} from "@/_helpers";
import config from "@/config";
import store from "@/store";

export const faculty = {
	instances: [],
	init() {
		faculty.instances.push(faculty.create("architektur", "Architektur"));
		faculty.instances.push(faculty.create("holz", "Holz", "Holz"));
		faculty.instances.push(faculty.create("gestein", "Gestein", "Stein"));
		faculty.instances.push(faculty.create("metall", "Metall", "Eisen"));
		faculty.instances.push(faculty.create("landwirtschaft", "Landwirtschaft", "Stroh"));
	},
	create(id, name, resource = null, enabled = false) {
		return {id, name, resource, enabled};
	},
	getChosen() {
		return ls.getObject(config.facultyPrefix + "chosen") || faculty.instances;
	},
	setChosen(value) {
		store.commit("setFaculties", value);
		ls.setObject(config.facultyPrefix + "chosen", value);
	},
	getByResource(resource) {
		let item = faculty.getChosen().find(f => f.resource === resource);
		return item.enabled ? item : null;
	},
	removeFromLocalStorage() {
		ls.removeAll(config.facultyPrefix);
	},
	with(facultyId) {
		return this.getChosen().find(f => f.id === facultyId).enabled;
	}
};
faculty.init();