<template>
	<v-overlay :value="globalLoading" z-index="6">
		<v-progress-circular color="primary" indeterminate size="40"></v-progress-circular>
	</v-overlay>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: "LoadingOverlay",
		computed: {
			...mapState(["globalLoading"]),
		}
	}
</script>

<style scoped>

</style>