import axios from "axios";
import config from "@/config";

export const backend = {
	async post(apiRoute, data = {}) {
		let url = config.apiUrl + apiRoute;
		let headers = getHeaders();
		return await axios.post(url, data, headers).then(response => {
			return Promise.resolve(response.data);
		}).catch(error => {
			error = getError(error);
			return Promise.reject(error);
		});
	}
};

function getError(axiosErrorObj) {
	let status = null;
	let message = null;
	if (axiosErrorObj.response) {
		message = axiosErrorObj.response.data;
		if (message.body) {
			message = message.body.error + ": " + message.body.reason;
		} else if (message.message) {
			message = message.message
		}
		status = axiosErrorObj.response.status;
	} else {
		message = (axiosErrorObj.message === "Network Error") ? "Server nicht erreichbar" : axiosErrorObj.message;
		status = 503;
	}
	return {status, message};
}

function getHeaders() {
	return {
		headers: {
			"X-Security-Token": config.securityToken
		}
	};
}