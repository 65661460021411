<template>
	<div>
		<v-alert type="info" :icon="mdiDiceMultiple">
			Immer mit allen {{maxCountingDice}} Würfeln würfeln!
		</v-alert>
		<v-alert type="warning" v-if="countingDice < 3" :icon="mdiAlertCircle">
			Rohstoffzuordnung:<br>
			<strong>{{countingDice}} der 3</strong> gewürfelten Würfel aussuchen
		</v-alert>
		<div class="d-flex flex-column align-center">
			<div v-for="(item, index) in dice" class="d-flex align-center">
				<v-icon x-large>{{item.icon}}</v-icon>
				<v-icon>{{mdiArrowRight}}</v-icon>
				<div class="d-flex flex-column align-center">
					<v-img :src="getResourcePicture(item.resource)" width="60" class="resource-image">
					</v-img>
					<div class="resource-caption">
						{{item.resource}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mdiDiceMultiple, mdiDice1, mdiDice2, mdiDice3, mdiDice4, mdiArrowRight, mdiAlertCircle,
		mdiInformation
	} from '@mdi/js';
	import {helper} from "@/_helpers";
	import config from "@/config";
	import {mapState} from 'vuex';

	export default {
		name: "HintResources",
		data: () => ({
			mdiDiceMultiple, mdiArrowRight, mdiAlertCircle, mdiInformation,
			dice: [
				{icon: mdiDice1, resource: 'Holz', color: 'brown'},
				{icon: mdiDice2, resource: 'Stein', color: 'grey'},
				{icon: mdiDice3, resource: 'Eisen', color: 'blue-grey'},
				{icon: mdiDice4, resource: 'Stroh', color: 'orange'},
			],
			maxCountingDice: config.maxCountingDice
		}),
		methods: {
			getResourcePicture(name) {
				return helper.getResourcePicture(name);
			}
		},
		computed: {
			...mapState(["countingDice"]),
		}
	}
</script>

<style scoped>
	.resource-image-wrapper {
		display: inline-block;
	}

	.resource-image {
		display: inline-block;
		top: 1rem;
		left: 0.5rem;
		margin-right: 1rem;
	}

	.resource-caption {
		margin-top: 1rem;
	}
</style>