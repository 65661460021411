<template>
	<div>
		<p>Fakultäten hinzufügen/entfernen:</p>
		<p>
			Kapazität: {{currentCapacity}}/{{maxCapacity}}<br>
			<span class="grey--text font-italic"
				  v-if="currentCapacity === maxCapacity && !builtBuildingNames.includes('Universität')"
			>
				(Universität oder Schule bauen, um weitere Fakultäten hinzuzufügen)
			</span>
		</p>
		<v-list dense>
			<template v-for="(item, index) in faculties">
				<v-list-item :disabled="isDisabled(item)">
					<v-list-item-avatar>
						<v-img :src="'img/faculties/'+item.id+'.png'" max-width="60"></v-img>
					</v-list-item-avatar>
					<v-list-item-content class="switches">
						<v-switch :label="item.name" v-model="item.enabled" color="primary" hide-details
						          @change="faculties = faculties; showSnackbar(item.enabled)" class="switch ma-2"
						          :disabled="isDisabled(item)"
						>
						</v-switch>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
		<br>
    <v-btn to="/buildings" text color="primary">
      <v-icon left>
        {{mdiChevronLeft}}
      </v-icon>
      Zurück
    </v-btn>
		<v-snackbar v-model="snackbar" :color="itemEnabled ? 'success' : 'info'" timeout="3000">
			Fakultät {{itemEnabled ? 'hinzugefügt' : 'entfernt' }}
			<template v-slot:action>
				<v-btn icon @click="snackbar = false">
					<v-icon>
						{{mdiClose}}
					</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	import {mdiClose, mdiChevronLeft} from "@mdi/js";
	import {faculty} from "@/_model";
	import config from "@/config";
	import {mapGetters} from 'vuex';

	export default {
		name: "ChooseFaculties",
		data: () => ({
      mdiChevronLeft,
			schoolFacultyCapacity: config.schoolFacultyCapacity,
			snackbar: false,
			itemEnabled: true,
			mdiClose
		}),
		computed: {
			...mapGetters(["builtBuildingNames", "chosenFaculties"]),
			faculties: {
				get() {
					return this.$store.state.faculties;
				},
				set(value) {
					faculty.setChosen(value);
				}
			},
			maxCapacity() {
        if (this.builtBuildingNames.includes("Universität")) {
          return this.faculties.length;
        }
        const numberOfSchools = this.builtBuildingNames.filter(n => n === "Schule");
        if (numberOfSchools.length > 0) {
          const capacity = numberOfSchools.length * this.schoolFacultyCapacity;
          if (capacity > this.faculties.length) {
            return this.faculties.length;
          }
          return capacity;
        }
        return 0;
			},
			currentCapacity() {
				return this.chosenFaculties.length;
			},
		},
		methods: {
			isDisabled(faculty) {
				return this.currentCapacity >= this.maxCapacity && !faculty.enabled;
			},
			showSnackbar(enabled) {
				this.itemEnabled = enabled;
				this.snackbar = true;
			}
		},
    created() {
      faculty.setChosen(faculty.getChosen());
    }
  }
</script>

<style scoped>
	.v-list-item {
		padding: 0 !important;
	}

	.v-list-item--disabled .v-image {
		filter: grayscale(100%);
	}

	.v-avatar {
		margin-right: 0 !important;
	}

	.switches {
		padding-left: 0.5rem !important;
	}

	.switch {
		margin: 0;
		padding: 0;
	}
</style>
