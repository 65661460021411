<template>
	<div>
		<template v-if="hasRole('Admin')">
			<v-select v-model="customerIdVal" :items="customers" outlined :prepend-icon="mdiAccount" :rules="rules"
			          label="Kunde wählen" item-text="name" item-value="id" required
			>
			</v-select>
			<br>
		</template>

		<v-text-field outlined label="Spielname" v-model="game.name" :prepend-icon="mdiGamepad" :rules="rules">
		</v-text-field>
		<br>
		<v-text-field outlined label="Rundenzeit (Sekunden)" type="number" v-model="roundTime" :rules="rules"
		              :prepend-icon="mdiAlarm" min="5"
		>
			<template v-slot:append>
				<v-icon color="primary" v-if="parseInt(roundTime) !== initialRoundTime"
				        @click="roundTime = initialRoundTime"
				>{{mdiReload}}
				</v-icon>
			</template>
		</v-text-field>
		<v-text-field outlined label="+ Zeit pro Erweiterung (Sekunden)" type="number" :prepend-icon="mdiAlarmPlus"
		              v-model="extraRoundTime" :rules="rules" :hint="'Gesamtzeit: '+fullTime" persistent-hint min="0"
		>
			<template v-slot:append>
				<v-icon color="primary" v-if="parseInt(extraRoundTime) !== initialExtraRoundTime"
				        @click="extraRoundTime = initialExtraRoundTime"
				>{{mdiReload}}
				</v-icon>
			</template>
		</v-text-field>
		<br>
		<v-switch class="liveRank" v-model="game.settings.liveRank" label="Erlaube Bürgermeister/innen Live-Spielstand-Ansicht"
		          hint="Live-Spielstand = Bürgermeister/innen sehen Spielstände der anderen Teams (Funktion aktuell deaktiviert)" persistent-hint
              disabled
		>
		</v-switch>
		<br>
		<div class="grey--text body-2">Erweiterungen:</div>
		<div id="switches">
			<ExtensionChooser :extensions.sync="extensions"></ExtensionChooser>
		</div>
	</div>
</template>

<script>
	import {mdiAlarm, mdiAlarmPlus, mdiGamepad, mdiReload, mdiAccount} from '@mdi/js';
	import ExtensionChooser from "@/components/ExtensionChooser";
	import {countdown} from "@/_helpers";
	import {extension} from "@/_model";
	import config from "@/config";
	import {authorize} from "@/mixins";
	import {customer} from "../_model";

	export default {
		name: "EditGame",
		components: {ExtensionChooser},
		mixins: [authorize],
		props: ["game", "customerId"],
		data: () => ({
			mdiAlarm, mdiAlarmPlus, mdiGamepad, mdiReload, mdiAccount,
			initialExtraRoundTime: config.extraRoundTime,
			initialRoundTime: config.roundTime,
			customers: [],
			rules: [
				v => (!!v || v === 0) || 'Feld ist erforderlich!',
			],
		}),
		created() {
			if (this.hasRole("Admin")) {
				customer.getAll().then(customers => {
					this.customers = customers;
				});
			}
		},
		computed: {
			customerIdVal: {
				get() {
					return this.customerId;
				},
				set(value) {
					this.$emit("update:customerId", value);
				}
			},
			extraRoundTime: {
				get() {
					return parseInt(this.game.settings.extraRoundTime) || 0;
				},
				set(value) {
					this.game.settings.extraRoundTime = parseInt(value) || 0;
				}
			},
			roundTime: {
				get() {
					return parseInt(this.game.settings.roundTime) || 0;
				},
				set(value) {
					this.game.settings.roundTime = value;
				}
			},
			extensions: {
				get() {
					return this.game.settings.extensions;
				},
				set(value) {
					this.game.settings.extensions = value;
					return this.$emit("update:game", this.game);
				}
			},
			fullTime() {
				let extensions = extension.getEnabled(this.extensions);
				return countdown.getHumanReadableTime(
						countdown.calcRoundTime(this.roundTime, extensions, this.extraRoundTime)
				);
			}
		},
	}
</script>

<style scoped>
	.liveRank {
		margin-top: 0 !important;
	}

	#switches *:first-child {
		margin-top: 0.25rem;
	}
</style>
