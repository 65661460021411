export default {
    dev: false,
    apiUrl: "https://cantopia.fluhrer.games/api/", // cantopia backend server
    securityToken: "@@<kNz*Jy862j^*&_<-M", // cantopia backend server security token
    couch: {
        url: "https://cantopia.fluhrer.games/couchdb/", // couchdb instance
        username: "admin",
        password: "eSA54AYyi7jCDw29HGD",
    },
    version: "2.3.9"
}
