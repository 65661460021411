import {gameDb} from "@/_controller";
import {auth, backend} from "@/_helpers";
import config from "@/config";
import store from "@/store";

export const liveRank = {
	connections: null,
	interval: null,
	async getEvalDbNames(gameId) {
		let data = {
			gameId,
			customerId: auth.getCurrentUser().id
		};
		return backend.post("getEvalDbNames", data);
	},
	totalWps(game) {
    return parseInt(game.wpFromBuildings) +
      (game.numberOfWpChips ? parseInt(game.numberOfWpChips) : 0) +
      (game.hasMostHouses ? config.mostHousesPoints : 0) +
      (game.hasFirstCastle ? config.firstCastlePoints : 0) +
      (game.hasMostPublicBuildings ? config.mostPublicBuildingsPoints : 0);
	},
	async leave() {
		clearInterval(liveRank.interval);
		let promises = [];
		let gameDbName = (gameDb.instance) ? gameDb.instance.dbName : null;
		for (let connection of this.connections) {
			// cancel all changes
			for (let change of connection.changes) {
				change.cancel();
			}
			// cancel all replications and Events
			connection.replication.cancel();

			// remove indexedDB if it is not the current game
			if (connection.dbName !== gameDbName) {
				promises.push(connection.destroy());
			}
		}
		return Promise.all(promises);
	}
};
