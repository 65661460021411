import {extension, building} from '@/_model';
import localConfig from "../localConfig";

// game
let extensions = [
	extension.create('naturkatastrophen', 'Naturkatastrophen'),
	extension.create('glaubeUndBildung', 'Glaube & Bildung'),
	extension.create('räuberUndGendarm', 'Räuber & Gendarm'),
	extension.create('zufriedenheit', 'Zufriedenheit'),
];
let grundspiel = extension.create('grundspiel', 'Grundspiel', true);

export default {
	// general
	version: localConfig.version,
	dev: localConfig.dev,
	apiUrl: localConfig.apiUrl,
	loginPath: "/",

	// db stuff
	dbPrefix: "game_",
	couchUrl: localConfig.couch.url,
	securityToken: localConfig.securityToken,
	dontReplicateDocs: ["countdownTime"],

	// game
	roundTime: 180,
	extraRoundTime: 15,
	countingDiceArray: [
		{round: 1, dice: 3},
		{round: 12, dice: 2},
		{round: 24, dice: 1},
	],
	firstCastlePoints: 3,
	mostHousesPoints: 3,
  mostPublicBuildingsPoints: 3,
	maxCountingDice: 3,
	extensions,
	grundspiel,
	allExtensions: [grundspiel, ...extensions],
	fireProtectCount: 15,
	schoolFacultyCapacity: 2,
	attackInterval: 3,

	// others
	smallDialogWidth: 300,
	addToHomeScreenMax: 2,

	// localStorage
	lsPrefix: "cantopia_",
	authPrefix: "auth_",
	evalPrefix: "eval_",
	gamePrefix: "game_",
	facultyPrefix: "faculty_",
	countdownPrefix: "countdown_",
	buildingPrefix: "building_",
};
