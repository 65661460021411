<template>
	<div>
		<p>
			Hier kannst du den aktuellen Spielstand vom Spiel sehen. Bei einer aktiven Internetverbindung aktualisiert sich
			diese Seite automatisch.
		</p>
		<LiveRankComponent :gameId="gameId"></LiveRankComponent>
		<br>
	</div>
</template>

<script>
	import LiveRankComponent from "@/components/LiveRank";
  import {mdiChevronLeft} from "@mdi/js";

	export default {
		name: "GameLiveRank",
		components: {LiveRankComponent},
    data: () => ({
      mdiChevronLeft
    }),
		computed: {
			gameId() {
				return this.$route.params.gameId;
			}
		}
	}
</script>

<style scoped>

</style>