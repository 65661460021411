import Vuex from 'vuex';
import Vue from 'vue';
import {getField, updateField} from 'vuex-map-fields';
import {countdown} from "@/_helpers";
import {building, extension} from "@/_model";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		countdownTime: 0,
		countingDice: 3,
		globalLoading: false,
		diceOverlay: false,
		showHouses: true,
		gameIsStarted: false,
		gameIsEnded: false,
		gameIsPaused: false,
		playingGame: false,
		isBuildingsView: false,
		smallCardPadding: false,
		isAuthenticated: false,
		showUpdateCacheMessage: false,
		currentGame: null,
		currentUser: null,
		globalError: "",
		globalSuccess: "",
		round: 1,
		gameDbInitialized: false,
		groupName: null,
		mayorName: null,
		builtBuildings: [],
		missedRounds: 0,
		showMissedRoundsDialog: false,
		showBuildCastleDialog: false,
		showCountdownTimeDialog: false,
		faculties: [],
		currentAvailableBuildings: [],
		// pouch changes:
		newBuiltBuilding: null,
		availableGames: null,
    	extraFireProtection: 0,
		winningPointChips: 0,
	},
	mutations: {
		updateField,
		countdownTime(state, value) {
			state.countdownTime = value;
		},
		setCountingDice(state, value) {
			state.countingDice = value;
		},
		setGlobalLoading(state, value) {
			state.globalLoading = value;
		},
		diceOverlay(state, value) {
			state.diceOverlay = value;
		},
		showHouses(state, value) {
			state.showHouses = value;
		},
		setGameIsStarted(state, value) {
			state.gameIsStarted = value;
		},
		setGameIsEnded(state, value) {
			state.gameIsEnded = value;
		},
		setGameIsPaused(state, value) {
			state.gameIsPaused = value
		},
		setPlayingGame(state, value) {
			state.playingGame = value;
		},
		setIsBuildingsView(state, value) {
			state.isBuildingsView = value;
		},
		setIsAuthenticated(state, value) {
			state.isAuthenticated = value;
		},
		showUpdateCacheMessage(state, value) {
			state.showUpdateCacheMessage = value;
		},
		setCurrentUser(state, value) {
			state.currentUser = value;
		},
		setCurrentGame(state, value) {
			state.currentGame = value;
		},
		setSmallCardPadding(state, value) {
			state.smallCardPadding = value;
		},
		globalError(state, value) {
			state.globalError = value;
		},
		globalSuccess(state, value) {
			state.globalError = "";
			state.globalSuccess = value;
		},
		setRound(state, value) {
			state.round = value;
		},
		setGameDbInitialized(state, value) {
			state.gameDbInitialized = value;
		},
		setGroupName(state, value) {
			state.groupName = value;
		},
		setMayorName(state, value) {
			state.mayorName = value;
		},
		setBuiltBuildings(state, value) {
			state.builtBuildings = value;
		},
		setMissedRounds(state, value) {
			state.missedRounds = value;
		},
		showMissedRoundsDialog(state, value) {
			state.showMissedRoundsDialog = value;
		},
		showBuildCastleDialog(state, value) {
			state.showBuildCastleDialog = value;
		},
		showCountdownTimeDialog(state, value) {
			state.showCountdownTimeDialog = value;
		},
		setFaculties(state, value) {
			state.faculties = value;
		},
		currentAvailableBuildings(state, value) {
			state.currentAvailableBuildings = value;
		},
		setAvailableGames(state, value) {
			state.availableGames = value;
		},
		setExtraFireProtection(state, value) {
		  	state.extraFireProtection = value;
		},
		setWinningPointChips(state, value) {
			state.winningPointChips = value;
		},
		// pouch changes:
		setNewBuiltBuilding(state, value) {
			state.newBuiltBuilding = value;
		}
	},
	getters: {
		getField,
		// building getters
		builtBuildingNames: (state, getters) => {
			return state.builtBuildings.map(b => b.name);
		},
		housesCount: (state, getters) => {
			let houses = getters.builtBuildingNames.filter(b => b === "Wohnhaus");
			return houses.length || 0;
		},
		otherBuildingsCount: (state, getters) => {
			return state.builtBuildings.length - getters.publicBuildingsCount - getters.housesCount;
		},
		publicBuildingsCount: (state, getters) => {
			return building.getPublicBuildings(state.builtBuildings).length
		},
		puffer: (state, getters) => {
			return getters.housesCount - (getters.otherBuildingsCount + getters.publicBuildingsCount);
		},
		availableBuildings: (state, getters) => {
			let withNaturkatastrophen = getters.withExtension("naturkatastrophen");
			let withGlaubeUndBilding = getters.withExtension("glaubeUndBildung");
			let withRäuberUndGendarm = getters.withExtension("räuberUndGendarm");
			let withZufriedenheit = getters.withExtension("zufriedenheit");
			return building.initBuildings(
					withNaturkatastrophen, withGlaubeUndBilding, withRäuberUndGendarm, withZufriedenheit
			);
		},
		winningPointsBuildings: (state, getters) => {
			return building.getWinningPoints(state.builtBuildings);
		},
		winningPoints: (state, getters) => {
			return getters.winningPointsBuildings + state.winningPointChips;
		},

		// faculty getters
		facultiesAllowed: (state, getters) => {
			return getters.builtBuildingNames.includes("Schule") || getters.builtBuildingNames.includes("Universität");
		},
		chosenFaculties: (state, getters) => {
			return state.faculties.filter(f => f.enabled);
		},

		// extension getters
		extensions: (state, getters) => {
			return state.currentGame ? state.currentGame.settings.extensions : [];
		},
		withExtension: (state, getters) => (extensionId) => {
			return extension.with(getters.extensions, extensionId);
		},
    withAllExtensions: (state, getters) => (extensionIds) => {
      for (const extensionId of extensionIds) {
        if (!extension.with(getters.extensions, extensionId)) {
          return false;
        }
      }
      return true;
    },
		withAnyExtensions: (state, getters) => (extensionIds) => {
			for (const extensionId of extensionIds) {
				if (extension.with(getters.extensions, extensionId)) {
					return true;
				}
			}
			return false;
		},

		// countdown getters
		humanReadableCountdownTime: (state, getters) => {
			return countdown.getHumanReadableTime(state.countdownTime);
		},

		// game getters
		liveRank: (state, getters) => {
			return state.currentGame.settings.liveRank
		}
	}
});
