<template>
	<div>
		<SmallDialog :dialog.sync="dialog">
			<template v-slot:header>
				Überfall-Chip
			</template>
			<template v-slot:text>
				{{attackInterval}} Runden vorbei.<br>
				Zeit den Siedlern einen weiteren <strong>Überfall-Chip</strong> zu übergeben.
			</template>
		</SmallDialog>
		<SmallDialog :dialog.sync="missedDialog">
			<template v-slot:header>
				Überfall-Chip
			</template>
			<template v-slot:text>
				Während du weg warst, musstest du den Siedlern
				<strong>
					{{missedAttackChips}}
					<span v-if="missedAttackChips === 1">weiteren</span><span v-else>weitere</span>
					Überfall-Chip<span v-if="missedAttackChips !== 1">s</span>
				</strong>
				übergeben.<br>
				Hole dies nach, falls noch nicht geschehen.
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import SmallDialog from "@/components/SmallDialog";
	import {ls, countdown} from "@/_helpers";
	import config from "@/config";
	import {mapState, mapGetters} from "vuex";
	import {attackChip} from "@/_controller";

	export default {
		name: "AttackChipDialog",
		components: {SmallDialog},
		data: () => ({
			dialog: false,
			missedDialog: false,
			missedAttackChips: 1,
			attackInterval: config.attackInterval
		}),
		created() {
			this.missedAttackChips = attackChip.getMissedAttackChips();
			if (!config.dev && this.missedAttackChips > 0) {
				this.missedDialog = true;
			}
		},
		computed: {
			...mapState(["round", "builtBuildings"]),
			...mapGetters(["builtBuildingNames"]),
			roundOfFirstWache() {
				return attackChip.getRoundOfFirstWache(this.builtBuildings);
			},
		},
		watch: {
			round(newRound) {
				// show dialog if Wache or Gendarmerie is built and it is %3 rounds from firstWache-Round
				if (attackChip.checkAttackChipInterval(newRound)){
					attackChip.setLastAttackChipRound(newRound);
					if (!config.dev) {
						this.dialog = true;
					}
				}
			}
		}
	}
</script>

<style scoped>

</style>