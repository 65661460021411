<template>
	<div>
		<v-dialog :value="showDialog" scrollable max-width="500" @click:outside="close">
			<v-card v-for="(hint, index) in hints" :key="hint.name" v-if="hint.isCurrent">
				<CardTitleClose :title="hint.caption" :close="close"></CardTitleClose>

				<v-card-text id="card-text">
          <hintNaturkatastrophen v-if="hint.name === 'hintNaturkatastrophen'"></hintNaturkatastrophen>
          <HintResources v-if="hint.name === 'hintResources'"></HintResources>
          <hintProduction v-if="hint.name === 'hintProduction'"></hintProduction>
					<hintZufriedenheit v-if="hint.name === 'hintZufriedenheit'"></hintZufriedenheit>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn text color="primary" @click="prev" v-if="!isFirst">
            <v-icon right>
              {{mdiChevronLeft}}
            </v-icon>
						Zurück
					</v-btn>
					<v-btn text v-if="isFirst" icon disabled>
						<v-icon></v-icon>
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn color="primary" @click="next" v-if="!isLast">
            Weiter
            <v-icon right>
              {{mdiChevronRight}}
            </v-icon>
					</v-btn>
					<v-btn color="error" @click="close" v-if="isLast">
						Schließen
            <v-icon right>
              {{mdiClose}}
            </v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import {mdiClose, mdiChevronRight, mdiChevronLeft} from "@mdi/js";
	import {hints} from "@/_model";

	import HintResources from "@/components/HintResources";
	import hintProduction from "@/components/HintProduction";
	import hintNaturkatastrophen from "@/components/HintNaturkatastrophen";
	import hintZufriedenheit from "@/components/HintZufriedenheit";
	import CardTitleClose from "@/components/CardTitleClose";

	export default {
		name: "Hints",
		components: {CardTitleClose, HintResources, hintProduction, hintNaturkatastrophen, hintZufriedenheit},
		data: () => ({
			mdiClose, mdiChevronRight, mdiChevronLeft,
			hints: hints.instances,
			showDialog: false,
		}),
		created() {
			this.$store.commit('setGlobalLoading', true);
			hints.initializeShowValues();
			hints.initializeCurrentHint();
			this.showDialog = true;
			this.$store.commit('setGlobalLoading', false);
		},
		computed: {
			isFirst() {
				return hints.isFirst();
			},
			isLast() {
				return hints.isLast();
			},
		},
		methods: {
			next() {
				hints.next();
			},
			prev() {
				hints.prev();
			},
			close() {
				hints.close();
			},
		}
	}
</script>

<style scoped>
	#card-text {
		height: 63vh;
		padding-top: 12px;
	}
</style>
