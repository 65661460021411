<template>
	<div>
		<p>
			Hier kannst du den aktuellen Spielstand sehen. Bei einer aktiven Internetverbindung aktualisiert sich diese Seite
			automatisch.
		</p>
		<LiveRankComponent :gameDbName="gameDbName"></LiveRankComponent>
	</div>
</template>

<script>
	import LiveRankComponent from "@/components/LiveRank";
	import {gameDb} from "@/_controller";

	export default {
		name: "LiveRankEnd",
		components: {LiveRankComponent},
		computed: {
			gameDbName() {
				return gameDb.instance.dbName;
			}
		}
	}
</script>

<style scoped>

</style>
