<template>
	<div>
		<v-alert type="info" :icon="mdiInformationOutline">
			Mache einen Screenshot von den Informationen unten und schicke es deinem Spielleiter.
		</v-alert>
    <v-list dense>
      <v-subheader>SPs von <strong style="display: inline">{{ groupName }}</strong> ({{ mayorName }})</v-subheader>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiHomeCity }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle></v-list-item-subtitle>
          <v-list-item-title>Anzahl SP auf Gebäude</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>{{ wpFromBuildings }}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiNumeric1CircleOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anzahl SP-Chips</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>{{ numberOfWpChips }}</v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiCastle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Extra SP 1. Schloss?</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>{{ firstCastlePoints }}</v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiFlagCheckered }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><strong>Siegerpunkte gesamt</strong></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <template v-if="loading">
            <v-progress-circular
                :size="18"
                indeterminate
                color="grey"
            ></v-progress-circular>
            </template>
          <strong v-else>{{ totalWps }}</strong>
        </v-list-item-action>
      </v-list-item>
      <v-list-item three-line class="extra-wp-hint">
        <v-list-item-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Extra-SP für meiste Wohnhäuser und meiste öff. Gebäude werden nach dem Spiel vom Spielleiter ausgerechnet.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-subheader>Statistik:</v-subheader>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiHomeGroup }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anzahl Wohnhäuser</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ builtHousesCount }}
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiCastle }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anzahl Schlösser</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ builtCastlesCount }}
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiTownHall }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anzahl öffentliche Gebäude</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ publicBuildingsCount }}
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiFactory }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anzahl Produktionsgebäude</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ productionBuildingsCount }}
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ mdiHomeGroup }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><strong>Gebäude gesamt</strong></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <strong>{{ builtBuildingsCount }}</strong>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <template v-if="currentGame.settings.liveRank">
      <br>
      <div class="d-flex justify-end">
        <v-btn to="/live-rank-end" color="primary">
          <v-icon left>{{ mdiPoll }}</v-icon>
          zum Live-Spielstand
        </v-btn>
      </div>
    </template>
	</div>
</template>

<script>
	import {
		mdiAccountTie, mdiAccountDetails, mdiDomain, mdiHomeGroup, mdiCastle, mdiNumeric1CircleOutline,
		mdiInformationOutline, mdiFlagCheckered, mdiHomeCity, mdiTownHall, mdiFactory, mdiPoll
	} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import {evaluation, gameDb} from "@/_controller";
	import config from "@/config";
  import {mapState} from 'vuex';
  import {building} from "@/_model";

	export default {
		name: "End",
		components: {SmallDialog},
		data: () => ({
			mdiAccountTie, mdiAccountDetails, mdiDomain, mdiHomeGroup, mdiCastle, mdiNumeric1CircleOutline,
			mdiInformationOutline, mdiFlagCheckered, mdiHomeCity, mdiTownHall, mdiFactory, mdiPoll,
			mayorName: "",
			groupName: "",

			builtBuildingsCount: 0,
			builtHousesCount: 0,
			builtCastlesCount: 0,
			hasFirstCastle: false,
			numberOfWpChips: 0,
      wpFromBuildings: 0,
      publicBuildingsCount: 0,
      productionBuildingsCount: 0,
      loading: false,

			smallDialogWidth: config.smallDialogWidth,
		}),
    computed: {
      ...mapState(['currentGame']),
      totalWps() {
        return this.wpFromBuildings + this.numberOfWpChips + (this.hasFirstCastle ? parseInt(config.firstCastlePoints) : 0);
      },
      firstCastlePoints() {
        return this.hasFirstCastle ? config.firstCastlePoints : 0
      },
    },
		created() {
			this.writeValues();
		},
		methods: {
			async writeValues() {
        this.loading = true;
				this.mayorName = await gameDb.instance.getValue("mayorName");
				this.groupName = await gameDb.instance.getValue("groupName");

				this.builtHousesCount = parseInt(await evaluation.getValue("builtHousesCount"));
				this.builtCastlesCount = parseInt(await evaluation.getValue("builtCastlesCount"));
        let builtBuildings = await evaluation.getBuiltBuildings();
				this.builtBuildingsCount = builtBuildings.length;
        this.publicBuildingsCount = building.getPublicBuildings(builtBuildings).length
        this.productionBuildingsCount = evaluation.getProductionBuildings(builtBuildings).length

        return await evaluation.getValue("wpFromBuildings").then(wp => {
          this.wpFromBuildings = parseInt(wp);
          return evaluation.getValue("numberOfWpChips");
        }).then(numberOfWpChips => {
          this.numberOfWpChips = parseInt(numberOfWpChips);
          return evaluation.getValue("hasFirstCastle");
        }).then(hasFirstCastle => {
          this.hasFirstCastle = hasFirstCastle;
          return Promise.resolve();
        }).finally(() => {
          this.loading = false;
        });
			},
		},
	}
</script>

<style lang="scss" scoped>
	.name {
		color: grey;
	}

	.value {
		max-width: 2rem;
	}

	#info-btn {
		top: 60px;
		right: 5px;
	}

  .v-list-item__action {
    justify-content: flex-end;
  }

  .v-list-item__subtitle {
    -webkit-line-clamp: unset;
  }

  .v-subheader {
    display: -webkit-box;
  }

  .extra-wp-hint {
    min-height: unset !important;
    .v-list-item__content {
      padding-top: 0;
    }
  }
</style>
