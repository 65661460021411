<template>
	<div>
		<p class="grey--text">Bitte fülle folgende Felder aus:</p>
		<EditGame :game="game" :customerId.sync="customerId"></EditGame>
		<br>
    <div class="d-flex justify-end">
      <v-btn color="success" @click="addGame" :loading="loading" :disabled="loading">
        <v-icon left>
          {{mdiContentSave}}
        </v-icon>
        Speichern
      </v-btn>
    </div>
	</div>
</template>

<script>
  import {mdiContentSave} from "@mdi/js";
	import EditGame from "@/components/EditGame";
	import {games} from "@/_model";

	export default {
		name: "AddGame",
		components: {EditGame},
		data: () => ({
      mdiContentSave,
			loading: false,
			game: games.getGameStructure(),
			customerId: null
		}),
		methods: {
			addGame() {
				this.loading = true;
				games.insert(this.game, this.customerId).then(response => {
					this.$store.commit("globalSuccess", response);
					this.$router.push("/games");
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.loading = false;
				});
			}
		}
	}
</script>

<style scoped>

</style>