import { render, staticRenderFns } from "./Buildings.vue?vue&type=template&id=6bcbb023&scoped=true"
import script from "./Buildings.vue?vue&type=script&lang=js"
export * from "./Buildings.vue?vue&type=script&lang=js"
import style0 from "./Buildings.vue?vue&type=style&index=0&id=6bcbb023&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcbb023",
  null
  
)

export default component.exports