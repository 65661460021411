<template>
	<div>
		<p>Wähle die Erweiterungen aus, mit denen ihr spielt:</p>
		<ExtensionChooser :extensions.sync="extensions"></ExtensionChooser>
		<p></p>
		<v-btn to="/buildings" class="success">
      <v-icon left>
        {{mdiContentSave}}
      </v-icon>
      Speichern
    </v-btn>
	</div>
</template>

<script>
	import ExtensionChooser from "@/components/ExtensionChooser";
	import {extension} from "@/_model";
  import {mdiContentSave} from "@mdi/js";

	export default {
		name: "ChooseExtensions",
		components: {ExtensionChooser},
    data: () => ({
      mdiContentSave
    }),
		computed: {
			extensions: {
				get() {
					return this.$store.getters.extensions;
				},
				set(value) {
					extension.setExtensions(value);
				}
			}
		},
	}
</script>

<style scoped>

</style>