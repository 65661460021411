<template>
	<v-snackbar :value="val" :color="color" :timeout="timeout">
		{{val}}
		<template v-slot:action>
			<v-btn dark text @click="val = ''" icon>
				<v-icon>{{mdiClose}}</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	import {mdiClose} from "@mdi/js";

	export default {
		name: "GlobalSnackbar",
		props: ["value", "color", "timeout"],
		data: () => ({
			mdiClose
		}),
		computed: {
			val: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('update:value', val);
				}
			}
		},
	}
</script>

<style scoped>

</style>