<template>
  <div class="qr-wrapper">
    <div class="qr-border">
      <video ref="previewElement" />
      <div :class="messages ? 'bg-white' : ''" class="messages">
        <div v-if="cameraPermissionError">
          <p class="error--text">Bitte Zugriff auf die Kamera geben!</p>
          <v-btn color="primary" @click="$router.go(0)">
            <v-icon left>{{ mdiRefresh }}</v-icon>
            Nochmal versuchen
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BrowserQRCodeReader} from "@zxing/browser";
import {mdiRefresh} from "@mdi/js";

export default {
  name: "QrCodeReader",
  data: () => ({
    mdiRefresh,
    qrReader: null,
    previewElement: null,
    cameraPermissionError: false,
  }),
  mounted() {
    this.emitInterface();
    this.setupQrReader();
  },
  beforeDestroy() {
    BrowserQRCodeReader.releaseAllStreams();
  },
  computed: {
    messages() {
      return (this.cameraPermissionError)
    },
  },
  methods: {
    setupQrReader() {
      this.qrReader = new BrowserQRCodeReader();
      this.previewElement = this.$refs.previewElement
    },
    startDecoding() {
      this.qrReader?.decodeOnceFromVideoDevice(undefined, this.previewElement)
        .then((result) => {
          if (result) {
            this.$emit('qrCodeValueChanged', result.getText());
          } else {
            this.$emit('error', new Error("QR-Code result is empty!"));
          }
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            this.cameraPermissionError = true
          } else {
            this.$emit('error', error);
          }
        })
    },
    emitInterface() {
      this.$emit("interface", {
        startDecodingQrCode: () => this.startDecoding(),
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.qr-wrapper {
  width: 18rem;
  height: 18rem;

  .qr-border {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    background: linear-gradient(to right, #195aa9 0.5rem, transparent 0.5rem) 0 0,
    linear-gradient(to right, #195aa9 0.5rem, transparent 0.5rem) 0 100%,
    linear-gradient(to left, #195aa9 0.5rem, transparent 0.5rem) 100% 0,
    linear-gradient(to left, #195aa9 0.5rem, transparent 0.5rem) 100% 100%,
    linear-gradient(to bottom, #195aa9 0.5rem, transparent 0.5rem) 0 0,
    linear-gradient(to bottom, #195aa9 0.5rem, transparent 0.5rem) 100% 0,
    linear-gradient(to top, #195aa9 0.5rem, transparent 0.5rem) 0 100%,
    linear-gradient(to top, #195aa9 0.5rem, transparent 0.5rem) 100% 100%;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
  }

  video,
  .messages {
    width: 18rem;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    object-fit: cover;
    padding: 0.5rem;
  }

  .messages {
    position: absolute;
    text-align: center;
    .error {
      color: red;
    }
    .success {
      color: green;
    }
    &.bg-white {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
