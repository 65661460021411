<template>
	<v-app id="app-wrapper">
		<template v-if="!$route.meta.template|| $route.meta.template === 'default'">
			<Navbar :leftDrawer.sync="leftDrawer"></Navbar>
			<!--    <v-main v-touch="{right: () => this.leftDrawer = true}">-->
			<v-main>
				<!--      {{displayMode}}-->
				<v-container>
					<v-card class="max-width"
					        :class="[smallCardPadding ? '' : $vuetify.breakpoint.xsOnly ? 'pa-4' :
			                 $vuetify.breakpoint.smAndUp ? 'pa-6': '']"
					>
						<v-btn v-if="gameIsStarted && isBuildingsView" fixed fab right :bottom="$vuetify.breakpoint.smAndUp"
						       class="primary" id="maximize-btn" link @click="openHints"
						>
							<v-icon>{{mdiDiceMultiple}}</v-icon>
						</v-btn>
						<router-view></router-view>
					</v-card>

					<BuildingChips v-if="isBuildingsView" ></BuildingChips>
				</v-container>
				<br>
				<br>

				<DiceOverlay></DiceOverlay>
				<LoadingOverlay></LoadingOverlay>
				<AddToHomeScreen></AddToHomeScreen>

				<MissedRoundDialog></MissedRoundDialog>
				<BuildCastleDialog></BuildCastleDialog>
				<CountdownTimeDialog></CountdownTimeDialog>
				<AttackChipDialog></AttackChipDialog>
        <CountingDiceDialog></CountingDiceDialog>

				<UpdateCacheMessage></UpdateCacheMessage>

				<GlobalSnackbar :value.sync="globalSuccess" color="success"></GlobalSnackbar>
				<GlobalSnackbar :value.sync="globalError" color="error" :timeout="-1"></GlobalSnackbar>
				<PauseSnackbar></PauseSnackbar>

			</v-main>
			<div id="pwa-status-bar"></div>

			<v-footer app v-if="!gameIsStarted && !['/home', '/extensions', '/'].includes($route.path)" fixed>
				<v-btn text small color="primary" @click="goBack">
					<v-icon left>{{mdiChevronLeft}}</v-icon>
					Zurück
				</v-btn>
			</v-footer>
		</template>
		<template v-else-if="$route.meta.template === 'bauamt'">
			<router-view></router-view>
		</template>
	</v-app>
</template>

<script>
	import {mdiDiceMultiple, mdiChevronLeft} from "@mdi/js";
	import Navbar from "@/components/Navbar";
	import LoadingOverlay from "@/components/LoadingOverlay";
	import DiceOverlay from "@/components/DiceOverlay";
	import MissedRoundDialog from "@/components/MissedRoundDialog";
	import BuildCastleDialog from "@/components/BuildCastleDialog";
	import CountdownTimeDialog from "@/components/CountdownTimeDialog";
	import AttackChipDialog from "@/components/AttackChipDialog";
	import UpdateCacheMessage from "@/components/UpdateCacheMessage";
	import GlobalSnackbar from "@/components/GlobalSnackbar";
	import AddToHomeScreen from "@/components/AddToHomeScreen";
	import PauseSnackbar from "@/components/PauseSnackbar";
	import BuildingChips from "@/components/BuildingChips";
	import {game, countdown} from "@/_helpers";
	import {mapState} from 'vuex';
	import {mapFields} from 'vuex-map-fields';
  import CountingDiceDialog from "@/components/CountingDiceDialog";
  import {gameDb} from "@/_controller";
  import {building, faculty} from "@/_model";
  import {getWinningPointChips} from "@/_helpers/qrcode-winning-point-chip";
  import {getExtraFireProtection} from "@/_helpers/qrcode-extra-fire-protection";

	export default {
		name: 'App',
		components: {
			AddToHomeScreen, PauseSnackbar, GlobalSnackbar, LoadingOverlay, DiceOverlay, Navbar, UpdateCacheMessage,
			MissedRoundDialog, BuildCastleDialog, CountdownTimeDialog, AttackChipDialog, BuildingChips, CountingDiceDialog
		},
		data: () => ({
			mdiDiceMultiple, mdiChevronLeft,
			leftDrawer: false
		}),
		created() {
			// initialize audio on first click on screen (necessary for iOS)
			if (!countdown.audio) {
				countdown.audio = new Audio("audio/alarm.mp3");
				document.getElementsByTagName("body")[0].addEventListener("click", this.initAudio);
			}
      countdown.initialize();

      if (this.$vuetify.breakpoint.mdAndUp) {
        this.leftDrawer = true;
      }

      this.initGameDb();
		},
		computed: {
			...mapState(["isBuildingsView", "smallCardPadding", "builtBuildings", "gameIsStarted", "gameIsEnded"]),
			...mapFields(["globalError", "globalSuccess"]),
			displayMode() {
				if (window.matchMedia('(display-mode: standalone)').matches) {
					return "standalone";
				} else if (window.matchMedia('(display-mode: browser)').matches) {
					return "browser";
				} else if (window.matchMedia('(display-mode: fullscreen)').matches) {
					return "fullscreen";
				} else if (window.matchMedia('(display-mode: minimal-ui)').matches) {
					return "minimal-ui";
				} else {
					return "kein match";
				}
			}
		},
		methods: {
      async initGameDb() {
        let gameDbExists = gameDb.exists();
        let playingGame = game.playingGame();
        if (!gameDbExists && playingGame) {

          // intialize game + mayorName and groupName
          await gameDb.init().then(instance => {
            this.$store.commit("setGameDbInitialized", true);
            return gameDb.instance.getValue("mayorName");
          }).then(mayorName => {
            this.$store.commit("setMayorName", mayorName);
            return gameDb.instance.getValue("groupName");
          }).then(groupName => {
            this.$store.commit("setGroupName", groupName);
          }).catch(error => {
            console.error(error);
          });

          this.$store.commit("setBuiltBuildings", await building.getBuilt());
          game.setShowHouses(game.getShowHouses());

          faculty.setChosen(faculty.getChosen());

          this.$store.commit("setExtraFireProtection", getExtraFireProtection());
          this.$store.commit("setWinningPointChips", getWinningPointChips());
        }
      },
			openHints() {
				this.$router.push('/buildings/hints');
			},
			initAudio() {
        countdown.audio.muted = true;
        countdown.audio.addEventListener("ended", this.unmuteAudioAfterInit)
				countdown.audio.play()
				document.getElementsByTagName("body")[0].removeEventListener("click", this.initAudio);
			},
      unmuteAudioAfterInit() {
        countdown.audio.muted = false;
        countdown.audio.removeEventListener("ended", this.unmuteAudioAfterInit)
      },
			goBack() {
				if (this.$route.path === "/evaluation") {
					game.backToGame();
				}
				this.$router.go(-1);
			}
		},
    beforeDestroy() {
      gameDb.destroy();
    }
  };
</script>

<style>
	:root {
		--pwa-offset: 20px;
		--pwa-offset-bottom: 0px;
		--maximize-btn-top: 60px;
	}

	/*bigger offset on iPhones with Notch*/
	@media (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3),
				 (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3),
				 (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) {
		@supports (-webkit-touch-callout: none) {
			:root {
				--pwa-offset: 44px;
				--pwa-offset-bottom: 20px;
			}
		}
	}

	img {
		object-fit: contain;
	}
	.highlighted {
		background-color: lightgray;
	}
	.highlight-transition {
		transition: background-color .5s;
	}
	.max-width {
		max-width: 700px;
		margin: auto;
	}
	#maximize-btn {
		top: var(--maximize-btn-top);
		right: 5px;
	}

	@media screen and (min-width: 600px) {
		#maximize-btn {
			top: unset;
			bottom: 5px;
			right: 260px;
		}
	}

	/* status bar for iOS pwa */
	@media (display-mode: standalone) and (orientation: portrait) ,
				 (display-mode: standalone) and (orientation: landscape) and (min-device-height: 1024px) {
		/*only iOS devices*/
		@supports (-webkit-touch-callout: none) {
			#app-bar {
				top: var(--pwa-offset) !important;
			}
			#drawer {
				top: var(--pwa-offset) !important;
			}
			#drawer-right {
				top: var(--pwa-offset) !important;
			}
			#app-wrapper .v-application--wrap {
				top: var(--pwa-offset);
			}
			#pwa-status-bar {
				position: fixed;
				top: -1px;
				width: 100%;
				height: calc(var(--pwa-offset) + 2px);
				background: #195aa9;
				z-index: 5;
			}
			#maximize-btn {
				top: calc(var(--maximize-btn-top) + var(--pwa-offset));
			}
			footer {
                padding-bottom: var(--pwa-offset-bottom) !important;
            }
		}
	}

	@media (display-mode: standalone) and (orientation: landscape) and (min-device-height: 1024px) {
		/*only iOS devices*/
		@supports (-webkit-touch-callout: none) {
			#drawer {
				top: calc(var(--pwa-offset) + 64px) !important;
			}
			#drawer-right {
				top: calc(var(--pwa-offset) + 64px) !important;
			}
		}
	}

	/*custom scroll bar*/
	* {
		scrollbar-width: thin;
		scrollbar-color: lightgrey white;
	}
	/*Works on Chrome/Edge/Safari */
	*::-webkit-scrollbar {
		width: 12px;
	}
	*::-webkit-scrollbar-track {
		background: white;
	}
	*::-webkit-scrollbar-thumb {
		background-color: lightgrey;
	}
</style>
