import moment from "moment";
import {backend, helper, ls} from "@/_helpers";
import {customer} from "@/_model";
import config from "@/config";
import store from "@/store";

moment.locale("de");

export const games = {
	async get() {
		let currentUser = store.state.currentUser;
		if (currentUser.roles.includes("Admin")) {
			return await customer.getAll().then(customers => {
				let games = [];
				for (let customer of customers) {
					for (let game of customer.games) {
						game.customerId = customer.id;
						game.customerName = customer.name;
						games.push(game);
					}
				}
				return games;
			});
		} else {
			return backend.post("getGames", {customerId: currentUser.id});
		}
	},
	async getAvailableGamesFromServer() {
		let customerId = store.state.currentUser.id;
		return backend.post("getNotPlayedGames", {customerId});
	},
	getAvailableGames() {
		return ls.getObject(config.gamePrefix + "availableGames");
	},
	setAvailableGames(value) {
		store.commit("setAvailableGames", value);
		ls.setObject(config.gamePrefix + "availableGames", value);
	},
	async writeAvailableGamesFromServer() {
		return await games.getAvailableGamesFromServer().then(gamesObjs => {
			games.setAvailableGames(gamesObjs);
			return Promise.resolve(gamesObjs);
		});
	},
	async insert(game, customerId = null) {
		delete game.customerId;
		delete game.customerName;
		let data = {
			game,
			customerId: customerId || store.state.currentUser.id,
		};
		return backend.post("insertGame", data);
	},
	async delete(game) {
		let data = {
			gameId: game.id,
			customerId: game.customerId ? game.customerId : store.state.currentUser.id,
		};
		return backend.post("deleteGame", data);
	},
	async getById(gameId) {
		return await games.get().then(games => {
			return games.find(g => g.id === gameId);
		});
	},
	toCouchFormat(game) {
		let newGame = helper.clone(game);
		newGame.settings.extensions = newGame.settings.extensions.filter(e => e.enabled).map(e => e.id);
		return newGame;
	},
	getGameStructure() {
		return {
			name: moment().format("Do MMM YYYY"),
			settings: {
				roundTime: config.roundTime,
				extraRoundTime: config.extraRoundTime,
				liveRank: false,
				extensions: [
					{
						id: "naturkatastrophen",
						name: "Naturkatastrophen",
						enabled: false
					},
					{
						id: "glaubeUndBildung",
						name: "Glaube & Bildung",
						enabled: false
					},
					{
						id: "räuberUndGendarm",
						name: "Räuber & Gendarm",
						enabled: false
					},
					{
						id: "zufriedenheit",
						name: "Zufriedenheit",
						enabled: false
					}
				]
			},
			played: false
		}
	}
};
