<template>
	<div>
		<p v-if="games.length <= 0" class="grey--text">{{message}}</p>
		<div v-else>
      <p>
        <v-btn color="primary" @click="reloadGames">
          <v-icon left>{{mdiRefresh}}</v-icon>
          Aktualisieren
        </v-btn>
      </p>
			<v-simple-table fixed-header height="70vh">
				<template v-slot:default>
					<thead>
					<tr>
						<th class="text-left">Platz</th>
						<th class="pa-0">
							<v-divider vertical></v-divider>
						</th>
            <th class="text-left">SPs</th>
            <th class="pa-0">
              <v-divider vertical></v-divider>
            </th>
						<th class="text-left">Gruppe</th>

						<th class="pa-0">
							<v-divider vertical></v-divider>
						</th>
						<th class="text-left">Gebäude-SP</th>
						<th class="text-left" v-if="$store.getters.withAnyExtensions(['zufriedenheit', 'glaubeUndBildung'])">SP-Chips</th>
            <th class="text-left">1.Schloss-Bonus</th>
            <th class="text-left">Wohnhäuser-Bonus</th>
            <th class="text-left">öffentl. Gebäude-Bonus</th>
						<th class="text-left">Gesamt-SP</th>

						<th class="pa-0">
							<v-divider vertical></v-divider>
						</th>
						<th class="text-left" style="width: 100%">Auswertung abgeschlossen?</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="(game, index) in games">
						<tr :class="[(gameDbName === game.dbName) ? 'currentGame' : '']">
							<td>{{ index+1 }}</td>
							<th class="pa-0">
								<v-divider vertical></v-divider>
							</th>
              <td class="text-center font-weight-bold">{{game.totalWps}}</td>
              <th class="pa-0">
                <v-divider vertical></v-divider>
              </th>
							<td>
								{{ game.groupName || "keine Angabe" }}
								<span class="grey--text">({{game.mayorName || "keine Angabe"}} | {{game.builtBuildingsCount}} Gebäude)</span>
							</td>

							<th class="pa-0">
								<v-divider vertical></v-divider>
							</th>
							<td>{{ game.wpFromBuildings }}</td>
							<td v-if="$store.getters.withAnyExtensions(['zufriedenheit', 'glaubeUndBildung'])">+{{ game.numberOfWpChips }}</td>
              <td>{{ (game.hasFirstCastle) ? '+' + firstCastlePoints : '' }}</td>
							<td v-if="game.hasMostHouses">
								+{{mostHousesPoints}} <span class="grey--text">({{game.builtHousesCount}} WH)</span>
							</td>
							<td v-else></td>
              <td v-if="game.hasMostPublicBuildings">
                +{{ mostPublicBuildingsPoints }} <span class="grey--text">({{game.builtPublicBuildingsCount}} öff. Geb.)</span>
              </td>
              <td v-else></td>
							<td>= <span class="font-weight-bold">{{ game.totalWps }}</span></td>

							<th class="pa-0">
								<v-divider vertical></v-divider>
							</th>
							<td class="text-center">
								<v-icon :class="[game.evalDone ? 'success--text' : 'error--text']">
									{{game.evalDone ? mdiCheck : mdiClose}}
								</v-icon>
							</td>
						</tr>
					</template>
					</tbody>
				</template>
			</v-simple-table>
		</div>
	</div>
</template>

<script>
	import {mdiClose, mdiCheck, mdiRefresh} from "@mdi/js";
	import {liveRank, gameDb, evaluation} from "@/_controller";
	import {Pouch, game} from "@/_helpers";
	import {building} from "@/_model";
	import config from "@/config";
	import {mapState} from 'vuex';

	export default {
		name: "LiveRank",
		props: ["gameDbName", "gameId"],
		data: () => ({
			mdiClose, mdiCheck, mdiRefresh,
			mostHousesPoints: config.mostHousesPoints,
			firstCastlePoints: config.firstCastlePoints,
      mostPublicBuildingsPoints: config.mostPublicBuildingsPoints,
			games: [],
			message: "Keine Spieldaten gefunden...",
      firstFetch: false,
		}),
		created() {
			liveRank.connections = [];
			if (!game.playingGame() || (game.playingGame() && this.$store.state.gameDbInitialized)) {
        this.firstFetch = true;
				this.writeGames();
				// this.writeGamesLoop();
			} else {
				this.message = "Spiel noch nicht initialisiert...";
			}
		},
    async beforeDestroy() {
      await liveRank.leave();
    },
    methods: {
			// writeGamesLoop() {
				// liveRank.interval = setInterval(() => {
				// 	console.log("force getting games again");
				// 	this.writeGames();
				// }, 30000); // interval that checks for new liveRank data
			// },
      reloadGames() {
        this.games = [];
        this.writeGames()
      },
			writeGames() {
				this.message = "Lade Daten...";
				this.writeConnections().then(() => {
					let promises = [];
					for (let connection of liveRank.connections) {
						promises.push(this.getGame(connection));
						if (connection.replication) {
							connection.replication.on("change", async (info) => {
                // recursive call
                let currGame = await this.getGame(connection);
                this.writeGame(currGame);
                // debugger;
                this.refreshTable();
              });
						}
					}
					return Promise.all(promises);
				}).then(games => {
					for (let currGame of games) {
						this.writeGame(currGame)
					}
          this.refreshTable();
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.message = "";
				});
			},
      writeGame(currGame) {
        // write game to this.games or update already existing ones
        if (!currGame.mayorName && !currGame.groupName && currGame.builtBuildingsCount === 0) {
          // hide all games that have no data
          return;
        }
        let index = this.games.findIndex(g => g.dbName === currGame.dbName);
        if (index !== -1) {
          this.games[index] = currGame;
        } else {
          this.games.push(currGame);
        }
      },
      refreshTable() {
        // write most houses, totalWps and resort
        this.writeMostHouses();
        this.writeMostPublicBuildings();
        this.writeTotalWps();
        this.sortGames();
      },
			async writeConnections() {
				let gameId = this.gameId || game.getCurrent().id;
				let gameDbName = (gameDb.instance) ? gameDb.instance.dbName : null;
				return await liveRank.getEvalDbNames(gameId).then(async dbNames => {
					let connectedDbNames = liveRank.connections.map(c => c.dbName);
					for (let dbName of dbNames) {
						// check if database is already connected
						if (!connectedDbNames.includes(dbName)) {
							let pouch;
							if (dbName === gameDbName) {
								pouch = gameDb.instance;
							} else {
								pouch = new Pouch(dbName);
								pouch.replicateFrom();
							}
							if (pouch) {
								liveRank.connections.push(pouch);
							}
						}
					}
					return Promise.resolve();
				});
			},
			async getGame(connection) {
				let dbName = connection.dbName;
				let groupName = await connection.getValue("groupName").catch(error => {
					return null;
				});
				let mayorName = await connection.getValue("mayorName").catch(error => {
					return null;
				});

				let evalDone = false;
				let builtBuildings = await connection.getValues("builtBuilding").catch(error => {
					return [];
				});
				let builtBuildingsCount = 0;
				let builtHousesCount = 0;
				let wpFromBuildings = 0;
				let numberOfWpChips = 0;
				let hasFirstCastle = false;
				let hasMostHouses = false;
        let hasMostPublicBuildings = false;

				return await connection.getValue(config.evalPrefix + "done").then(async value => {
					// if evaluation of game is completed
					evalDone = value;
					builtBuildingsCount = await connection.getValue(config.evalPrefix + "builtBuildingsCount");
					builtHousesCount = await connection.getValue(config.evalPrefix + "builtHousesCount");
					wpFromBuildings = await connection.getValue(config.evalPrefix + "wpFromBuildings");

					numberOfWpChips = await connection.getValue(config.evalPrefix + "numberOfWpChips");
					hasFirstCastle = await connection.getValue(config.evalPrefix + "hasFirstCastle");
				}).catch(async error => {
					// if evaluation of game is not completed yet
					builtBuildingsCount = builtBuildings.length;
					builtHousesCount = (builtBuildings.filter(b => b.name === "Wohnhaus")).length;
					wpFromBuildings = building.getWinningPoints(builtBuildings);
				}).then(() => {
          let builtPublicBuildingsCount = building.getPublicBuildings(builtBuildings).length;
					return Promise.resolve({
						dbName, evalDone,
						groupName, mayorName,
						builtBuildingsCount, builtHousesCount, wpFromBuildings,
						numberOfWpChips, hasFirstCastle, hasMostHouses,
            hasMostPublicBuildings, builtPublicBuildingsCount
					});
				});
			},
			writeMostHouses() {
				let mostHousesCount = 0;
				let gamesWithMostHouses = [];
				for (let game of this.games) {
          game.hasMostHouses = false;
					if (game.builtHousesCount > mostHousesCount) gamesWithMostHouses = [];
					if (game.builtHousesCount >= mostHousesCount) {
						gamesWithMostHouses.push(game);
						mostHousesCount = game.builtHousesCount;
					}
				}
				if (mostHousesCount > 0) {
					for (let game of gamesWithMostHouses) {
						game.hasMostHouses = true;
					}
				}
			},
      writeMostPublicBuildings() {
        let mostPublicBuildingsCount = 0;
        let gamesWithMostPublicBuildings = [];
        for (let game of this.games) {
          game.hasMostPublicBuildings = false;
          if (game.builtPublicBuildingsCount > mostPublicBuildingsCount) gamesWithMostPublicBuildings = [];
          if (game.builtPublicBuildingsCount >= mostPublicBuildingsCount) {
            gamesWithMostPublicBuildings.push(game);
            mostPublicBuildingsCount = game.builtPublicBuildingsCount;
          }
        }
        if (mostPublicBuildingsCount > 0) {
          for (let game of gamesWithMostPublicBuildings) {
            game.hasMostPublicBuildings = true;
          }
        }
      },
			writeTotalWps() {
				for (let game of this.games) {
					game.totalWps = liveRank.totalWps(game);
				}
			},
			sortGames() {
				this.games = this.games.sort((a, b) => b.totalWps - a.totalWps);
			},
		},
		computed: {
			...mapState(["gameDbInitialized"]),
		},
		watch: {
			gameDbInitialized() {
        if (!this.firstFetch) {
          this.firstFetch = true;
          this.writeGames();
        }
			}
		},
	}
</script>

<style scoped>
	td {
		white-space: nowrap;
	}

	.stick-left {
		position: sticky;
		left: 0;
		z-index: 100;
		background-color: white;
	}

	.currentGame {
		background-color: #fffec2;
	}
</style>
