import {ls, countdown} from "@/_helpers";
import config from "@/config";
import store from "@/store";

export const attackChip = {
	getRoundOfFirstWache(builtBuildings = null) {
		builtBuildings = builtBuildings || store.state.builtBuildings;
		let buildingObj = builtBuildings.find(b => b.name === "Wache" || b.name === "Gendarmerie");
		if (buildingObj) {
			return countdown.calcRound(new Date(buildingObj.timeAdded).getTime());
		}
		return null;
	},
	getLastAttackChipRound() {
		return ls.getInt("lastAttackChipRound") || null;
	},
	setLastAttackChipRound(value) {
		ls.setItem("lastAttackChipRound", value);
	},
	roundsToNextAttackChip(builtBuildings = null, round) {
		builtBuildings = builtBuildings || store.state.builtBuildings;
		let roundOfFirstWache = attackChip.getRoundOfFirstWache(builtBuildings);
		if (store.getters.withExtension("räuberUndGendarm") && roundOfFirstWache) {
			let currentAttackChips = Math.floor((round - roundOfFirstWache) / config.attackInterval);
			let nextAttackChipRound = roundOfFirstWache + (currentAttackChips + 1) * config.attackInterval;
			return nextAttackChipRound - round;
		} else {
			return null;
		}
	},
	getMissedAttackChips() {
		let lastAttackChipRound = attackChip.getLastAttackChipRound();
		if (lastAttackChipRound) {
			let difference = store.state.round - lastAttackChipRound;
			if (difference >= config.attackInterval) {
				let missedAttackChips = Math.floor(difference / config.attackInterval);
				let newRound = lastAttackChipRound + (missedAttackChips * config.attackInterval);
				attackChip.setLastAttackChipRound(newRound);
				return missedAttackChips;
			}
		}
		return 0;
	},
	checkAttackChipInterval(newRound) {
		let roundOfFirstWache = attackChip.getRoundOfFirstWache();
		return store.getters.withExtension("räuberUndGendarm") &&
				   store.getters.builtBuildingNames.filter(n => n === "Wache" || n === "Gendarmerie").length > 0 &&
					 roundOfFirstWache && (newRound - roundOfFirstWache) % config.attackInterval === 0
	}
};