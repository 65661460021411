<template>
	<v-card-title class="headline">
		<span class="text-truncate">{{title}}</span>
		<v-spacer></v-spacer>
		<v-btn text icon absolute id="close-button" @click="close">
			<v-icon>{{mdiClose}}</v-icon>
		</v-btn>
	</v-card-title>
</template>

<script>
	import {mdiClose} from "@mdi/js";

	export default {
		name: "CardTitleClose",
		props: ["title", "close"],
		data: () => ({
			mdiClose
		}),
	}
</script>

<style scoped>
	.v-card__title.headline {
		padding-right: 3rem !important;
	}
	.v-card__title #close-button {
		right: 7px;
	}
</style>