<template>
	<div>
		<v-list>
			<template v-for="(item, index) in [...brunnen, ...builtBuildings]">
				<template v-if="!isHouse(item) || (isHouse(item) && (showHouses || item.timeAdded === showHouseQuick))">
					<v-list-item :id="item.timeAdded">
						<v-list-item-avatar tile>
							<img :src="getSrc(item.name)" alt="Building Icon">
						</v-list-item-avatar>
						<v-list-item-content>{{item.name}}</v-list-item-content>

						<!--				action button-->
						<v-list-item-action v-if="item.name !== 'Brunnen'">
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on">
										<v-icon color="grey lighten-1">{{mdiDotsVertical}}</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item v-if="item.upgrade && canUpgrade(item)" @click="upgradeBuilding(item)">
										<v-list-item-icon>
											<v-icon>{{mdiOfficeBuilding}}</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Ausbauen ({{item.upgrade}})</v-list-item-title>
									</v-list-item>
									<v-list-item v-if="item.predecessor" @click="downgradeBuilding(item)">
										<v-list-item-icon>
											<v-icon>{{mdiOfficeBuilding}}</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Rückbau ({{item.predecessor}})</v-list-item-title>
									</v-list-item>
									<v-list-item
											v-if="$store.getters.withExtension('glaubeUndBildung') &&
														(['Schule', 'Universität'].includes(item.name))"
											to="/choose-faculties"
									>
										<v-list-item-icon>
											<v-icon>{{mdiSchool}}</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Fakultäten</v-list-item-title>
									</v-list-item>
                  <v-list-item
                      v-if="(item.name === 'Kirche' || item.name === 'Kloster') &&
                            $store.getters.withAllExtensions(['glaubeUndBildung', 'naturkatastrophen'])"
                      to="/scan-qr"
                  >
                    <v-list-item-icon>
                      <v-icon>{{mdiQrcodeScan}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>QR scannen</v-list-item-title>
                  </v-list-item>
									<v-list-item @click="deleteConfirmation(item)">
										<v-list-item-icon>
											<v-icon color="error">{{mdiDelete}}</v-icon>
										</v-list-item-icon>
										<v-list-item-title class="error--text">Löschen</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item-action>
						<span class="grey--text text--lighten-1 index" v-if="index !== 0">
							<span v-if="index === [...brunnen, ...builtBuildings].length-1">Anzahl Gebäude: </span>
							{{index}}
						</span>
					</v-list-item>
				</template>

				<template
						v-if="$store.getters.withExtension('naturkatastrophen') && fireProtectionIndex === index &&
									builtBuildings.length !== 0"
				>
					<!--					<span class="subtitle-2 grey&#45;&#45;text">Brandschutzgrenze</span>-->
					<div class="fire-protection-limit d-flex align-center">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn fab style="background-color: #e0e0e0" elevation="0" :ripple="false" v-on="on"
								       retain-focus-on-click>
									<v-badge color="transparent" right overlap>
										<template v-slot:badge>
											<v-icon size="20" color="success" class="badge-icon"
											        v-if="fireProtectionIndex === builtBuildings.length"
											>
												{{mdiCheckDecagram}}
											</v-icon>
											<v-icon v-else size="20" color="error" class="badge-icon">
												{{mdiAlertDecagram}}
											</v-icon>
										</template>
										<v-icon x-large color="error">{{mdiFireHydrant}}</v-icon>
									</v-badge>
								</v-btn>
							</template>
							<template v-if="fireProtectionIndex === 0">
								<span>Siedlung ist bisher noch nicht vom Feuer geschützt.</span>
							</template>
							<template v-else>
								<span v-if="fireProtectionUntil > fireProtectionIndex">
									Siedlung ist einschließlich dem <strong>{{fireProtectionUntil}}. Gebäude</strong>
									vom Feuer geschützt.
								</span>
								<span v-else>
									Siedlung ist bis hier vom Feuer geschützt.
								</span>
								<br>
								Alle Gebäude danach nicht.
							</template>
						</v-tooltip>
						<v-divider></v-divider>
					</div>
				</template>
				<v-divider v-else-if="index !== [...brunnen, ...builtBuildings].length-1"></v-divider>

				<template v-if="isHouse(item) && !showHouses">
					<v-badge overlap left color="grey" bordered class="house-chip">
						<template v-slot:badge>
							<div class="d-flex">
								<span>+</span>
								<v-img class="d-inline-block" contain width="15" :src="getSrc('Wohnhaus')"></v-img>
							</div>
						</template>
					</v-badge>
				</template>
			</template>
		</v-list>


		<router-view></router-view>


		<v-snackbar v-model="deletedSnackbar" :timeout="10000">
			<div>
				{{lastDeletedBuilding.name}} gelöscht. <strong>Rückgängig?</strong>
			</div>

			<template v-slot:action>
				<v-btn icon @click="revertDeletion" color="white">
					<v-icon>{{mdiCheck}}</v-icon>
				</v-btn>
				<v-btn icon @click="deletedSnackbar = false" color="white">
					<v-icon>{{mdiClose}}</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

		<ConfirmationDialog :dialog="deletionDialog">
			<template v-slot:header>
				Hinweis
			</template>
			<template v-slot:text>
				Gebäude, die durch Naturkatastrophen zerstört werden, müssen in der App nicht gelöscht werden.<br>
				Trotzdem löschen?
			</template>
			<template v-slot:actions>
				<v-btn text color="primary" @click="deletionDialog = false">
					Abbrechen
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="error" @click="deletionDialog = false; deleteBuilding(tempBuildingObj)">
					Löschen
				</v-btn>
			</template>
		</ConfirmationDialog>

		<SmallDialog :dialog.sync="firstWacheDialog">
			<template v-slot:header>
				Erste Wache
			</template>
			<template v-slot:text>
				Die erste Wache dieser Siedlung wurde gebaut. Gib den Siedlern einen <strong>Überfall-Chip</strong> aus!<br>
				Hiermit kann der Räuber eine andere Siedlung überfallen.
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import {
		mdiDotsVertical, mdiOfficeBuilding, mdiDelete, mdiFireHydrant, mdiCheckDecagram, mdiSchool, mdiAlertDecagram,
		mdiClose, mdiCheck, mdiQrcodeScan
	} from '@mdi/js';
	import Building from "@/components/Building.vue";
	import ConfirmationDialog from "@/components/ConfirmationDialog";
	import {building} from "@/_model";
	import {helper, game, ls, countdown} from "@/_helpers";
	import config from "@/config";
	import {mapState} from 'vuex';
	import SmallDialog from "../components/SmallDialog";
  import {getExtraFireProtection} from "../_helpers/qrcode-extra-fire-protection";

  export default {
		name: "Buildings",
		components: {SmallDialog, ConfirmationDialog, Building},
		data: () => ({
			mdiDotsVertical, mdiOfficeBuilding, mdiDelete, mdiFireHydrant, mdiCheckDecagram, mdiSchool, mdiAlertDecagram,
			mdiClose, mdiCheck, mdiQrcodeScan,
			headers: [
				{text: 'Nr.', value: 'number'},
				{text: 'Gebäude', value: 'name', sortable: false},
				{text: '', value: 'picture', align: 'center', sortable: false},
				{text: 'SP', value: 'wp', sortable: false},
				{text: '', value: 'actions', sortable: false},
			],
			brunnen: [{name: "Brunnen"}],
			showHouseQuick: false,
			fireProtectionLimitShown: false,
			lastDeletedBuilding: {},
			deletedSnackbar: false,
			deletionDialog: false,
			firstWacheDialog: false,
			tempBuildingObj: null
		}),
		async created() {
			this.showHouseQuick = game.initShowHouses();
      countdown.initialize();
      this.$store.commit("setExtraFireProtection", getExtraFireProtection());
		},
		methods: {
			isHouse(item) {
				return item.name === "Wohnhaus";
			},
			getSrc(name) {
				return building.getPicture(name);
			},
			deleteConfirmation(buildingObj) {
				let numberOfDeletedBuildings = ls.getInt("buildingsDeleted") || 0;
				if (this.$store.getters.withExtension("naturkatastrophen") && numberOfDeletedBuildings < 1) {
					this.tempBuildingObj = buildingObj;
					this.deletionDialog = true;
					ls.setItem("buildingsDeleted", ++numberOfDeletedBuildings);
				} else {
					this.deleteBuilding(buildingObj)
				}
			},
			deleteBuilding(buildingObj) {
				this.$store.commit("setGlobalLoading", true);
				this.lastDeletedBuilding = buildingObj;
				building.delete(buildingObj).then(() => {
				}).catch(error => {
					this.$store.commit("globalError", "Konnte nicht gelöscht werden: " + error.message);
				}).then(() => {
					this.$store.commit("setGlobalLoading", false);
					this.deletedSnackbar = true;
				});
			},
			upgradeBuilding(buildingObj) {
				this.$store.commit("setGlobalLoading", true);
				building.upgrade(buildingObj).then(upgradeObj => {
					this.scrollAndHighlight(buildingObj);
					building.checkCastleDialog();
				}).catch(error => {
					this.$store.commit("globalError", "Gebäude-Upgrade fehlgeschlagen: " + error.message);
				}).then(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			downgradeBuilding(buildingObj) {
				this.$store.commit("setGlobalLoading", true);
				building.downgrade(buildingObj).then(predecessorObj => {
					this.scrollAndHighlight(buildingObj);
				}).catch(error => {
					this.$store.commit("setGlobalLoading", false);
				}).then(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},

			scrollAndHighlight(buildingObj) {
				// wait for DOM to be updated
				this.$nextTick(() => {
					let element = document.getElementById(buildingObj.timeAdded);
					helper.scrollAndHighlight(element);
				});
			},
			canUpgrade(item) {
				let buildingObj = this.$store.state.currentAvailableBuildings.find(b => b.name === item.upgrade);
				return buildingObj ? !buildingObj.disabled : false;
			},
			revertDeletion() {
				if (Object.keys(this.lastDeletedBuilding).length !== 0 && this.lastDeletedBuilding.constructor === Object) {
					building.add(this.lastDeletedBuilding).then(res => {
						this.$store.commit("setBuiltBuildings", building.sortByTime(this.builtBuildings));
					}).catch(error => {
						this.$store.commit("globalError", "Rückgängig nicht möglich" + error.message);
					}).then(() => {
						this.deletedSnackbar = false;
					});
				}
			}
		},
		computed: {
			...mapState(["showHouses", "newBuiltBuilding", "builtBuildings", "round"]),
			fireProtectionUntil() {
				let fireBrigades = this.builtBuildings.filter(b => b.name === "Feuerwehr");
				let numberOfFireBrigades = fireBrigades.length || 0;
				return numberOfFireBrigades * config.fireProtectCount + this.$store.state.extraFireProtection;
			},
			fireProtectionIndex() {
				if (this.builtBuildings.length >= this.fireProtectionUntil) {
					return this.fireProtectionUntil;
				} else {
					return this.builtBuildings.length;
				}
			}
		},
		watch: {
			newBuiltBuilding(buildingObj) {
				// show Wohnhaus for 3 seconds if houses shouldn't be shown
				if (buildingObj.name === "Wohnhaus" && !this.showHouses) {
					this.showHouseQuick = buildingObj.timeAdded;
					setTimeout(() => {
						this.showHouseQuick = false;
					}, 3000);
				} else if (buildingObj.name === "Wache" && this.$store.getters.withExtension("räuberUndGendarm")) {
					let wachen = this.$store.state.builtBuildings.filter(b => b.name === "Wache");
					if (wachen.length === 1) {
						this.firstWacheDialog = true;
						ls.setItem("roundOfFirstWache", this.round);
					}
				}
				this.scrollAndHighlight(buildingObj);
				this.$store.commit("setGlobalLoading", false);
			},
		}
	}
</script>

<style scoped>
	.house-chip {
		position: absolute;
		right: 40px;
		margin-top: -1px;
		z-index: 1;
	}

	.fire-protection-limit .v-btn {
		left: calc(50% - 20px);
		position: absolute;
		border: 3px solid white;
	}
	.fire-protection-limit .v-btn::before {
		opacity: 0 !important;
	}
	.fire-protection-limit .v-badge .badge-icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	.fire-protection-limit .v-divider {
		border-width: thick;
		margin: 1rem 0;
	}

	.index {
		position: absolute;
		right: 0.5rem;
		top: 0.25rem;
		font-size: 0.6rem;
	}
</style>
