import {auth} from "@/_helpers";

export const authorize = {
	methods: {
		hasAccess(routeName) {
			return auth.hasAccess(routeName);
		},
		hasRole(role) {
			return auth.hasRole(role);
		}
	},
};