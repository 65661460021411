<template>
	<div v-if="customer.access">
		<v-text-field outlined label="Kundenname" v-model="customer.name" required :rules="rules"
		              :prepend-icon="mdiAccountDetails"
		>
		</v-text-field>
		<v-text-field outlined label="Zugangs-Code Spielleitung" v-model="customer.access[0].value" required
		              :rules="rules" :prepend-icon="mdiLock"
		>
		</v-text-field>
		<v-text-field outlined label="Zugangs-Code Bürgermeister/in" v-model="customer.access[1].value" required
		              :rules="rules" :prepend-icon="mdiLock"
		>
		</v-text-field>
		<v-text-field outlined label="Zugangs-Code Bauamt" v-model="customer.access[2].value" required
		              :rules="rules" :prepend-icon="mdiLock"
		>
		</v-text-field>
		<v-dialog ref="dialog" v-model="dialog" :return-value.sync="customer.exp" persistent :max-width="dialogWidth"
		>
			<template v-slot:activator="{ on }">
				<v-text-field outlined label="Ablaufdatum" :prepend-icon="mdiCalendar"
				              readonly v-on="on" :value="getDate"
				>
				</v-text-field>
			</template>
			<v-date-picker v-model="customer.exp" scrollable locale="de">
				<v-spacer></v-spacer>
				<v-btn text color="primary" @click="dialog = false">Abbrechen</v-btn>
				<v-btn text color="primary" @click="$refs.dialog.save(customer.exp)">OK</v-btn>
			</v-date-picker>
		</v-dialog>
		<v-switch v-model="customer.canCreateGames" label="Kann Spiele erstellen">
		</v-switch>
	</div>
</template>

<script>
	import {mdiAccountDetails, mdiLock, mdiCalendar} from "@mdi/js";
	import moment from "moment";
	import config from "@/config";

	export default {
		name: "EditCustomer",
		props: ["customer"],
		data: () => ({
			mdiAccountDetails, mdiLock, mdiCalendar,
			rules: [v => (!!v || v === 0) || 'Feld ist erforderlich!'],
			dialog: false,
			dialogWidth: config.smallDialogWidth
		}),
		computed: {
			getDate() {
				return this.customer.exp ? moment(this.customer.exp).format('dddd, Do MMMM YYYY') : ''
			}
		}
	}
</script>

<style scoped>
	.v-input--switch {
		margin-top: 0;
	}
</style>