<template>
	<div>
		<p v-if="Object.keys(customerObj).length === 0 && customerObj.constructor === Object" class="grey--text">
			{{ message }}
		</p>
		<v-form ref="form">
			<EditCustomerComponent :customer="customerObj"></EditCustomerComponent>
      <div class="d-flex justify-end">
        <v-btn class="success" type="submit" @click="validate" :loading="loading" :disabled="loading">
          <v-icon left>
            {{mdiContentSave}}
          </v-icon>
          Speichern
        </v-btn>
      </div>
		</v-form>
	</div>
</template>

<script>
	import EditCustomerComponent from "@/components/EditCustomer";
	import {customer} from "@/_model";
  import {mdiContentSave} from "@mdi/js";

	export default {
		name: "EditCustomer",
		components: {EditCustomerComponent},
		data: () => ({
      mdiContentSave,
			customerObj: {},
			message: "Kunde nicht gefunden...",
			loading: false,
		}),
		created() {
			this.writeCustomers();
		},
		methods: {
			writeCustomers() {
				this.$store.commit("setGlobalLoading", true);
				customer.getById(this.$route.params.id).then(customer => {
					for (let accessObj of customer.access) {
						accessObj.value = "";
					}
					this.customerObj = customer;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.message = error.message;
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			saveCustomer() {
				this.loading = true;
				customer.insert(this.customerObj).then(response => {
					this.$store.commit("globalSuccess", response);
					this.$router.push("/customers");
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.loading = false;
				});
			},
			validate() {
				if (this.$refs.form.validate()) {
					this.saveCustomer();
				}
			},
		}
	}
</script>

<style scoped>

</style>