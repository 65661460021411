<template>
	<div class="text-center">
		<v-overlay :value="diceOverlay" color="error" opacity="1" z-index="250" @click.native="diceOverlay = false">
			<div>
				<h1 style="font-size: 2rem" class="text--white">
					WÜRFELN
					<v-icon size="60">{{mdiDiceMultiple}}</v-icon>
				</h1>
			</div>
		</v-overlay>
	</div>
</template>

<script>
	import {mdiDiceMultiple} from "@mdi/js";
	import {countdown} from "@/_helpers";
	import config from "@/config";
	import {mapFields} from 'vuex-map-fields';

	export default {
		name: "DiceOverlay",
		data: () => ({
			mdiDiceMultiple
		}),
		computed: {
			...mapFields(["diceOverlay"]),
		},
	}
</script>

<style scoped>

</style>