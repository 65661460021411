<template>
	<div>
		<v-app-bar color="white" class="d-flex justify-center">
			<div v-for="(resource, index) in resources" class="resource-wrapper">
				<img :src="'/img/resources/'+resource.id+'.png'" width="40">
				<span class="display-2">{{calcResource(index)}}</span>
			</div>
		</v-app-bar>

		<div class="grid ma-1">
			<div class="square" v-for="(item, index) in items" :key="item.title" >
				<v-sheet elevation="3" class="d-flex flex-column align-center pa-1"
				         width="100%" @click="item.chosen = !item.chosen" :class="[item.chosen ? 'secondary' : 'white']"
				>
						<div class="caption text-truncate text-center">{{item.title}}</div>
						<img :src="'/img/'+item.image+'.png'">
				</v-sheet>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Bauamt",
		data: () => ({
			resources: [
				{id: "Holz"},
				{id: "Stein"},
				{id: "Eisen"},
				{id: "Stroh"},
			],
			items: [
				{title: "Wohnhaus", image:"buildings/Wohnhaus", chosen: false, price: [1, 1, 1, 1]},
				{title: "Sägewerk", image:"buildings/Sägewerk", chosen: false, price: [2, 0, 1, 1]},
				{title: "Bergwerk", image:"buildings/Bergwerk", chosen: false, price: [0, 1, 3, 0]},
				{title: "Schmiede", image:"buildings/Schmiede", chosen: false, price: [0, 2, 1, 1]},
				{title: "Dreschmaschine", image:"buildings/Dreschmaschine", chosen: false, price: [2, 0, 2, 0]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
				{title: "Feuerwehr", image:"buildings/Feuerwehr", chosen: false, price: [1, 1, 1, 1]},
			]
		}),
		computed: {
			chosenItems() {
				return this.items.filter(i => i.chosen);
			},
		},
		methods: {
			calcResource(index) {
				return this.chosenItems.reduce((a, b) => a+b.price[index], 0)
			}
		}
	}
</script>

<style scoped>
	.grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
		grid-gap: 0.5em;
		height: 88vh;
	}
	.grid .square .v-sheet {
		min-width: 4rem;
		position: absolute;
		height: 100%;
	}
	.grid .v-sheet .caption {
		width: 100%;
	}
	.grid .square {
		height: 0;
		padding-bottom: 100%;
		position: relative;
	}
	.resource-wrapper {

	}
</style>