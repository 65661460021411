<template>
	<div>
		<v-switch v-for="(item, index) in extensions" :key="item.id" :label="item.name" v-model="item.enabled"
		          :color="item.id" hide-details @change="saveExtensions(extensions)"
		>
		</v-switch>
	</div>
</template>

<script>
	import config from "@/config";
	import {extension} from "@/_model";

	export default {
		name: "ExtensionChooser",
		props: ["extensions"],
		methods: {
			saveExtensions(extensions) {
				this.$emit("update:extensions", extensions)
			}
		}
	}
</script>

<style scoped>

</style>