<template>
	<div>
		<h1>Willkommen</h1>
		<p>Du hast dich erfolgreich in der Cantopia-App eingeloggt. Bitte wähle aus, was du tun möchtest:</p>
		<template v-for="(route, index) of routes">
			<v-card outlined :to="'/'+route.lnk" v-if="hasAccess(route.lnk)" class="my-6">
				<v-card-title>
					<span>{{route.title}}</span>
					<v-spacer></v-spacer>
					<v-icon color="primary">{{mdiChevronRight}}</v-icon>
				</v-card-title>
				<v-card-subtitle>{{route.sub}}</v-card-subtitle>
			</v-card>
		</template>
	</div>
</template>

<script>
	import {mdiChevronRight} from "@mdi/js";
	import {authorize} from "@/mixins";

	export default {
		name: "Home",
		mixins: [authorize],
		data: () => ({
			mdiChevronRight,
			routes: [
				{lnk: "preparation", title: "Spiel beitreten", sub: "Hier kannst du als Bürgermeister/in einem Spiel beitreten."},
				{lnk: "games", title: "Spiele verwalten", sub: "Verwalte deine Spiele hier."},
				{lnk: "customers", title: "Kunden verwalten", sub: "Hier lang, um Kunden zu verwalten."},
				{lnk: "admin-infos", title: "Admin Informationen", sub: "Informationen für Administratoren."},
			]
		}),
	}
</script>

<style scoped>

</style>