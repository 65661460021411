<template>
	<div>
    <v-alert type="info" :icon="mdiInformation">
      <p>Ausgewähltes Spiel: <b>{{ currentGame.name }}</b></p>
      Stelle sicher, dass du das korrekte Spiel ausgewählt hast, bevor du das Spiel startest.
    </v-alert>
		<p>Tippe auf 'Spiel starten', sobald der Spielleiter das Startzeichen gibt. Danach startet direkt die erste
			Runde.</p>
		<p>Eine Runde dauert {{ humanReadableRoundTime }} Minuten.</p>
		<v-btn @click="startGame" class="primary" block>Spiel starten</v-btn>
	</div>
</template>

<script>
  import {mdiInformation} from '@mdi/js';
	import {countdown, game} from '@/_helpers';
  import {removeWinningPointChipsFromLocalStorage} from "@/_helpers/qrcode-winning-point-chip";
  import {removeFireProtectionFromLocalStorage} from "@/_helpers/qrcode-extra-fire-protection";

	export default {
		name: "Infos",
		data: () => ({
      mdiInformation
		}),
		computed: {
			humanReadableRoundTime() {
				return countdown.getHumanReadableTime(countdown.getRoundTime());
			},
      currentGame() {
        return game.getCurrent();
      }
		},
		methods: {
			startGame() {
				game.setIsStarted(true);
				game.setIsPaused(false);

        removeWinningPointChipsFromLocalStorage();
        removeFireProtectionFromLocalStorage();

				this.$router.push("/buildings");
			},
		}
	}
</script>

<style scoped>

</style>
