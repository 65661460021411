import { render, staticRenderFns } from "./HintZufriedenheit.vue?vue&type=template&id=4164771a&scoped=true"
import script from "./HintZufriedenheit.vue?vue&type=script&lang=js"
export * from "./HintZufriedenheit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4164771a",
  null
  
)

export default component.exports