<template>
	<div>
		<v-form ref="form">
			<p>Fülle folgende Felder aus, um einen Kunden hinzuzufügen. <br>
				Merke dir die Zugangs-Codes! Du kannst sie hinterher nicht mehr einsehen, nur noch ändern!</p>
			<EditCustomer :customer="customer"></EditCustomer>
<!--			<v-divider></v-divider>-->
<!--			<v-checkbox v-model="createGame" label="zusätzlich ein Spiel erstellen:" :off-icon="mdiCheckboxBlankOutline"-->
<!--			            :on-icon="mdiCheckboxMarked"-->
<!--			>-->
<!--			</v-checkbox>-->
<!--			<template v-if="createGame">-->
<!--				<EditGame :game="customer.games[0]"></EditGame>-->
<!--				<p></p>-->
<!--			</template>-->
      <div class="d-flex justify-end">
        <v-btn type="submit" class="success" @click="validate" :loading="loading" :disabled="loading">
          <v-icon left>
            {{mdiContentSave}}
          </v-icon>
          Speichern
        </v-btn>
      </div>
		</v-form>
	</div>
</template>

<script>
	import {mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiContentSave} from "@mdi/js";
	import moment from "moment";
	import EditCustomer from "@/components/EditCustomer";
	import EditGame from "@/components/EditGame";
	import {customer} from "@/_model";

	export default {
		name: "AddCustomer",
		components: {EditGame, EditCustomer},
		data: () => ({
			mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiContentSave,
			customer: {
				name: "",
				access: [
					{roles: ["Spielleiter"], value: ""},
					{roles: ["Bürgermeister"], value: ""},
					{roles: ["Bauamt"], value: ""},
				],
				exp: moment().add(2, "months").toISOString().substr(0, 10),
				// games: [games.getGameStructure()],
				canCreateGames: false,
			},
			loading: false,
			// createGame: true,
		}),
		methods: {
			validate() {
				if (this.$refs.form.validate()) {
					this.saveData();
				}
			},
			async saveData() {
				this.loading = true;
				// if (!this.createGame) {
				// 	this.customer.games = [];
				// }
				customer.insert(this.customer).then(response => {
					this.$store.commit("globalSuccess", response);
					this.$router.push("/customers");
				}).catch(error => {
					console.log(error);
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.loading = false;
				});
			},

		}
	}
</script>

<style scoped>

</style>