import {router} from '@/router';
import {v4 as uuidv4} from "uuid";
import {auth, game, ls} from "@/_helpers";
import config from "@/config";
import store from "@/store";

export const helper = {
	getResourcePicture(name) {
		return '/img/resources/' + name + '.png';
	},

	elementIsInViewPort(el) {
		let rect = el.getBoundingClientRect();
		return (
				rect.top >= 64 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 48 &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},
	scrollTo(element) {
		let yOffset = -120;
		let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
		window.scrollTo({top: y, behavior: 'smooth'});
	},
	highlight(target, duration = 1500) {
		target.classList.add("highlight-transition");
		target.classList.add("highlighted");
		setTimeout(() => {
			target.classList.remove("highlighted");
		}, duration);
	},
	scrollAndHighlight(element) {
		if (!this.elementIsInViewPort(element)) {
			this.scrollTo(element);
		}
		this.highlight(element);
	},

	isBuildingsView() {
		return router.currentRoute.path.startsWith('/buildings');
	},

	clone(obj) {
		return JSON.parse(JSON.stringify(obj));
	},

	getSuffix() {
		let uuid = ls.getItem("pouchSuffix");
		if (!uuid) {
			uuid = uuidv4().substring(0, 7);
			ls.setItem("pouchSuffix", uuid);
		}
		return uuid;
	},

	getGameDbName(currentGame = null, currentUser = null) {
		currentGame = currentGame || game.getCurrent();
		currentUser = currentUser || auth.getCurrentUser();
		if (currentGame && currentUser) {
			// create Pouch instance
			return config.dbPrefix + currentUser.id + "_" + currentGame.id + "_" + helper.getSuffix();
		} else {
			return null;
		}
	}
};
