import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import {router} from '@/router'
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import 'typeface-roboto'
import '@/sass/main.scss'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(Viewer, {
	defaultOptions: {
		"inline": false, "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": false,
		"movable": false, "zoomable": true, "rotatable": false, "scalable": false, "transition": true, "fullscreen": true,
		"keyboard": false, "url": "data-source"
	}
});

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

// Vue.config.productionTip = false;

export default new Vue({
	router,
	vuetify,
	store,
	render(h) {
		return h(App)
	},
}).$mount('#app');
