<template>
	<v-snackbar bottom color="info" v-model="showUpdateCacheMessage" :timeout="-1">
		Update! Seite neu laden?
		<template v-slot:action>
			<v-btn dark text @click="updateCache" icon color="white">
				<v-icon>{{mdiCheck}}</v-icon>
			</v-btn>
			<v-btn dark text @click="showUpdateCacheMessage = false" icon color="white">
				<v-icon>{{mdiClose}}</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	import {mdiCheck, mdiClose} from "@mdi/js";
	import {mapFields} from 'vuex-map-fields';

	export default {
		name: "UpdateCacheMessage",
		data: () => ({
			mdiCheck, mdiClose
		}),
		computed: {
			...mapFields(["showUpdateCacheMessage"]),
		},
		methods: {
			updateCache() {
				this.snackbar = false;
				window.location.reload();
				// this.$router.go();
			}
		},
	}
</script>

<style scoped>

</style>