<template>
  <div>
    <p>Scanne hier den QR-Code, wenn du im Spiel einen siehst:</p>
    <QrCodeReader
        @qrCodeValueChanged="qrCodeValueChanged"
        @error="onError"
        @interface="getQrCodeReaderInterface"
        style="margin: auto"
    ></QrCodeReader>
    <br>
    <v-alert type="info" :icon="mdiInformation">
        Falls der QR-Code-Scan für Siegerpunkte nicht klappt,
        können diese bei Spielende manuell in der Auswertung eingetragen werden.
    </v-alert>

    <SmallDialog :dialog.sync="addedFireProtectionDialog">
      <template v-slot:header>
        Hat funktioniert!
      </template>
      <template v-slot:text>
        <v-alert type="success" :icon="mdiFireHydrant">
          Eure Siedlung ist jetzt besser vor Feuer geschützt!
        </v-alert>
        <p>Der Feuerschutz wurde um 3 Gebäude erhöht, ohne dass ihr eine weitere Feuerwehr benötigt.</p>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addedFireProtectionDialog = false; decodeQrCode()">
          Okay
        </v-btn>
      </template>
    </SmallDialog>

    <SmallDialog :dialog.sync="addedWinningPointChipDialog">
      <template v-slot:header> Hat funktioniert! </template>
      <template v-slot:text>
        <v-alert type="success" :icon="mdiNumeric1CircleOutline">
          Du hast erfolgreich den Siegerpunkte-Chip eingetragen!
        </v-alert>
        <p>Ihr habt jetzt 1 Siegerpunkt mehr!</p>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="
            addedWinningPointChipDialog = false;
            decodeQrCode();
          "
        >
          Okay
        </v-btn>
      </template>
    </SmallDialog>

    <SmallDialog :dialog.sync="alreadyUsedQrCodeDialog">
      <template v-slot:header>
        Bereits verwendet!
      </template>
      <template v-slot:text>
        <v-alert type="warning" :icon="mdiAlertCircle">
          Dieser QR-Code wurde bereits eingescannt!
        </v-alert>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="alreadyUsedQrCodeDialog = false; decodeQrCode()">
          Okay
        </v-btn>
      </template>
    </SmallDialog>

    <SmallDialog :dialog.sync="couldNotReadQrCodeDialog">
      <template v-slot:header>
        Fehler!
      </template>
      <template v-slot:text>
        <v-alert type="error" :icon="mdiAlertCircle">
          Dieser QR-Code ist nicht gültig, er konnte nicht gelesen werden!
        </v-alert>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="couldNotReadQrCodeDialog = false; decodeQrCode()">
          Okay
        </v-btn>
      </template>
    </SmallDialog>

    <SmallDialog :dialog.sync="genericErrorDialog">
      <template v-slot:header>
        Fehler!
      </template>
      <template v-slot:text>
        <v-alert type="error" :icon="mdiAlertCircle">
          Etwas ist schief gelaufen beim Lesen der Kamera.
        </v-alert>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="genericErrorDialog = false; reload()">
          nochmal versuchen
        </v-btn>
      </template>
    </SmallDialog>

    <v-btn to="/buildings" text color="primary">
      <v-icon left>
        {{mdiChevronLeft}}
      </v-icon>
      Zurück
    </v-btn>
  </div>
</template>

<script>
import QrCodeReader from "../components/QrCodeReader";
import {
  addUsedFireProtectionId,
  fireProtectionIdAlreadyUsed, getExtraFireProtection,
  isValidQrForFireProtection, saveExtraFireProtection
} from "../_helpers/qrcode-extra-fire-protection";
import SmallDialog from "../components/SmallDialog";
import {mdiAlertCircle, mdiFireHydrant, mdiChevronLeft, mdiNumeric1CircleOutline, mdiInformation} from "@mdi/js";
import {
  addUsedWinningPointChipId, getWinningPointChips,
  isValidQrForWinningPointChips, saveWinningPointChips,
  winningPointChipIdAlreadyUsed
} from "@/_helpers/qrcode-winning-point-chip";

export default {
  name: "ScanQr",
  components: {SmallDialog, QrCodeReader},
  qrCodeReaderInterface: {
    startDecodingQrCode: () => {}
  },
  data: () => ({
    mdiAlertCircle, mdiFireHydrant, mdiChevronLeft, mdiNumeric1CircleOutline, mdiInformation,
    addedFireProtectionDialog: false,
    addedWinningPointChipDialog: false,
    alreadyUsedQrCodeDialog: false,
    couldNotReadQrCodeDialog: false,
    genericErrorDialog: false,
  }),
  mounted() {
    this.decodeQrCode()
  },
  methods: {
    qrCodeValueChanged(value) {
      if (isValidQrForFireProtection(value)) {
        if (!fireProtectionIdAlreadyUsed(value)) {
          addUsedFireProtectionId(value);
          this.addExtraFireProtection(3);
          this.addedFireProtectionDialog = true;
        } else {
          this.alreadyUsedQrCodeDialog = true;
        }
      } else if (isValidQrForWinningPointChips(value)) {
        if (!winningPointChipIdAlreadyUsed(value)) {
          addUsedWinningPointChipId(value);
          this.addWinningPointChip();
          this.addedWinningPointChipDialog = true;
        } else {
          this.alreadyUsedQrCodeDialog = true;
        }
      } else {
        this.couldNotReadQrCodeDialog = true;
      }
    },
    decodeQrCode() {
      this.$options.qrCodeReaderInterface.startDecodingQrCode();
    },
    addExtraFireProtection(number) {
      let extraFireProtection = getExtraFireProtection();
      extraFireProtection += number;
      this.$store.commit("setExtraFireProtection", extraFireProtection);
      saveExtraFireProtection(extraFireProtection);
    },
    addWinningPointChip() {
      let winningPointChips = getWinningPointChips();
      winningPointChips++;
      this.$store.commit("setWinningPointChips", winningPointChips);
      saveWinningPointChips(winningPointChips);
    },
    onError(error) {
      this.genericErrorDialog = true;
    },
    reload() {
      this.$router.go(0);
    },
    getQrCodeReaderInterface(childInterface) {
      this.$options.qrCodeReaderInterface = childInterface;
    },
  }
}
</script>

<style scoped>

</style>
