<template>
	<SmallDialog :dialog.sync="dialogVal">
		<template v-slot:header>
			<slot name="header">Wirklich?</slot>
		</template>
		<template v-slot:text>
			<slot name="text"></slot>
		</template>
		<template v-slot:actions>
			<slot name="actions">
				<v-btn color="primary" text @click="dialogVal = false">Abbrechen</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="error" @click="dialogVal = false; confirmed();">Ja</v-btn>
			</slot>
		</template>
	</SmallDialog>
</template>

<script>
	import SmallDialog from "@/components/SmallDialog";
	import config from "@/config";

	export default {
		name: "ConfirmationDialog",
		components: {SmallDialog},
		props: ["confirmed", "dialog"],
		computed: {
			dialogVal: {
				get() {
					return this.dialog;
				},
				set(value) {
					this.$emit('update:dialog', value)
				}
			}
		},
	}
</script>

<style scoped>

</style>