<template>
	<div>
		<p>
			Hier kannst du den aktuellen Live-Spielstand sehen.
		</p>
		<LiveRankComponent :gameDbName="gameDbName"></LiveRankComponent>
		<br>
		<v-btn to="/buildings" text color="primary">
      <v-icon left>
        {{mdiChevronLeft}}
      </v-icon>
      Zurück
    </v-btn>
	</div>
</template>

<script>
	import LiveRankComponent from "@/components/LiveRank";
	import {gameDb} from "@/_controller";
  import {mdiChevronLeft} from "@mdi/js";

  export default {
		name: "LiveRank",
		components: {LiveRankComponent},
    data: () => ({
      mdiChevronLeft
    }),
		computed: {
			gameDbName() {
				return gameDb.instance.dbName;
			}
		},
    mounted() {
      console.log("created");
      this.$store.commit("setGameDbInitialized", true);
    }
  }
</script>

<style scoped>

</style>