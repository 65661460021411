<template>
	<div>
		<p v-if="Object.keys(game).length === 0 && game.constructor === Object" class="grey--text">
			{{ message }}
		</p>
		<v-form v-else ref="form">
			<EditGameComponent :game.sync="game" :customerId.sync="customerId"></EditGameComponent>
			<br>
      <div class="d-flex justify-end">
			<v-btn class="success" type="submit" @click="validate" :loading="loading" :disabled="loading">
        <v-icon left>
          {{mdiContentSave}}
        </v-icon>
				Speichern
			</v-btn>
      </div>
		</v-form>
	</div>
</template>

<script>
	import EditGameComponent from "@/components/EditGame";
	import {games} from "@/_model";
	import {router} from "@/router";
  import {mdiContentSave} from "@mdi/js";

	export default {
		name: "EditGame",
		components: {EditGameComponent},
		data: () => ({
      mdiContentSave,
			game: {},
			message: "Spiel nicht gefunden...",
			loading: false,
		}),
		computed: {
			customerId: {
				get() {
					return this.game.customerId ? this.game.customerId : null;
				},
				set(value) {
					this.game.customerId = value;
				}
			}
		},
		created() {
			this.writeGame();
		},
		methods: {
			writeGame() {
				this.$store.commit("setGlobalLoading", true);
				games.getById(this.$route.params.id).then(game => {
					this.game = game;
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.message = error.message;
				}).finally(() => {
					this.$store.commit("setGlobalLoading", false);
				});
			},
			saveGame() {
				this.loading = true;
				games.insert(this.game, this.customerId).then(response => {
					this.$store.commit("globalSuccess", response);
					router.push("/games");
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.loading = false;
				});
			},
			validate() {
				if (this.$refs.form.validate()) {
					this.saveGame();
				}
			},
		}
	}
</script>

<style scoped>

</style>