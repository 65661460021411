import {game} from "@/_helpers";
import store from "@/store";

export const extension = {
	create(id, name, enabled = false) {
		return {id, name, enabled};
	},
	getPicture(ext) {
		return '/img/checklistPictures/' + ext.id + '.jpg';
	},
	setExtensions(extensions) {
		let currentGame = game.getCurrent();
		if (currentGame) {
			for (let extension of extensions) {
				delete extension.pic;
			}
			currentGame.settings.extensions = extensions;
			game.setCurrent(currentGame);
		}
	},
	with(extensions, extensionId) {
		let ext = extensions.find(e => e.id === extensionId);
		return ext ? ext.enabled : false;
	},
	getEnabled(extensions = null) {
		extensions = extensions || store.getters.extensions;
		return extensions.filter(e => e.enabled);
	}
};