import {gameDb} from "@/_controller";
import {ls} from "@/_helpers";
import store from "@/store";
import config from "@/config";

export const building = {
	initBuildings(withNaturkatastrophen, withGlaubeUndBilding, withRäuberUndGendarm, withZufriedenheit) {
		let avBuildings = [];
		avBuildings.push(building.create("Wohnhaus", 1));
		avBuildings.push(building.create("Sägewerk", 0));
		avBuildings.push(building.create("Bergwerk", 0));
		avBuildings.push(building.create("Schmiede", 0));
		avBuildings.push(building.create("Dreschmaschine", 0));
		if (withNaturkatastrophen) {
			avBuildings.push(
					building.create("Feuerwehr", 1, null, null, 'naturkatastrophen')
			);
		}
		avBuildings.push(
				building.create("Schule", 2, null, withGlaubeUndBilding ? 'Universität' : null)
		);
		if (withGlaubeUndBilding) {
			avBuildings.push(
					building.create("Universität", 4, 'Schule', null, 'glaubeUndBildung')
			);
		}
		avBuildings.push(
				building.create("Kirche", 2, null, withGlaubeUndBilding ? 'Kloster' : null)
		);
		if (withGlaubeUndBilding) {
			avBuildings.push(
					building.create("Kloster", 4, 'Kirche', null, 'glaubeUndBildung')
			);
		}
		avBuildings.push(
				building.create("Wache", 2, null, withRäuberUndGendarm ? 'Gendarmerie' : null)
		);
		if (withRäuberUndGendarm) {
			avBuildings.push(
					building.create("Gendarmerie", 4, 'Wache', null, 'räuberUndGendarm')
			);
		}
		avBuildings.push(
				building.create("Lazarett", 2, null, withRäuberUndGendarm ? 'Krankenhaus' : null)
		);
		if (withRäuberUndGendarm) {
			avBuildings.push(
					building.create("Krankenhaus", 4, 'Lazarett', null, 'räuberUndGendarm')
			);
		}
		avBuildings.push(
				building.create("Taverne", 2, null, withZufriedenheit ? 'Wirtschaft' : null)
		);
		if (withZufriedenheit) {
			avBuildings.push(
					building.create("Wirtschaft", 5, 'Taverne', null, 'zufriedenheit')
			);
		}
		avBuildings.push(building.create("Schloss", 6));
		return avBuildings
	},
	create(name, wp, pred = null, upgrade = null, ext = "grundspiel") {
		return {name, wp, extension: ext, predecessor: pred, upgrade};
	},
	async getBuilt(name = null) {
		if (name) {
			return await this.getBuilt().then(builtBuildings => {
				return builtBuildings.filter(b => b.name === name);
			});
		} else {
			return await gameDb.instance.getValues('builtBuilding');
		}
	},
	sortByTime(buildings = null) {
		return buildings.sort((a, b) => new Date(a.timeAdded).getTime() - new Date(b.timeAdded).getTime())
	},
	getWinningPoints(buildings) {
		return Object.values(buildings).reduce((total, {wp}) => total + wp, 0);
	},
	async getFirst(name) {
		return await this.getBuilt().then(builtBuildings => {
			let firstBuilding = null;
			builtBuildings.reverse().forEach(builtBuilding => {
				if (builtBuilding.name === name) {
					firstBuilding = builtBuilding;
					return false;
				}
			});
			if (firstBuilding) {
				// get doc via timeAdded
				return this.getDoc(firstBuilding).then(doc => {
					return doc;
				}).catch(error => {
					return {};
				});
			} else {
				return {};
			}
		}).catch(error => {
			return {};
		});
	},
	async add(newBuilding) {
		return await gameDb.instance.putDoc('builtBuilding', newBuilding).then(res => {
			let builtBuildings = store.state.builtBuildings;
			builtBuildings.push(newBuilding);
			store.commit("setBuiltBuildings", builtBuildings);
			return Promise.resolve(newBuilding);
		});
	},
	async upgrade(buildingObj) {
		// if has node no timeAdded: find first predecessor
		let promise = (!buildingObj.timeAdded) ?
				this.getFirst(buildingObj.predecessor) : Promise.resolve({builtBuilding: buildingObj});
		return await promise.then(doc => {
			buildingObj = doc.builtBuilding;
			return this.getDoc(buildingObj);
		}).then(doc => {
			let correspondingBuildingObj = store.getters.availableBuildings.find(b => b.name === buildingObj.name) || buildingObj;
			let upgrade = this.getUpgrade(correspondingBuildingObj);
			if (upgrade) {
				return gameDb.instance.overwriteDoc(upgrade, doc, 'builtBuilding');
			} else {
				throw new Error("Upgrade fehlgeschlagen: Kein Ausbaugebäude gefunden");
			}
		}).then(doc => {
			let builtBuildings = store.state.builtBuildings;
			builtBuildings = this.replaceBuilding(builtBuildings, doc.builtBuilding);
			store.commit("setBuiltBuildings", builtBuildings);
			return Promise.resolve(doc.builtBuilding);
		});
	},
	async downgrade(buildingObj) {
		return await this.getDoc(buildingObj).then(async doc => {
			let predecessor = this.getPredecessor(buildingObj);
			return gameDb.instance.overwriteDoc(predecessor, doc, 'builtBuilding');
		}).then(doc => {
			let builtBuildings = store.state.builtBuildings;
			builtBuildings = this.replaceBuilding(builtBuildings, doc.builtBuilding);
			store.commit("setBuiltBuildings", builtBuildings);
			return Promise.resolve(doc.builtBuilding);
		});
	},
	replaceBuilding(builtBuildings, newBuilding) {
		let index = builtBuildings.findIndex(b => b.timeAdded === newBuilding.timeAdded);
		builtBuildings.splice(index, 1, newBuilding);
		return builtBuildings;
	},
	async delete(buildingObj) {
		return await gameDb.instance.deleteDoc("builtBuilding", buildingObj).then(res => {
			let builtBuildings = store.state.builtBuildings.filter(b => b.timeAdded !== buildingObj.timeAdded);
			store.commit("setBuiltBuildings", builtBuildings);
			return Promise.resolve(res);
		});
	},
	getUpgrade(buildingObj) {
		return store.getters.availableBuildings.find(b => b.name === buildingObj.upgrade);
	},
	getPredecessor(buildingObj) {
		return store.getters.availableBuildings.find(b => b.name === buildingObj.predecessor);
	},
	async getDoc(buildingObj) {
		return gameDb.instance.localDb.get('builtBuilding_' + buildingObj.timeAdded);
	},
	isProductionBuilding(buildingObj) {
		return ["Sägewerk", "Bergwerk", "Schmiede", "Dreschmaschine"].includes(buildingObj.name);
	},
	hasProductionBuildings() {
		let yes = false;
		let builtBuildings = store.state.builtBuildings;
		for (let builtBuilding of builtBuildings) {
			if (this.isProductionBuilding(builtBuilding)) {
				yes = true;
				break;
			}
		}
		return yes;
	},

	getMissingBuildings() {
		let missingBuildings = [];
		let names = store.getters.builtBuildingNames;
		for (let buildingObj of store.getters.availableBuildings) {
			// alle Gebäude außer das Schloss
			if (buildingObj.name !== "Schloss") {
				// überprüfen, ob Gebäude ein Vorgänger ist
				if (buildingObj.upgrade) {
					if (!names.includes(buildingObj.upgrade) && !names.includes(buildingObj.name)) {
						// Beispiel: Schule fehlt nur, wenn Uni oder es selbst fehlt
						missingBuildings.push(buildingObj);
					}
				} else {
					if (!names.includes(buildingObj.name)) {
						// Gebäude fehlt
						missingBuildings.push(buildingObj);
					}
				}
			}
		}
		return missingBuildings;
	},
	checkCastleDialog() {
		if (!ls.getBool(config.buildingPrefix + "builtCastleDialogShown") && !building.castleBuilt()) {
			let missingBuildings = building.getMissingBuildings();
			if (missingBuildings.length <= 0) {
				ls.setItem(config.buildingPrefix + "builtCastleDialogShown", true);
				store.commit("showBuildCastleDialog", true);
			}
		}
	},
	removeFromLocalStorage() {
		ls.removeAll(config.buildingPrefix);
	},
	castleBuilt() {
		return store.state.builtBuildings.map(b => b.name).includes("Schloss");
	},
	getPicture(name) {
		return '/img/buildings/' + name + '.png';
	},
	getPublicBuildings(builtBuildings) {
		return builtBuildings.filter((buildingObj) => {
			return buildingObj.name !== 'Schloss' && buildingObj.name !== 'Wohnhaus' && !building.isProductionBuilding(buildingObj);
		});
	}
};
