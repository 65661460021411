<template>
	<div>
		<v-alert type="info" :icon="mdiInformation">
			Überprüfe folgende automatisch ausgerechneten Werte:
		</v-alert>
		<br>
		<v-form ref="form">
			<v-text-field outlined required min="0" :rules="rules" :prepend-icon="mdiFlagCheckered" type="number"
			              label="Anzahl Siegerpunkte auf Gebäuden" v-model="actualWinningPointsBuildings"
			>
				<template v-slot:append>
					<v-icon color="primary" v-if="parseInt(actualWinningPointsBuildings) !== initialWinningPointsBuildings"
					        @click="actualWinningPointsBuildings = initialWinningPointsBuildings"
					>{{mdiReload}}
					</v-icon>
				</template>
			</v-text-field>
			<v-text-field outlined required min="0" :rules="rules" :prepend-icon="mdiHomeGroup" type="number"
			              label="Anzahl Wohnhäuser" v-model="builtHousesCount"
			>
				<template v-slot:append>
					<v-icon color="primary" v-if="parseInt(builtHousesCount) !== initialBuiltHousesCount"
					        @click="builtHousesCount = initialBuiltHousesCount"
					>{{mdiReload}}
					</v-icon>
				</template>
			</v-text-field>
			<v-text-field outlined required min="0" :rules="rules" :prepend-icon="mdiCastle" type="number"
			              label="Anzahl Schlösser" v-model="builtCastlesCount" @change="checkFirstCastle"
			>
				<template v-slot:append>
					<v-icon color="primary" v-if="parseInt(builtCastlesCount) !== initialBuiltCastlesCount"
					        @click="builtCastlesCount = initialBuiltCastlesCount"
					>{{mdiReload}}
					</v-icon>
				</template>
			</v-text-field>

			<v-divider></v-divider>
			<br>

			<v-alert type="info" :icon="mdiInformation">
				Bitte gib noch folgende Angaben an:
			</v-alert>

			<v-subheader>Besitzt ihr den '1. Schloss'-Chip?</v-subheader>
			<v-flex class="d-flex" align-center>
				<v-avatar size="60" class="ma-2">
					<v-img src="/img/schloss-chip.png"></v-img>
				</v-avatar>
				<v-radio-group row v-model="hasFirstCastle" hide-details class="ma-2">
					<v-radio :off-icon="mdiRadioboxBlank" :on-icon="mdiRadioboxMarked" :value="true" label="Ja"
					         :disabled="builtCastlesCount <= 0"
					></v-radio>
					<v-radio :off-icon="mdiRadioboxBlank" :on-icon="mdiRadioboxMarked" :value="false" label="Nein">
					</v-radio>
				</v-radio-group>
			</v-flex>
			<br>

			<template v-if="$store.getters.withAnyExtensions(['zufriedenheit', 'glaubeUndBildung'])">
				<v-text-field outlined required v-model="actualWinningPointChips" type="number" :rules="rules"
				              label="Anzahl SP-Chips" :prepend-icon="mdiNumeric1CircleOutline" min="0"
				>
          <template v-slot:append>
            <v-icon color="primary" v-if="parseInt(actualWinningPointChips) !== initialWinningPointChips"
                    @click="actualWinningPointChips = initialWinningPointChips"
            >{{mdiReload}}
            </v-icon>
          </template>
				</v-text-field>
			</template>
      <div class="d-flex justify-end">
        <v-btn color="primary" @click="validate">
          Weiter
          <v-icon right>
            {{mdiChevronRight}}
          </v-icon>
        </v-btn>
      </div>
		</v-form>

		<SmallDialog :dialog="builtBuildingsHint">
			<template v-slot:header>
				Anzahl Gebäude
			</template>
			<template v-slot:text>
				Ihr habt insgesamt {{builtBuildingsCount}} Gebäude gebaut (ohne Brunnen).<br>
				Sollte dies NICHT stimmen, gehe zurück und verbessere, welche Gebäude nicht eingetragen oder zu viel
				eingetragen sind.
			</template>
			<template v-slot:actions>
				<v-btn text color="error" @click="builtBuildingsHint = false; backToGame()">
          <v-icon left>
            {{mdiChevronLeft}}
          </v-icon>
					Zurück
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn text color="primary" @click="builtBuildingsHint = false">
					Stimmt
          <v-icon right>
            {{mdiCheck}}
          </v-icon>
				</v-btn>
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import {
		mdiFlagCheckered, mdiHomeGroup, mdiCastle, mdiReload, mdiNumeric1CircleOutline, mdiInformation, mdiRadioboxBlank,
		mdiRadioboxMarked, mdiChevronLeft, mdiCheck, mdiChevronRight
	} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import {evaluation} from "@/_controller";
	import {game} from "@/_helpers";
	import {mapState, mapGetters} from 'vuex';
  import {getWinningPointChips} from "@/_helpers/qrcode-winning-point-chip";

	export default {
		name: "Evaluation",
		components: {SmallDialog},
		data: () => ({
			mdiFlagCheckered, mdiHomeGroup, mdiCastle, mdiReload, mdiNumeric1CircleOutline, mdiInformation,
			mdiRadioboxBlank, mdiRadioboxMarked, mdiChevronLeft, mdiCheck, mdiChevronRight,
			builtBuildingsHint: true,
			builtBuildingsCount: 0,
			actualWinningPointsBuildings: null,
			initialWinningPointsBuildings: 0,
			builtHousesCount: 0,
			initialBuiltHousesCount: 0,
			builtCastlesCount: 0,
			initialBuiltCastlesCount: 0,
      actualWinningPointChips: 0,
      initialWinningPointChips: 0,
			hasFirstCastle: false,

			valid: false,
			rules: [
				v => (!!v || v === 0) || 'Feld ist erforderlich!',
			],
		}),
		created() {
			this.writeValues();

      // initialize wpChips
      this.initialWinningPointChips = getWinningPointChips();
      if(this.initialWinningPointChips !== this.actualWinningPointChips && this.actualWinningPointChips === 0) {
        this.actualWinningPointChips = this.initialWinningPointChips;
      }
		},
		methods: {
			checkFirstCastle() {
				if (this.builtCastlesCount <= 0) {
					this.hasFirstCastle = false;
				}
			},
			validate() {
				if (this.$refs.form.validate()) {
					// save data to pouch and then go to /end
					this.saveItems().then(res => {
						game.markAsPlayed().then(response => {
							console.log(response);
						}).catch(error => {
							console.log(error.message);
						});
						// if (game.getCurrent().settings.liveRank) {
						// 	this.$router.push("/live-rank-end")
						// } else {
							this.$router.push("/end");
						// }
					}).catch(error => {
						console.log(error);
						this.$store.commit("globalError", error.message);
					});
				}
			},
			async saveItems() {
				let promises = [];

				promises.push(
						evaluation.updateValue("builtHousesCount", this.builtHousesCount)
				);
				let wps = this.actualWinningPointsBuildings || this.winningPointsBuildings;
				promises.push(
						evaluation.updateValue("wpFromBuildings", wps)
				);
				promises.push(
						evaluation.updateValue("builtCastlesCount", this.builtCastlesCount)
				);
				promises.push(
						evaluation.updateValue("hasFirstCastle", this.hasFirstCastle)
				);
				promises.push(
						evaluation.updateValue("numberOfWpChips", this.actualWinningPointChips)
				);

				let builtBuildings = await evaluation.getBuiltBuildings(this.builtHousesCount, this.builtCastlesCount);
				promises.push(
						evaluation.updateValue("builtBuildingsCount", builtBuildings.length)
				);
				promises.push(
						evaluation.updateValue("done", true)
				);

				return Promise.all(promises);
			},
			async writeValues() {
				this.initialWinningPointsBuildings = this.winningPointsBuildings;
				this.actualWinningPointsBuildings = this.winningPointsBuildings;

				this.builtBuildingsCount = this.builtBuildings.length;
				this.builtHousesCount = this.builtBuildings.filter(b => b.name === "Wohnhaus").length;
				this.initialBuiltHousesCount = this.builtHousesCount;
				this.builtCastlesCount = this.builtBuildings.filter(b => b.name === "Schloss").length;
				this.initialBuiltCastlesCount = this.builtCastlesCount;
			},
			backToGame() {
				game.backToGame();
				this.$router.push('/buildings');
			}
		},
		computed: {
			...mapState(["builtBuildings"]),
			...mapGetters(["winningPointsBuildings"]),
		},
		watch: {
			builtBuildings() {
				this.writeValues();
			}
		}
	}
</script>

<style scoped>
	.v-subheader {
		padding: 0 !important;
	}
</style>
