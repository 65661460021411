import { ls } from "@/_helpers/localStorage";

const validWinningPointChipIds = [
  "f64d971d",
  "66aa5f04",
  "14efe175",
  "359f889e",
  "f8972b07",
  "04cdd89c",
  "584ba948",
  "551daa65",
  "eccc4359",
  "f7a788ce",
  "d99831cb",
  "f38eda8b",
  "c99c8f38",
  "14a52ad9",
  "93cba04c",
  "30d4ec1c",
  "480b98a3",
  "85e82905",
  "0b8c7c4f",
  "9aef6125",
];

const prefix = "winningPointChip_";

export const isValidQrForWinningPointChips = (value) => {
  return validWinningPointChipIds.includes(value);
};

const getUsedWinningPointChipIds = () => {
  return ls.getObject(prefix + "usedIds") || [];
};

export const winningPointChipIdAlreadyUsed = (id) => {
  const usedWinningPointChipIds = getUsedWinningPointChipIds();
  return usedWinningPointChipIds.includes(id);
};

export const addUsedWinningPointChipId = (value) => {
  const usedWinningPointChipIds = getUsedWinningPointChipIds();
  usedWinningPointChipIds.push(value);
  ls.setObject(prefix + "usedIds", usedWinningPointChipIds);
};

export const getWinningPointChips = () => {
  return ls.getInt(prefix + "points") || 0;
};

export const saveWinningPointChips = (number) => {
  ls.setItem(prefix + "points", number);
};

export const removeWinningPointChipsFromLocalStorage = () => {
  ls.removeAll(prefix);
}
