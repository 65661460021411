<template>
	<div>
		<v-alert type="error" v-if="error" :icon="mdiAlert">
			{{ error }}
		</v-alert>
		<v-alert type="warning" v-if="warning" :icon="mdiAlertCircle">
			{{ warning }}
		</v-alert>
		<h2>Willkommen bei der Cantopia-App</h2>
		<br>
		<p>Um die Cantopia-App nutzen zu können, geben Sie Ihren Zugangs-Code ein:</p>
		<v-form @submit="onSubmit">
			<v-text-field
					:prepend-inner-icon="mdiLock"
					outlined
					clearable
					v-model="access_code"
					label="Zugangs-Code"
					:disabled="loading"
          type="password"
          :append-icon="revealPassword ? mdiEyeOff : mdiEye"
          :type="revealPassword ? 'text' : 'password'"
          @click:append="revealPassword = !revealPassword"
			>
			</v-text-field>
			<div class="d-flex justify-space-between align-end">
        <a @click="noCodeDialog = true">Ich habe kein Code</a>
				<v-btn :disabled="access_code.length <= 0 || loading" class="primary" type="submit" :loading="loading" large>
          Login
          <v-icon right>
            {{mdiChevronRight}}
          </v-icon>
				</v-btn>
			</div>
		</v-form>
		<SmallDialog :dialog.sync="noCodeDialog">
			<template v-slot:header>
				Ich habe keinen Code
			</template>
			<template v-slot:text>
				<p>Frage deinen Spielleiter nach dem Zugangscode<p>
				<p>oder<p>
				<p><a href="https://fluhrer.games/cantopia-reservieren/">Reserviere Cantopia für dich</a></p>
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import {mdiLock, mdiAlertCircle, mdiAlert, mdiChevronRight, mdiEye, mdiEyeOff} from '@mdi/js';
	import {games} from "@/_model";
	import {auth} from '@/_helpers';
	import SmallDialog from "../components/SmallDialog";

	export default {
		name: "Login",
		components: {SmallDialog},
		data: () => ({
			mdiLock, mdiAlertCircle, mdiAlert, mdiChevronRight, mdiEye, mdiEyeOff,
			access_code: "",
			error: "",
			warning: "",
			loading: false,
			noCodeDialog: false,
      revealPassword: false
		}),
    beforeRouteUpdate(to) {
      this.warning = to.query.warning;
    },
		created() {
			this.warning = this.$route.query.warning;
		},
		methods: {
			onSubmit(e) {
				e.preventDefault();
				this.loading = true;
				auth.login(this.access_code).then(response => {
					// set available games after login
					games.writeAvailableGamesFromServer();
					this.$router.push("/home");
				}).catch(error => {
					this.warning = "";
					this.$store.commit("globalError", error.message);
				}).finally(() => {
					this.loading = false;
				});
			}
		},
	}
</script>

<style scoped>

</style>
