<template>
	<div>
		<p>Überprüfe vor dem Spiel nochmals, ob <strong>alle Materialien</strong> für jede Erweiterung bereit liegen:</p>
		<ChecklistPictures :extensions="currentGame.settings.extensions"></ChecklistPictures>
    <div class="d-flex justify-end">
      <v-btn link to="/infos" class="primary">
        Weiter
        <v-icon right>
          {{mdiChevronRight}}
        </v-icon>
      </v-btn>
    </div>
	</div>
</template>

<script>
	import ChecklistPictures from "@/components/ChecklistPictures";
	import {mapState} from 'vuex';
  import {mdiChevronRight} from "@mdi/js";

	export default {
		name: "ChecklistBeforeGame",
		components: {ChecklistPictures},
    data: () => ({
      mdiChevronRight
    }),
		computed: {
			...mapState(["currentGame"]),
		}
	}
</script>

<style scoped>

</style>