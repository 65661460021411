<template>
	<div>
		<template v-if="availableGames && availableGames.length > 0">
			<v-form ref="form">
				<p>Bevor die App offline funktionieren kann, gib bitte noch ein paar Daten an:</p>
				<v-text-field outlined required label="Name Bürgermeister/in" v-model="mayorName" :rules="rules"
				              :prepend-icon="mdiAccountTie"
				>
				</v-text-field>
				<v-text-field outlined required label="Nr./Name Gruppe" v-model="groupName" :rules="rules"
				              :prepend-icon="mdiAccountDetails"
				>
				</v-text-field>
				<v-text-field outlined required label="Anzahl Spielende (inkl. Bürgermeister/in)" v-model="playerCount"
				              :rules="rules" type="number" min="1" :prepend-icon="mdiAccountGroup"
				>
				</v-text-field>
        <v-alert type="info" :icon="mdiInformation" style="margin-left: 33px">
          Wähle das korrekte Spiel aus! Frage bei der Spielleitung nach, was das richtige Spiel ist, solltest du es nicht wissen.
        </v-alert>
				<v-select outlined label="Spiel wählen" v-model="selectedGameId" :readonly="availableGames.length <= 1"
						  :items="availableGames" item-text="name" item-value="id" :prepend-icon="mdiGamepad" required :rules="rules"
              :color="availableGames.length <= 1 ? 'grey' : 'primary'" :append-icon="mdiMenuDown"
				>
					<template v-slot:append-outer>
						<v-icon color="primary" @click="writeAvailableGames">{{mdiRefresh}}</v-icon>
					</template>
				</v-select>
				<v-alert type="error" v-if="error" :icon="mdiAlert">
					{{error}}
				</v-alert>
        <div class="d-flex justify-end">
          <v-btn color="primary" @click="validate" :disabled="loading" :loading="loading">
            Weiter
            <v-icon right>
              {{mdiChevronRight}}
            </v-icon>
          </v-btn>
        </div>
			</v-form>
		</template>
		<template v-else>
			<p class="grey--text">Kein Spiel gefunden... Bitte wende dich an die Spielleitung</p>
		</template>
	</div>
</template>

<script>
	import {
    mdiAccountTie, mdiAccountDetails, mdiAccountGroup, mdiGamepad, mdiAlert, mdiRefresh, mdiMenuDown, mdiChevronRight,
    mdiInformation
  } from '@mdi/js';
	import {gameDb} from "@/_controller";
	import {game} from "@/_helpers";
	import {games} from "@/_model";
	import {mapState} from "vuex";

	export default {
		name: "Preparation",
		data: () => ({
			mdiAccountTie, mdiAccountDetails, mdiAccountGroup, mdiGamepad, mdiAlert, mdiRefresh, mdiMenuDown, mdiChevronRight,
      mdiInformation,
			loading: false,
			gamesLoading: false,
			games: [],
			mayorName: "",
			groupName: "",
			playerCount: 10,
			rules: [v => (!!v || v === 0) || 'Feld ist erforderlich!'],
			error: "",
			selectedGameId: ''
		}),
		created() {
			games.writeAvailableGamesFromServer().then(() => {
        if(this.availableGames.length === 1) {
          this.selectedGameId = this.availableGames[0].id;
        }
      });
		},
		methods: {
			validate() {
				if (this.$refs.form.validate()) {
					this.initGame();
				}
			},
			initGame() {
				// create database and redirect to extensions
				// write game to localStorage
				let selectedGame = this.availableGames.find(g => g.id === this.selectedGameId);
				game.setCurrent(selectedGame);
				game.setPlayingGame(true);

				this.loading = true;
				gameDb.init().then(async instance => {
					await gameDb.setMayorName(this.mayorName);
					await gameDb.setGroupName(this.groupName);
					await instance.updateValue("playerCount", this.playerCount);
					await this.$router.push("/extensions");
				}).catch(error => {
					this.$store.commit("globalError", error.message);
				}).then(() => {
					this.loading = false;
				});
			},
			writeAvailableGames() {
				this.$store.commit("setGlobalLoading", true);
				games.writeAvailableGamesFromServer().then(gamesObjs => {
					this.$store.commit("setGlobalLoading", false);
				}).catch(error => {
					this.$store.commit("globalError", error.message);
					this.$store.commit("setGlobalLoading", false);
				});
			}
		},
		computed: {
      ...mapState(["availableGames"]),
		}
	}
</script>

<style scoped>

</style>
