import config from "@/config";
import {game, ls} from "@/_helpers";
import {hints, extension} from "@/_model";
import store from "@/store";

export const countdown = {
	interval: null,
	audio: null,
	initialize() {
		// initalize countdown stuff
		if (game.isStarted()) {
			if (!game.isPaused()) {
				this.start();
			}
			store.commit("countdownTime", this.getCountdownTime());
			store.commit("setCountingDice", this.getCountingDice());
			this.setRound(this.getRound());
		}
	},
	// time related getters
	getCountdownTime() {
		return ls.getInt(config.countdownPrefix + "countdownTime") || 0;
	},
	setCountdownTime(time) {
		ls.setItem(config.countdownPrefix + "countdownTime", time);
		store.commit("countdownTime", time);
	},
	getRound() {
		if (game.isPaused()) {
			return countdown.getPausedRound();
		} else {
			return countdown.calcRound();
		}
	},
	calcRound(referenceTime = null) {
		let initialStartTime = countdown.getInitialStartTime();
		if (initialStartTime) {
			let elapsedTime = referenceTime ? countdown.getElapsedTime(initialStartTime, referenceTime) :
					countdown.getElapsedTime(initialStartTime);
			return Math.floor((elapsedTime / countdown.getRoundTime())) + 1;
		} else {
			return 1;
		}
	},
	setRound(value) {
		store.commit("setRound", value);
	},
	setCompareRound(value) {
		ls.setItem(config.countdownPrefix + "compareRound", value);
	},
	getCompareRound(value) {
		return ls.getInt(config.countdownPrefix + "compareRound") || null;
	},
	getPausedRound() {
		return ls.getInt(config.countdownPrefix + "pausedRound");
	},
	setPausedRound(value) {
		ls.setItem(config.countdownPrefix + "pausedRound", value);
	},
	getInitialStartTime() {
		return ls.getInt(config.countdownPrefix + "initialStartTime") || null;
	},
	setInitialStartTime(value) {
		ls.setItem(config.countdownPrefix + "initialStartTime", value);
	},
	getElapsedTime(referenceTime, now = new Date().getTime()) {
		return (now - referenceTime) / 1000;
	},

	getCountingDice() {
		let round = countdown.getRound();
		if (round !== 1) {
			round++;
		}
		let dice = config.countingDiceArray[0].dice;
		for (let item of config.countingDiceArray) {
			if (round >= item.round) {
				dice = item.dice;
			}
		}
		return dice;
	},

	// timer functions
	start() {
		// only start if countdown wasn't started before
		if (!countdown.interval) {
			let initialStartTime = countdown.getInitialStartTime();
			if (initialStartTime) {
				let timeout = 500; // in Millisekunden
				let roundTime = countdown.getRoundTime();
				countdown.interval = setInterval(() => {
					// update countdownTime in pouchdb
					let elapsedTime = countdown.getElapsedTime(initialStartTime);
					let relativeRoundFactor = (elapsedTime / roundTime) % 1;
					let countdownTime = roundTime - (relativeRoundFactor * roundTime);

					let round = countdown.getRound();
					if (!config.dev) {
						let compareRound = countdown.getCompareRound();
						if (compareRound) {
							let missedRounds = round - compareRound;
							if (missedRounds >= 1) {
								store.commit("setMissedRounds", missedRounds);
								store.commit("showMissedRoundsDialog", true);
								countdown.setCompareRound(round);
							}
						}
					}

					if (countdownTime - (timeout / 1000) <= 0) {
						let newRound = round + 1;
						countdown.setCompareRound(newRound);
						countdown.setRound(newRound);
						countdown.roundOverAction();
					}
					// countdown.setLastIntervalTime(new Date().getTime());
					countdown.setCountdownTime(countdownTime);
				}, timeout);
			} else {
				// initial start of timer
				countdown.setInitialStartTime(new Date().getTime());
				countdown.start();
				countdown.triggerDiceOverlay();
				countdown.roundOverAction();
			}
		}
	},
	destroy() {
		clearInterval(countdown.interval);
		countdown.interval = null;
	},
	pause() {
		countdown.setPausedRound(countdown.getRound());
		countdown.setLastPauseTime(new Date().getTime());
		countdown.destroy();
		game.setIsPaused(true);
	},
	continue() {
		let lastPauseTime = countdown.getLastPauseTime();

		let initialStartTime = countdown.getInitialStartTime();
		initialStartTime = initialStartTime + ((new Date()).getTime() - lastPauseTime);
		countdown.setInitialStartTime(initialStartTime);

		game.setIsPaused(false);
		countdown.start();
	},
	getLastPauseTime() {
		return ls.getInt(config.countdownPrefix + "lastPauseTime");
	},
	setLastPauseTime(value) {
		ls.setItem(config.countdownPrefix + "lastPauseTime", value);
	},
	getHumanReadableTime(time) {
		// get human readable time in format: 02:09
		let minutes = countdown.addLeadingZero(Math.floor(time / 60));
		let seconds = countdown.addLeadingZero(Math.floor(time - (minutes * 60)));
		return minutes + ':' + seconds;
	},

	roundOverAction() {
		store.commit("showCountdownTimeDialog", false);
		store.commit("setCountingDice", countdown.getCountingDice());
		if (!config.dev) {
			// play audio
			countdown.triggerDiceOverlay();
			this.audio.play();
			// show hints
			hints.open();
		}
	},

	triggerDiceOverlay() {
		if (!config.dev) {
			store.commit("diceOverlay", true);
			setTimeout(() => {
				store.commit("diceOverlay", false);
			}, 3000);
		}
	},
	removeFromLocalStorage() {
		// delete all values in localStorage that are associated to the countdown
		ls.removeAll(config.countdownPrefix);
	},
	addLeadingZero(time) {
		time = time.toString();
		if (time.length === 1) {
			time = "0" + time;
		}
		return time;
	},
	getRoundTime() {
		let settings = game.getCurrent().settings;
		return countdown.calcRoundTime(settings.roundTime, extension.getEnabled(), settings.extraRoundTime || 0);
	},
	calcRoundTime(roundTime, extensions, extraRoundTime) {
		return parseInt(roundTime) + (extensions.length * parseInt(extraRoundTime));
	}
};
