<template>
	<div>
		<SmallDialog :dialog.sync="dialog">
			<template v-slot:header>
				ACHTUNG!
			</template>
			<template v-slot:text>
				<v-alert type="warning" :icon="mdiAlertCircle">
					<template slot="prepend">
						<v-icon left large>{{mdiDiceMultiple}}</v-icon>
					</template>
					Ab jetzt
					<span v-if="countingDice > 1">zählen</span>
					<span v-else>zählt</span>&nbsp;
          <strong>nur noch {{ countingDice }} Würfel</strong>.<br>
				</v-alert>
				<p>Trotzdem mit allen Würfeln würfeln.</p>
				<p>
					Ihr dürft euch dann einfach bei der Rohstoffausgabe {{ countingDice }} der {{ maxCountingDice }}
					Würfel raussuchen.
				</p>
			</template>
		</SmallDialog>
	</div>
</template>

<script>
	import {mdiDiceMultiple, mdiAlertCircle} from "@mdi/js";
	import SmallDialog from "@/components/SmallDialog";
	import config from "@/config";
	import {mapState} from 'vuex';

	export default {
		name: "CountingDiceDialog",
		components: {SmallDialog},
		data: () => ({
			mdiDiceMultiple, mdiAlertCircle,
			dialog: false,
			maxCountingDice: config.maxCountingDice,
		}),
		computed: {
			...mapState(["countingDice"]),
		},
		watch: {
			countingDice(countingDice) {
				if (countingDice !== config.maxCountingDice) {
					setTimeout(() => {
						this.dialog = true;
					}, 1000);
				}
			}
		},
	}
</script>

<style scoped>

</style>