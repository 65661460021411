import { render, staticRenderFns } from "./MissedRoundDialog.vue?vue&type=template&id=6973bf8a&scoped=true"
import script from "./MissedRoundDialog.vue?vue&type=script&lang=js"
export * from "./MissedRoundDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6973bf8a",
  null
  
)

export default component.exports