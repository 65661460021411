import {Pouch, helper} from "@/_helpers";
import store from "@/store";

export const gameDb = {
	instance: null,
	async init() {
		let dbName = helper.getGameDbName();
		if (dbName) {
			this.instance = new Pouch(dbName);

			this.instance.registerChange("groupName", change => {
				store.commit("setGroupName", change.doc.value);
			});
			this.instance.registerChange("mayorName", change => {
				store.commit("setMayorName", change.doc.value);
			});
			this.instance.registerDestroyed(destroyedDbName => {
				store.commit("setGroupName", null);
				store.commit("setMayorName", null);
			});

			// initialize replication right away
			return await this.instance.replicateTo().then(replication => {
				return Promise.resolve(this.instance);
			});
		} else {
			return Promise.reject("Kein aktuelles Spiel oder kein aktueller Benutzer");
		}
	},
	destroy() {
		this.instance.destroy();
	},
	async setMayorName(mayorName) {
		store.commit("setMayorName", mayorName);
		return this.instance.updateValue("mayorName", mayorName);
	},
	async setGroupName(groupName) {
		store.commit("setGroupName", groupName);
		return this.instance.updateValue("groupName", groupName);
	},
	exists() {
		return !!this.db;
	}
};