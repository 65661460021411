<template>
	<v-dialog :value="showCountdownTimeDialog" :max-width="width+50" @click:outside="showCountdownTimeDialog = false">
		<v-card color="primary" class="white--text">
			<v-card-title class="d-flex justify-center white--text">Nächste Würfelrunde in:</v-card-title>
			<v-card-text class="d-flex justify-center white--text">
				<div id="big-countdown-time">{{humanReadableCountdownTime}}</div>
			</v-card-text>
			<v-overlay absolute v-model="gameIsPaused" opacity="1" color="primary">
				<template v-slot:default>
					<div class="d-flex align-center flex-column">
						<v-icon size="100">
							{{mdiPauseCircle}}
						</v-icon>
						<p>Spiel angehalten...</p>
					</div>
				</template>
			</v-overlay>
		</v-card>
	</v-dialog>
</template>

<script>
	import {mdiTimerSand, mdiPauseCircle} from "@mdi/js";
	import {countdown} from "@/_helpers";
	import config from "@/config";
	import {mapState, mapGetters} from 'vuex';
	import {mapFields} from 'vuex-map-fields';

	export default {
		name: "CountdownTimeDialog",
		data: () => ({
			mdiTimerSand, mdiPauseCircle,
			width: config.smallDialogWidth
		}),
		computed: {
			...mapFields(["showCountdownTimeDialog"]),
			...mapState(["gameIsPaused"]),
			...mapGetters(["humanReadableCountdownTime"]),
		}
	}
</script>

<style scoped>
	#big-countdown-time {
		line-height: 1;
		font-size: 5rem;
		font-weight: bold;
	}
</style>