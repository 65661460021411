import {router} from "@/router";
import {building} from "@/_model";
import store from "@/store";

export const hints = {
	instances: [
		{name: 'hintNaturkatastrophen', caption: 'Frühwarnsystem', show: true, isCurrent: false},
		{name: 'hintResources', caption: 'Rohstoffzuordnung', show: true, isCurrent: true},
		{name: 'hintProduction', caption: 'Produktionsgebäude', show: true, isCurrent: false},
		{name: 'hintZufriedenheit', caption: 'Gold/Streiks', show: true, isCurrent: false},
	],

	// booleans
	isFirst() {
		let currentHint = this.getCurrent();
		return this.instances[0].name === currentHint.name || !this.getPrevVisible();
	},
	isLast() {
		let currentHint = this.getCurrent();
		return this.instances[this.instances.length - 1].name === currentHint.name || !this.getNextVisible();
	},

	// navigation
	open() {
		this.resetCurrent();
		if (router.currentRoute.path !== "/buildings/hints") {
			router.push('/buildings/hints');
		}
	},
	close() {
		router.push('/buildings');
	},
	next() {
		let current = this.getCurrent();
		let nextVisible = this.getNextVisible();
		current.isCurrent = false;
		nextVisible.isCurrent = true;
	},
	prev() {
		let current = this.getCurrent();
		let prevVisible = this.getPrevVisible();
		current.isCurrent = false;
		prevVisible.isCurrent = true;
	},

	// getters
	getNextVisible() {
		let next = null;
		let hints = this.instances;
		loop: for (let i = 0; i < hints.length; i++) {
			if (hints[i].isCurrent || next) {
				next = hints[i + 1];
				if (next && next.show) {
					break loop;
				}
			}
		}
		return next;
	},
	getPrevVisible() {
		let prev = null;
		let hints = this.instances;
		loop: for (let i = hints.length - 1; i >= 0; i--) {
			if (hints[i].isCurrent || prev) {
				prev = hints[i - 1];
				if (prev && prev.show) {
					break loop;
				}
			}
		}
		return prev;
	},
	getCurrent() {
		return this.instances.find(h => h.isCurrent);
	},

	// initializers
	initializeShowValues() {
		let hints = this.instances;
		for (let hint of hints) {
			switch (hint.name) {
				case 'hintProduction':
					hint.show = building.hasProductionBuildings();
					break;
				case 'hintNaturkatastrophen':
					hint.show = store.getters.withExtension('naturkatastrophen');
					break;
				case 'hintZufriedenheit':
					hint.show = store.getters.withExtension('zufriedenheit');
					break;
			}
		}
	},
	initializeCurrentHint() {
		let currentHint = this.getCurrent();
		if (!currentHint.show) {
			let nextVisible = this.getNextVisible();
			currentHint.isCurrent = false;
			nextVisible.isCurrent = true;
		}
	},

	// others
	resetCurrent() {
		let current = this.getCurrent();
		current.isCurrent = false;
		this.instances[0].isCurrent = true;
	},
};
