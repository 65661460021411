import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginPage from "@/views/Login";
import HomePage from "@/views/Home";
import PreparationPage from "@/views/Preparation";
import ExtensionsPage from "@/views/Extensions";
import InfosPage from "@/views/Infos";
import BuildingsPage from "@/views/Buildings";
import ChecklistPage from "@/views/Checklist";
import ChecklistBeforeGamePage from "@/views/ChecklistBeforeGame";
import ChooseFacultiesPage from "@/views/ChooseFaculties";
import ScanQrPage from "@/views/ScanQr";
import ChooseExtensionsPage from "@/views/ChooseExtensions";
import Hints from "@/views/Hints";
import EvaluationPage from "@/views/Evaluation";
import EndPage from "@/views/End";
import LiveRankEndPage from "@/views/LiveRankEnd";
import LiveRankPage from "@/views/LiveRank";

import GamesPage from "@/views/Games";
import AddGamePage from "@/views/AddGame";
import EditGamePage from "@/views/EditGame";
import LiveRankGamePage from "@/views/LiveRankGame";
import CustomersPage from "@/views/Customers";
import AddCustomerPage from "@/views/AddCustomer";
import EditCustomerPage from "@/views/EditCustomer";
import AdminInfosPage from "@/views/AdminInfos";

import BauamtPage from "@/views/Bauamt";

import NotFoundPage from "@/views/NotFound";
import NoPermissionPage from "@/views/NoPermission";

Vue.use(VueRouter);
import {game, auth} from "@/_helpers";
import {games} from "@/_model";
import config from "@/config";
import store from "@/store";

const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginPage,
		meta: {
			title: "Cantopia-App",
			redirects: [
				{
					to: '/home',
					condition() {
						return auth.isAuthenticated();
					}
				}
			]
		}
	},
	{
		path: '/home',
		name: 'home',
		component: HomePage,
		meta: {
			title: "Startseite",
			authorize: ["Bürgermeister", "Spielleiter", "Admin"],
			redirects: [
				{
					to: '/buildings',
					condition() {
						return game.isStarted();
					}
				}
			]
		}
	},
	{
		path: "/preparation",
		name: "preparation",
		component: PreparationPage,
		meta: {
			title: "Vorbereitung",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/extensions',
					condition() {
						return game.exists();
					}
				}
			]
		}
	},
	{
		path: '/extensions',
		name: 'extensions',
		component: ExtensionsPage,
		meta: {
			title: "Erweiterungen",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/preparation',
					condition() {
						return !game.exists();
					}
				},
				{
					to: '/buildings',
					condition() {
						return game.isStarted();
					}
				},
				{
					to: '/evaluation',
					condition() {
						return game.isEnded();
					}
				}
			]
		}
	},
	{
		path: '/checklist-before-game',
		name: 'checklist-before-game',
		component: ChecklistBeforeGamePage,
		meta: {
			title: "Checkliste vor dem Spiel",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/buildings',
					condition() {
						return game.isStarted();
					}
				},
				{
					to: '/evaluation',
					condition() {
						return game.isEnded();
					}
				}
			]
		}
	},
	{
		path: '/infos',
		name: 'infos',
		component: InfosPage,
		meta: {
			title: "Infos vor dem Spiel",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/buildings',
					condition() {
						return game.isStarted();
					}
				},
				{
					to: '/evaluation',
					condition() {
						return game.isEnded();
					}
				}
			]
		}
	},

	{
		path: '/buildings',
		name: 'buildings',
		component: BuildingsPage,
		meta: {
			title: "Gebäudeliste",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/extensions',
					condition() {
						return !game.isStarted();
					}
				},
				{
					to: '/evaluation',
					condition() {
						return game.isEnded();
					}
				}
			],
			preventScrollReset: true,
		},
		children: [
			{
				path: 'hints',
				component: Hints,
				meta: {
					title: "Gebäudeliste",
					authorize: ["Bürgermeister", "Spielleiter"],
					preventScrollReset: true,
				},
			},
		],
	},
	{
		path: '/checklist',
		name: 'checklist',
		component: ChecklistPage,
		meta: {
			title: "Checkliste",
			authorize: ["Bürgermeister", "Spielleiter"]
		}
	},
	{
		path: '/choose-faculties',
		name: 'choose-faculties',
		component: ChooseFacultiesPage,
		meta: {
			title: "Gebäude wählen",
			authorize: ["Bürgermeister", "Spielleiter"]
		}
	},
	{
		path: '/choose-extensions',
		name: 'choose-extensions',
		component: ChooseExtensionsPage,
		meta: {
			title: "Erweiterungen wählen",
			authorize: ["Bürgermeister", "Spielleiter"]
		}
	},
  {
    path: '/scan-qr',
    name: 'scan-qr',
    component: ScanQrPage,
    meta: {
      title: "QR-Code scannen",
      authorize: ["Bürgermeister", "Spielleiter"]
    }
  },

	{
		path: '/evaluation',
		name: 'evaluation',
		component: EvaluationPage,
		meta: {
			title: "Auswertung",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/buildings',
					condition() {
						return !game.isEnded();
					}
				}
			]
		}
	},
	{
		path: '/end',
		name: 'end',
		component: EndPage,
		meta: {
			title: "Ende",
			authorize: ["Bürgermeister", "Spielleiter"],
			redirects: [
				{
					to: '/buildings',
					condition() {
						return !game.isEnded();
					}
				}
			]
		}
	},
	{
		path: '/live-rank-end',
		name: 'live-rank-end',
		component: LiveRankEndPage,
		meta: {
			title: "Ende: Live-Spielstand",
			authorize: ["Bürgermeister", "Spielleiter"],
		}
	},

	{
		path: '/live-rank',
		name: 'live-rank',
		component: LiveRankPage,
		meta: {
			title: "Live-Spielstand",
			authorize: ["Bürgermeister", "Spielleiter"],
		}
	},

	{
		path: "/games",
		name: "games",
		component: GamesPage,
		meta: {
			title: "Spiele",
			authorize: ["Spielleiter", "Admin"]
		}
	},
	{
		path: "/add-game",
		name: "add-game",
		component: AddGamePage,
		meta: {
			title: "Spiel hinzufügen",
			authorize: ["Admin", "canCreateGames"]
		}
	},
	{
		path: "/edit-game/:id",
		name: "edit-game",
		component: EditGamePage,
		meta: {
			title: "Spiel bearbeiten",
			authorize: ["Admin", "Spielleiter"]
		}
	},
	{
		path: "/live-rank-game/:gameId",
		name: "live-rank-game",
		component: LiveRankGamePage,
		// props: (route) => ({
		//   ...route.params
		// }),
		meta: {
			title: "Live-Spielstand",
			authorize: ["Spielleiter"]
		}
	},
	{
		path: "/customers",
		name: "customers",
		component: CustomersPage,
		meta: {
			title: "Admin: Kunden",
			authorize: ["Admin"],
		}
	},
	{
		path: "/add-customer",
		name: "add-customer",
		component: AddCustomerPage,
		meta: {
			title: "Admin: Kunde hinzufügen",
			authorize: ["Admin"],
		}
	},
	{
		path: "/edit-customer/:id",
		name: "edit-customer",
		component: EditCustomerPage,
		meta: {
			title: "Admin: Kunde bearbeiten",
			authorize: ["Admin"],
		}
	},
	{
		path: "/admin-infos",
		name: "admin-infos",
		component: AdminInfosPage,
		meta: {
			title: "Admin: Infos",
			authorize: ["Admin"],
		}
	},

	{
		path: "/bauamt",
		name: "bauamt",
		component: BauamtPage,
		meta: {
			title: "Bauamt",
			authorize: ["Bauamt", "Spielleiter"],
			template: "bauamt"
		}
	},

	{
		path: "/not-found",
		name: "not-found",
		component: NotFoundPage,
		meta: {
			title: "Seite nicht gefunden",
		}
	},
	{
		path: "/no-permission",
		name: "no-permission",
		component: NoPermissionPage,
		meta: {
			title: "Keine Berechtigung",
		}
	},
	{
		path: '*',
		redirect: '/not-found'
	},
];

export const router = new VueRouter({
	// leave history mode off, so offline caching will work without specific webserver rules
	// mode: 'history',
	routes
});


router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const {authorize, redirects} = to.meta;
	const currentuser = auth.getCurrentUser();

	let nextCalled = false;

	if (authorize) {
		if (auth.expired()) {
			nextCalled = true;
      return next({path: config.loginPath, query: {returnUrl: to.path, warning: 'Zugang abgelaufen.'}});
		} else if (!auth.isAuthenticated()) {
			// not logged in so redirect to login page with the return url
			nextCalled = true;
      return next({path: config.loginPath, query: {returnUrl: to.path, warning: 'Bitte log dich zuerst ein.'}});
		} else if ((authorize.length && !auth.hasAccess(to.name))) {
			// check if route is restricted by role
			// role not authorized so redirect to home page
			nextCalled = true;
			return next({path: "/no-permission", query: {url: to.path}});
		}
	}

	// check if redirect exists
	if (redirects && !nextCalled) {
		for (let redirect of redirects) {
			if (!nextCalled) {
				// redirect.getCondition().then(function (condition) {
				// console.log(condition);
				// console.log(from);
				if (redirect.condition()) {
					nextCalled = true;
					return next({path: redirect.to});
				}
				// });
			}
		}
	}

	if (!nextCalled) next();
});

router.afterEach(async (to, from) => {
	// if forceReload query-param is given: force a page reload
	if (to.query.forceReload) {
		store.commit('setGlobalLoading', true);
		// remove query param to prevent endless loop
		await router.replace({'query': null});
		router.go();
	}

	// TODO: Wird nach jeder Route ausgeführt! Jeweils an notwendige Stellen platzieren
	store.commit("setGameIsStarted", game.isStarted());
	store.commit("setPlayingGame", game.playingGame());
	store.commit("setGameIsEnded", game.isEnded());
	store.commit("setGameIsPaused", game.isPaused());
	store.commit("setAvailableGames", games.getAvailableGames());
	store.commit("setIsAuthenticated", auth.isAuthenticated());

	// TODO: als getter in store.js machen und hier entfernen
	store.commit("setIsBuildingsView", to.path.startsWith("/buildings"));
	store.commit("setSmallCardPadding", to.path.startsWith("/buildings"));

	// reset global error
	store.commit("globalError", "");

	// initialize currentUser
	if (!store.state.currentUser) {
		store.commit("setCurrentUser", auth.getCurrentUser());
	}
	// initialize currentGame
	if (!store.state.currentGame) {
		store.commit("setCurrentGame", game.getCurrent());
	}

	// scroll to the top before each route (iOS Safari problem)
	if (!to.meta.preventScrollReset) {
		window.scrollTo(0, 0);
	}
});
