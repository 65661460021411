<template>
	<v-snackbar v-model="snackbar">
		<div>
			<v-icon dark>{{mdiPause}}</v-icon>
			<span class="pa-2">Spiel ist pausiert</span>
		</div>
		<template v-slot:action>
			<v-btn dark icon @click="snackbar = false">
				<v-icon>
					{{mdiClose}}
				</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	import {mdiClose, mdiPause} from "@mdi/js";
	import {mapState} from "vuex";
	import config from "@/config";

	export default {
		name: "PauseSnackbar",
		data: () => ({
			mdiClose, mdiPause,
			snackbar: false
		}),
		created() {
			this.writeSnackbar();
		},
		computed: {
			...mapState(["gameIsPaused", "isBuildingsView"]),
		},
		methods: {
			writeSnackbar() {
				this.snackbar = !config.dev && this.gameIsPaused && this.isBuildingsView;
			}
		},
		watch: {
			gameIsPaused() {
				this.writeSnackbar();
			},
			isBuildingsView() {
				this.writeSnackbar();
			}
		}
	}
</script>

<style scoped>

</style>