import { render, staticRenderFns } from "./HintNaturkatastrophen.vue?vue&type=template&id=e9f612c8&scoped=true"
import script from "./HintNaturkatastrophen.vue?vue&type=script&lang=js"
export * from "./HintNaturkatastrophen.vue?vue&type=script&lang=js"
import style0 from "./HintNaturkatastrophen.vue?vue&type=style&index=0&id=e9f612c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9f612c8",
  null
  
)

export default component.exports