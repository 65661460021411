<template>
	<div>
    <v-alert type="info" :icon="mdiDiceMultiple">
      Immer mit allen {{maxCountingDice}} Würfeln würfeln!
    </v-alert>

		<p>Pasch oder Drilling vorhanden?</p>

		<div class="text-center">
			<div class="border">
				<v-icon color="blue darken-2" x-large>{{mdiDice2}}</v-icon>
				<v-icon color="black" x-large>{{mdiDice2}}</v-icon>
			</div>
			<v-icon color="red" x-large>{{mdiDice4}}</v-icon>
			<v-icon class="ma-2">{{mdiArrowRight}}</v-icon>
			<span>Pasch</span>

			<br>
			<br>

			<div class="border">
				<v-icon color="blue darken-2" x-large>{{mdiDice3}}</v-icon>
				<v-icon color="black" x-large>{{mdiDice3}}</v-icon>
				<v-icon color="red" x-large>{{mdiDice3}}</v-icon>
			</div>
			<v-icon class="ma-2">{{mdiArrowRight}}</v-icon>
			<span>Drilling</span>

		</div>
		<br>
		<v-divider></v-divider>
		<br>

		<p>Wenn ja: Murmeln ins Frühwarnsystem. <br> Beispiel 2er-Pasch:</p>
		<div class="d-flex flex-column align-center">
			<v-img width="230" src="/img/frühwarnsystem.jpg"></v-img>
		</div>
	</div>
</template>

<script>
  import config from "@/config";
	import {mdiDice2, mdiDice4, mdiArrowRight, mdiDice3, mdiDiceMultiple} from '@mdi/js';

	export default {
		name: "HintNaturkatastrophen",
		data: () => ({
			mdiDice2, mdiDice4, mdiArrowRight, mdiDice3, mdiDiceMultiple,
      maxCountingDice: config.maxCountingDice
		})
	}
</script>

<style scoped>
	.border {
		border: 1px solid red;
		border-radius: 3px;
		display: inline-block;
	}
</style>
